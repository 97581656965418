var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var xP,yP,zP,Yba,Zba,DP,$ba,GP,HP,IP,JP,aca,KP,LP,NP,OP,AP,PP,bca,QP,cca,SP,TP,UP,dca,VP,WP,eca,YP,fca,ZP,$P,gca,bQ,hca,ica,jca,dQ,eQ,fQ,gQ,hQ,iQ,jQ,kQ,kca,lQ,mQ,nQ,pQ,rQ,lca,sQ,tQ,uQ,vQ,mca,wQ,xQ,yQ,nca,zQ,AQ,BQ,CQ,oca,DQ,pca,EQ,FQ,GQ,qca,HQ,IQ,JQ,KQ,MQ,NQ,rca,OQ,PQ,sca,QQ,SQ,TQ,UQ,VQ,tca,WQ,uca,XQ,YQ,$Q,vca,aR,bR,cR,wca,fR,gR,xca,yca,hR,jR,kR,lR,mR,zca,pR,qR,Aca,Bca,Cca,rR,sR,tR,uR,vR,wR,Dca,xR,Eca,yR,zR,BR,DR,ER,FR,GR,Fca,IR,Gca,Hca,JR,KR,LR,MR,NR,Ica,OR,Jca,Kca,PR,QR,RR,SR,TR,Lca,
Mca,Nca,UR,Oca,VR,WR,Pca,ZR,$R,Qca,bS,cS,dS,eS,Rca,hS,Sca,Tca,iS,jS,kS,Uca,lS,mS,nS,oS,Vca,qS,rS,sS,uS,vS,Wca,wS,xS,Xca,yS,zS,AS,BS,CS,DS,FS,Yca,GS,Zca,HS,IS,JS,$ca,ada,bda,LS,MS,NS,cda,OS,PS,QS,RS,dda,eda,TS,fda,gda,US,VS,WS,XS,YS,hda,ZS,$S,aT,bT,ida,cT,dT,eT,fT,hT,jda,iT,jT,kda,kT,lT,mT,lda,nT,mda,pT,nda,qT,rT,sT,tT,uT,vT,wT,oda,pda,qda,xT,rda,yT,zT,sda,tda,AT,BT,CT,uda,DT,vda,ET,wda,xda,FT,yda,zda,Ada,IT,JT,Bda,KT,LT,NT,Cda,OT,Dda,PT,QT,RT,Eda,Fda,ST,TT,UT,VT,Gda,XT,YT,Hda,ZT,$T,aU,bU,cU,dU,eU,
fU,Ida,gU,hU,Jda,iU,Kda,jU,kU,lU,Lda,mU,nU,Mda,Nda,oU,pU,qU,Oda,Pda,rU,Qda,Rda,Sda,uU,Tda,xU,Uda,Vda,yU,zU,Wda,Xda,Yda,AU,BU,CU,DU,EU,FU,GU,Zda,HU,IU,$da,JU,aea,bea,cea,KU,LU,MU,dea,NU,OU,eea,PU,fea,QU,gea,hea,SU,UU,VU,WU,XU,iea,YU,ZU,jea,$U,aV,bV,cV,dV,eV,fV,gV,kea,hV,iV,kV,lV,mV,nV,oV,pV,qV,rV,sV,lea,tV,uV,vV,wV,xV,mea,yV,zV,AV,BV,nea,DV,oea,pea,FV,qea,GV,rea,HV,sea,tea,IV,uea,JV,LV,MV,vea,QV,RV,wea,xea,SV,TV,UV,VV,WV,XV,YV,$V,aW,yea;
$CLJS.vP=function(a,b){return function(){function c(l,m,t){return $CLJS.Fd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Fd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Fd(l)}var f=null,h=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Fd(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Me(function(D){var H=a.h?a.h(D):a.call(null,D);return $CLJS.n(H)?
b.h?b.h(D):b.call(null,D):H},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return h.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=h.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=h.l;return f}()};
$CLJS.wP=function(a){var b=$CLJS.to.g(a,null),c=$CLJS.uJ(b,$CLJS.Kt,function(d){return $CLJS.sn(d,$CLJS.Bf)});return function(){function d(h,l,m){return(l=$CLJS.z(c.j?c.j(h,l,m):c.call(null,h,l,m)))?new $CLJS.k(null,3,[$CLJS.nl,b,$CLJS.Bj,h,$CLJS.Rt,l],null):null}function e(h){return f.j(h,$CLJS.Bf,$CLJS.Bf)}var f=null;f=function(h,l,m){switch(arguments.length){case 1:return e.call(this,h);case 3:return d.call(this,h,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
xP=function(a,b){return $CLJS.yd(a)?$CLJS.J.g(a,b):a};yP=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.vn($CLJS.to.g($CLJS.nl.h(a),null));if($CLJS.n(b)){var e=xP($CLJS.Bu.h(b),c);$CLJS.n(e)?(e=$CLJS.Cq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:xP($CLJS.zu.h(b),c)}return null};zP=function(a,b){return $CLJS.wd(a)||$CLJS.Gl(a)?$CLJS.J.g(a,b):$CLJS.xd(a)?$CLJS.J.g($CLJS.Cf(a),b):null};
Yba=function(a,b){a=$CLJS.Ge(a);var c=$CLJS.J.g(a,$CLJS.nl),d=$CLJS.J.g(a,$CLJS.kj);b=$CLJS.Ge(b);var e=$CLJS.J.j(b,$CLJS.Rt,$CLJS.zF),f=$CLJS.J.j(b,$CLJS.rF,!0),h=$CLJS.J.g(b,$CLJS.yF),l=$CLJS.J.j(b,$CLJS.tF,$CLJS.pF),m=yP(a,$CLJS.Oq.h(c),h,b);if($CLJS.n(m))return m;m=yP(a,$CLJS.fu.h(c),h,b);if($CLJS.n(m))return m;m=yP(a,e.h?e.h(d):e.call(null,d),h,b);if($CLJS.n(m))return m;m=yP(a,function(){var t=$CLJS.$n.h(c);return e.h?e.h(t):e.call(null,t)}(),h,b);if($CLJS.n(m))return m;m=yP(a,$CLJS.Oq.h(c),
l,b);if($CLJS.n(m))return m;m=yP(a,$CLJS.fu.h(c),l,b);if($CLJS.n(m))return m;d=yP(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=yP(a,function(){var t=$CLJS.$n.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;h=$CLJS.n(f)?yP(a,e.h?e.h($CLJS.nF):e.call(null,$CLJS.nF),h,b):f;return $CLJS.n(h)?h:$CLJS.n(f)?yP(a,e.h?e.h($CLJS.nF):e.call(null,$CLJS.nF),l,b):f};
Zba=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.kl.h(b);var f=$CLJS.Ge(b);var h=$CLJS.J.g(f,$CLJS.nl),l=$CLJS.Ge(c);f=$CLJS.J.g(l,$CLJS.yF);l=$CLJS.J.j(l,$CLJS.tF,$CLJS.pF);h=$CLJS.Oq.h(h);f=xP($CLJS.qF.h(h),f);f=$CLJS.n(f)?f:xP($CLJS.qF.h(h),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Yba(b,c)],null)};
$CLJS.BP=function(a){var b=$CLJS.Ge(a),c=$CLJS.J.g(b,$CLJS.Bj);a=$CLJS.J.g(b,$CLJS.Rt);var d=$CLJS.Ge(null),e=$CLJS.J.j(d,$CLJS.sF,$CLJS.Ri),f=$CLJS.J.j(d,$CLJS.wF,Zba);return $CLJS.n(a)?$CLJS.bb(function(h,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Ri,m);l=e.h?e.h(l):e.call(null,l);return AP(h,c,t,l)},null,a):null};
DP=function(a,b,c){var d=$CLJS.XD($CLJS.q($CLJS.gI),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Rh.v($CLJS.gI,$CLJS.CP,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.EP=function(a){return DP($CLJS.pk,a,function(){return $CLJS.vJ.h(a)})};$ba=function(){var a=FP;return DP($CLJS.Kt,a,function(){var b=$CLJS.vJ.h(a),c=$CLJS.wP(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
GP=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,$CLJS.le(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};HP=function(a){if($CLJS.Ad(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,GP(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,GP(c)],null);default:return GP(a)}}else return GP(a)};
IP=function(a,b){var c=$CLJS.Ad(b);return c?(c=$CLJS.B(b)instanceof $CLJS.M)?$CLJS.vd(a)?(b=$CLJS.B(b),a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c:c};
JP=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(IP,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,a],null)],null)],null),function(){return function e(d){return new $CLJS.me(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.Bd(f)){var h=$CLJS.kc(f),l=$CLJS.E(h),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.md(h,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,HP(u)],null);m.add(v);t+=1}else{h=!0;break a}return h?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}h=$CLJS.B(f);m=$CLJS.I(h,0,null);h=$CLJS.I(h,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[m,HP(h)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.iu(2,2,b))}())],null)};aca=function(a){return $CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M?$CLJS.B(a):null};
KP=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.Pi,aca,$CLJS.zu,["valid instance of one of these MBQL clauses: ",$CLJS.Lu(", ",$CLJS.ff.g($CLJS.B,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.me(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Bd(e)){var f=$CLJS.kc(e),h=$CLJS.E(f),l=$CLJS.qe(h);a:for(var m=0;;)if(m<h){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.le(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.lc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.B(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.le(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
LP=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uP],null)],null)};$CLJS.MP={};NP={};OP={};$CLJS.CP=function CP(a,b,c){var e=$CLJS.z(b);b=$CLJS.B(e);var f=$CLJS.C(e);if(f){e=$CLJS.R.j;var h=$CLJS.J.g(a,b);c=CP.j?CP.j(h,f,c):CP.call(null,h,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
AP=function AP(a,b,c,d){var f=$CLJS.z(c);c=$CLJS.B(f);var h=$CLJS.C(f),l=zP(b,c);f=$CLJS.n(a)?a:$CLJS.xd(b)?$CLJS.Bf:$CLJS.zd(b)?$CLJS.N:$CLJS.ld(b);return $CLJS.n($CLJS.n(c)?$CLJS.oF.h($CLJS.rd(f)):c)?a:$CLJS.n(c)?(b=zP(f,c),d=AP.v?AP.v(b,l,h,d):AP.call(null,b,l,h,d),$CLJS.Ll(c)&&$CLJS.xd(f)&&c>$CLJS.E(f)&&(b=$CLJS.$e(c-$CLJS.E(f),null),b=$CLJS.cf.g(f,b),f=null==f||$CLJS.Ed(f)?b:$CLJS.eg.g($CLJS.ld(f),b)),null==f||$CLJS.Gl(f)?$CLJS.R.j(f,c,d):$CLJS.wd(f)?$CLJS.ae.g(f,d):$CLJS.O.g($CLJS.V,$CLJS.R.j($CLJS.Cf(f),
c,d))):$CLJS.yd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vF],null),AP.v?AP.v(f,b,c,d):AP.call(null,f,b,c,d)):$CLJS.n($CLJS.oF.h($CLJS.rd(f)))?$CLJS.ae.g(f,d):$CLJS.Ad($CLJS.He(f))?f:$CLJS.qd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.k(null,1,[$CLJS.oF,!0],null))};PP=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);bca=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
QP=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);$CLJS.RP=new $CLJS.M(null,"dimension","dimension",543254198);cca=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);SP=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);TP=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);
UP=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);dca=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);VP=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);WP=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);eca=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
$CLJS.XP=new $CLJS.M(null,"query-hash","query-hash",-1524484965);YP=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);fca=new $CLJS.M(null,"from","from",1815293044);ZP=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);$P=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);gca=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);
$CLJS.aQ=new $CLJS.M(null,"snippet-name","snippet-name",819240328);bQ=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);hca=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);ica=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.cQ=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);
jca=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);dQ=new $CLJS.M("location","zip_code","location/zip_code",1641155222);eQ=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);fQ=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);gQ=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);hQ=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);
iQ=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);jQ=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);kQ=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);kca=new $CLJS.M(null,"lon-max","lon-max",1590224717);lQ=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);
mQ=new $CLJS.w(null,"stddev","stddev",775056588,null);nQ=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);$CLJS.oQ=new $CLJS.M(null,"snippet","snippet",953581994);pQ=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.qQ=new $CLJS.M(null,"query","query",-1288509510);rQ=new $CLJS.M(null,"string-expression","string-expression",-1395337766);lca=new $CLJS.M(null,"lat-field","lat-field",-830652957);
sQ=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);tQ=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);uQ=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);vQ=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);mca=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);
wQ=new $CLJS.M("location","country","location/country",1666636202);xQ=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);yQ=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);nca=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);zQ=new $CLJS.M(null,"unary","unary",-989314568);AQ=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
BQ=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);CQ=new $CLJS.w(null,"ceil","ceil",-184398425,null);oca=new $CLJS.M(null,"lon-min","lon-min",-787291357);DQ=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);pca=new $CLJS.M(null,"match","match",1220059550);EQ=new $CLJS.w(null,"count-where","count-where",2025939247,null);FQ=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
GQ=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);qca=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);HQ=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);IQ=new $CLJS.w(null,"sum","sum",1777518341,null);JQ=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);KQ=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.LQ=new $CLJS.M("date","range","date/range",1647265776);MQ=new $CLJS.w(null,"between","between",-1523336493,null);NQ=new $CLJS.M(null,"clause-form","clause-form",1820463737);rca=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);OQ=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);PQ=new $CLJS.w(null,"field","field",338095027,null);sca=new $CLJS.M(null,"segment-id","segment-id",1810133590);
QQ=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);$CLJS.RQ=new $CLJS.M(null,"filter","filter",-948537934);SQ=new $CLJS.w(null,"not-null","not-null",313812992,null);TQ=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);UQ=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);VQ=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);
tca=new $CLJS.M(null,"template-tag","template-tag",310841038);WQ=new $CLJS.M(null,"invalid","invalid",412869516);uca=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);XQ=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);YQ=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.ZQ=new $CLJS.M(null,"context","context",-830191113);
$Q=new $CLJS.w(null,"get-year","get-year",704520253,null);vca=new $CLJS.M(null,"format-rows?","format-rows?",992129486);aR=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);bR=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);cR=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.dR=new $CLJS.M(null,"parameters","parameters",-1229919748);
wca=new $CLJS.M(null,"json-download","json-download",-971130133);$CLJS.eR=new $CLJS.M(null,"card","card",-1430355152);fR=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);gR=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);xca=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);yca=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);hR=new $CLJS.w(null,"expression","expression",1842843403,null);
$CLJS.iR=new $CLJS.M("date","month-year","date/month-year",1948031290);jR=new $CLJS.M("metabase.mbql.schema","FieldOrAggregationReference","metabase.mbql.schema/FieldOrAggregationReference",-334144392);kR=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);lR=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);mR=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);
$CLJS.nR=new $CLJS.M(null,"joins","joins",1033962699);zca=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.oR=new $CLJS.M(null,"source-field","source-field",933829534);pR=new $CLJS.w(null,"Field","Field",430385967,null);qR=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);Aca=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Bca=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);
Cca=new $CLJS.M(null,"items","items",1031954938);rR=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);sR=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);tR=new $CLJS.M(null,"more","more",-2058821800);uR=new $CLJS.M(null,"first-clause","first-clause",-20953491);vR=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);wR=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);
Dca=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);xR=new $CLJS.w(null,"contains","contains",-1977535957,null);Eca=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);yR=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);zR=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.AR=new $CLJS.M(null,"widget-type","widget-type",1836256899);
BR=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.CR=new $CLJS.M(null,"fingerprint","fingerprint",598613022);DR=new $CLJS.w(null,"is-null","is-null",-356519403,null);ER=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);FR=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);GR=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Fca=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);
$CLJS.HR=new $CLJS.M(null,"required","required",1807647006);IR=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Gca=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Hca=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);JR=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);KR=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);LR=new $CLJS.M("string","contains","string/contains",1602423827);
MR=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);NR=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Ica=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);OR=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);Jca=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);
Kca=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);PR=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);QR=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);RR=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);SR=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);
TR=new $CLJS.w(null,"share","share",1051097594,null);Lca=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);Mca=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Nca=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);UR=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Oca=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
VR=new $CLJS.w(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);WR=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Pca=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.YR=new $CLJS.M(null,"collection","collection",-683361892);ZR=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);
$R=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.aS=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Qca=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);bS=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);cS=new $CLJS.w(null,"metric","metric",2049329604,null);dS=new $CLJS.w(null,"concat","concat",-467652465,null);eS=new $CLJS.M(null,"variable","variable",-281346492);
$CLJS.fS=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);Rca=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.gS=new $CLJS.M("date","relative","date/relative",25987732);hS=new $CLJS.M("location","city","location/city",-1746973325);Sca=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Tca=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);
iS=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);jS=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);kS=new $CLJS.M("number","between","number/between",97700581);Uca=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);lS=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);mS=new $CLJS.w(null,"sqrt","sqrt",370479598,null);
nS=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);oS=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.pS=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Vca=new $CLJS.M(null,"metric-id","metric-id",-686486942);qS=new $CLJS.w(null,"*","*",345799209,null);rS=new $CLJS.w(null,"+","+",-740910886,null);sS=new $CLJS.w(null,"-","-",-471816912,null);
$CLJS.tS=new $CLJS.M(null,"breakout","breakout",-732419050);uS=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);vS=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Wca=new $CLJS.M(null,"question","question",-1411720117);wS=new $CLJS.w(null,"asc","asc",1997386096,null);xS=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Xca=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);
yS=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);zS=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);AS=new $CLJS.w(null,"\x3c","\x3c",993667236,null);BS=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);CS=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);DS=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.ES=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);
FS=new $CLJS.M("string","ends-with","string/ends-with",302681156);Yca=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);GS=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Zca=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);HS=new $CLJS.w(null,"and","and",668631710,null);IS=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);JS=new $CLJS.w(null,"round","round",-645002441,null);
$ca=new $CLJS.M(null,"to","to",192099007);$CLJS.KS=new $CLJS.M("date","single","date/single",1554682003);ada=new $CLJS.M(null,"action-id","action-id",-1727958578);bda=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);LS=new $CLJS.w(null,"exp","exp",1378825265,null);MS=new $CLJS.w(null,"Filter","Filter",-424893332,null);NS=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);cda=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);
OS=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);PS=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);QS=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);RS=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.SS=new $CLJS.M(null,"source-table","source-table",-225307692);dda=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);
eda=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);TS=new $CLJS.w(null,"floor","floor",-772394748,null);fda=new $CLJS.M(null,"middleware","middleware",1462115504);gda=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);US=new $CLJS.M(null,"requires-features","requires-features",-101116256);VS=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);
WS=new $CLJS.M(null,"clause-name","clause-name",-996419059);XS=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);YS=new $CLJS.w(null,"now","now",-9994004,null);hda=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);ZS=new $CLJS.w(null,"not","not",1044554643,null);$S=new $CLJS.w(null,"avg","avg",1837937727,null);aT=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);
bT=new $CLJS.M(null,"max-results","max-results",-32858165);ida=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);cT=new $CLJS.w(null,"case","case",-1510733573,null);dT=new $CLJS.w(null,"distinct","distinct",-148347594,null);eT=new $CLJS.w(null,"get-second","get-second",-425414791,null);fT=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.gT=new $CLJS.M(null,"join-alias","join-alias",1454206794);
hT=new $CLJS.w(null,"is-empty","is-empty",600228619,null);jda=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);iT=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);jT=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);kda=new $CLJS.M(null,"original","original",-445386197);kT=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);lT=new $CLJS.w(null,"abs","abs",1394505050,null);
mT=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);lda=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);nT=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);mda=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.oT=new $CLJS.M(null,"date","date",-1463434462);pT=new $CLJS.M(null,"second-clause","second-clause",-461435645);
nda=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);qT=new $CLJS.w(null,"rtrim","rtrim",979195078,null);rT=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);sT=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);tT=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);
uT=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);vT=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);wT=new $CLJS.w(null,"or","or",1876275696,null);oda=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);pda=new $CLJS.M(null,"constraints","constraints",422775616);qda=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);
xT=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);rda=new $CLJS.M(null,"csv-download","csv-download",2141432084);yT=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);zT=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);sda=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);
tda=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);AT=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);BT=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);CT=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);uda=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);
DT=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);vda=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);ET=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);wda=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);xda=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);FT=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);
yda=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);zda=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Ada=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.GT=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);$CLJS.HT=new $CLJS.M(null,"database","database",1849087575);IT=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);
JT=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);Bda=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);KT=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);LT=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.MT=new $CLJS.M(null,"expressions","expressions",255689909);
NT=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Cda=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);OT=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);Dda=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);PT=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);
QT=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);RT=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);Eda=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);Fda=new $CLJS.M(null,"action","action",-811238024);ST=new $CLJS.w(null,"get-day","get-day",1768100384,null);TT=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);
UT=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);VT=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.WT=new $CLJS.M(null,"native","native",-613060878);Gda=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);XT=new $CLJS.M(null,"page","page",849072397);YT=new $CLJS.w(null,"length","length",-2065447907,null);Hda=new $CLJS.M(null,"dashboard","dashboard",-631747508);
ZT=new $CLJS.w(null,"get-week","get-week",752472178,null);$T=new $CLJS.w(null,"get-month","get-month",1271156796,null);aU=new $CLJS.w(null,"dimension","dimension",-2111181571,null);bU=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);cU=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);dU=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);
eU=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);fU=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Ida=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);gU=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);hU=new $CLJS.w(null,"substring","substring",-1513569493,null);Jda=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);
iU=new $CLJS.M(null,"internal","internal",-854870097);Kda=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);jU=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);kU=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);lU=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);
Lda=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);mU=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);nU=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);Mda=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);Nda=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);
oU=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);pU=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);qU=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);Oda=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);
Pda=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);rU=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.sU=new $CLJS.M(null,"template-tags","template-tags",1853115685);Qda=new $CLJS.M(null,"public-question","public-question",629369976);$CLJS.tU=new $CLJS.M(null,"fk-field-id","fk-field-id",1124127357);Rda=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Sda=new $CLJS.M(null,"binary","binary",-1802232288);uU=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Tda=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.vU=new $CLJS.M(null,"source-query","source-query",198004422);$CLJS.wU=new $CLJS.M(null,"settings","settings",1556144875);xU=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Uda=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);
Vda=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);yU=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);zU=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Wda=new $CLJS.M(null,"executed-by","executed-by",-739811161);Xda=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Yda=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);AU=new $CLJS.M(null,"amount","amount",364489504);BU=new $CLJS.w(null,"percentile","percentile",1039342775,null);CU=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);DU=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);EU=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
FU=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);GU=new $CLJS.w(null,"trim","trim",-1880116002,null);Zda=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);HU=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);IU=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);$da=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
JU=new $CLJS.M("string","\x3d","string/\x3d",983744235);aea=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);bea=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);cea=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);KU=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);LU=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
MU=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);dea=new $CLJS.M(null,"lat-min","lat-min",1630784161);NU=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);OU=new $CLJS.w(null,"inside","inside",-681932758,null);eea=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);PU=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
fea=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);QU=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);gea=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);hea=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.RU=new $CLJS.M(null,"card-id","card-id",-1770060179);SU=new $CLJS.M(null,"variadic","variadic",882626057);
$CLJS.TU=new $CLJS.M(null,"category","category",-593092832);UU=new $CLJS.w(null,"upper","upper",1886775433,null);VU=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);WU=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);XU=new $CLJS.w(null,"optional","optional",-600484260,null);iea=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);
YU=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);ZU=new $CLJS.M(null,"sugar","sugar",-73788488);jea=new $CLJS.M(null,"lat-max","lat-max",841568226);$U=new $CLJS.w(null,"power","power",702679448,null);aV=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);bV=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);
cV=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);dV=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);eV=new $CLJS.w(null,"median","median",-2084869638,null);fV=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);gV=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);
kea=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);hV=new $CLJS.M(null,"y","y",-1757859776);iV=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);$CLJS.jV=new $CLJS.M(null,"binning","binning",1709835866);kV=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);lV=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);mV=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);
nV=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);oV=new $CLJS.M(null,"b","b",1482224470);pV=new $CLJS.M(null,"a","a",-2123407586);qV=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);rV=new $CLJS.w(null,"replace","replace",853943757,null);sV=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);lea=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);
tV=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);uV=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);vV=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);wV=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);xV=new $CLJS.w(null,"segment","segment",675610331,null);mea=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);
yV=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);zV=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);AV=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);BV=new $CLJS.M("string","starts-with","string/starts-with",1266861170);nea=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.CV=new $CLJS.M(null,"order-by","order-by",1527318070);
DV=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);oea=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);pea=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.EV=new $CLJS.M(null,"condition","condition",1668437652);FV=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);qea=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);
GV=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);rea=new $CLJS.M(null,"card-name","card-name",-2035606807);HV=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);sea=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);tea=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);IV=new $CLJS.w(null,"log","log",45015523,null);
uea=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);JV=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.KV=new $CLJS.M(null,"database_type","database_type",-54700895);LV=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);MV=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.NV=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.OV=new $CLJS.M(null,"expression-name","expression-name",-1798450709);
$CLJS.PV=new $CLJS.M("date","all-options","date/all-options",549325958);vea=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);QV=new $CLJS.M("location","state","location/state",-114378652);RV=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);wea=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);xea=new $CLJS.M(null,"lon-field","lon-field",517872067);
SV=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);TV=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);UV=new $CLJS.M(null,"numeric","numeric",-1495594714);VV=new $CLJS.w(null,"variable","variable",1359185035,null);WV=new $CLJS.w(null,"lower","lower",-1534114948,null);XV=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);
YV=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.ZV=new $CLJS.M(null,"limit","limit",-1355822363);$V=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);aW=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);yea=new $CLJS.M(null,"pulse","pulse",-244494476);var bW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wI],null),cW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FI],null),dW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tI],null),eW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nI],null),fW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null),gW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EI],null),zea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YO],null),Aea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WO],null),hW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.NM],null),iW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RM],null),jW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JM],null),kW=new $CLJS.dh(null,new $CLJS.k(null,12,[$CLJS.ui,null,$CLJS.Gv,null,$CLJS.mj,null,$CLJS.gi,null,$CLJS.gl,null,$CLJS.vj,null,$CLJS.jl,null,$CLJS.il,null,$CLJS.ni,null,$CLJS.Cj,null,$CLJS.vi,null,$CLJS.fl,null],null),null),lW=new $CLJS.dh(null,new $CLJS.k(null,7,[$CLJS.Fv,null,$CLJS.oL,null,$CLJS.gi,null,$CLJS.el,null,$CLJS.rL,null,$CLJS.hl,null,$CLJS.Dv,null],null),null),
mW=$CLJS.Hv.g(kW,lW),Bea=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"date bucketing unit"],null)],null),kW),Cea=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"time bucketing unit"],null)],null),lW),nW=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"datetime bucketing unit"],null)],null),mW),oW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wN],null),Dea=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,
1,[$CLJS.zu,"temporal extract unit"],null),$CLJS.sL,$CLJS.fl,$CLJS.jl,$CLJS.hN,$CLJS.CN,$CLJS.DN,$CLJS.il,$CLJS.gl,$CLJS.el,$CLJS.hl,$CLJS.pL],null),Eea=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"datetime-diff unit"],null),$CLJS.oL,$CLJS.Dv,$CLJS.Fv,$CLJS.Gv,$CLJS.mj,$CLJS.vj,$CLJS.ui,$CLJS.ni],null),pW=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"temporal-extract week extraction mode"],null),$CLJS.bN,$CLJS.kN,$CLJS.pN],null),qW=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"relative-datetime unit"],null),$CLJS.gi,$CLJS.Dv,$CLJS.Fv,$CLJS.Gv,$CLJS.mj,$CLJS.vj,$CLJS.ui,$CLJS.ni],null),xW,Gea,FW,GW,HW,IW,JW,KW,LW,wY,Iea,xY,Jea,Kea,yY,Lea,Mea,Nea;$CLJS.X(BQ,JP($CLJS.lN,$CLJS.G(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.Lx],null),$CLJS.yt],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,qW],null)])));var rW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);
$CLJS.X(kT,JP($CLJS.jN,$CLJS.G(["n",$CLJS.yt,"unit",qW])));var Fea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kT],null);
$CLJS.X(XQ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,"valid :absolute-datetime clause",$CLJS.Pi,function(a){if($CLJS.Ta(IP($CLJS.fN,a)))a=WQ;else{a=$CLJS.jd(a);var b=$CLJS.EP(hW);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.oT:$CLJS.mN}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[WQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oT,JP($CLJS.fN,
$CLJS.G(["date",hW,"unit",Bea]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mN,JP($CLJS.fN,$CLJS.G(["datetime",iW,"unit",nW]))],null)],null));var sW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XQ],null);$CLJS.X(mR,JP($CLJS.pC,$CLJS.G(["time",jW,"unit",Cea])));var tW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mR],null),uW=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.zu,"date or datetime literal"],null),sW,iW,hW],null);
$CLJS.X(VP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.zu,"time literal"],null),tW,jW],null));$CLJS.X(SV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.zu,"temporal literal"],null),uW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VP],null)],null));var vW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SV],null);
$CLJS.X($da,KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof sW?new $CLJS.Gc(function(){return sW},$CLJS.qd(Aca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,iU,$CLJS.lj,$CLJS.rk,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RT,"metabase/mbql/schema.cljc",69,$CLJS.fN,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(sW)?sW.H:null])):null));return $CLJS.n(a)?a:RT}(),sW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof rW?new $CLJS.Gc(function(){return rW},$CLJS.qd(vea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,
$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iT,"metabase/mbql/schema.cljc",29,$CLJS.lN,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lN,$CLJS.bz,$CLJS.V($CLJS.Mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.Lx],null),$CLJS.yt],null)),$CLJS.xi,$CLJS.V($CLJS.Mj,$CLJS.V(XU,GS))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(rW)?rW.H:null])):null));return $CLJS.n(a)?a:iT}(),rW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof tW?new $CLJS.Gc(function(){return tW},$CLJS.qd(xda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,iU,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JV,"metabase/mbql/schema.cljc",27,$CLJS.pC,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pC,$CLJS.pC,$CLJS.V($CLJS.Mj,Zda),$CLJS.xi,$CLJS.V($CLJS.Mj,Rca)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(tW)?tW.H:null])):null));return $CLJS.n(a)?a:JV}(),tW],null)])));
$CLJS.X(HV,JP($CLJS.Bj,$CLJS.G(["value",$CLJS.pi,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KV,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,bW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,cW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pS,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.qk,dW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,nW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,bW],null)],null)],null)],null)])));var wW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HV],null);$CLJS.X(oS,JP($CLJS.zE,$CLJS.G(["expression-name",bW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,$CLJS.dk],null)])));
xW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oS],null);
Gea=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.Ge(b);b=$CLJS.J.g(c,$CLJS.HI);c=$CLJS.J.g(c,$CLJS.pI);return $CLJS.F.g(b,$CLJS.pI)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.Ge(b);b=$CLJS.J.g(c,$CLJS.HI);c=$CLJS.J.g(c,$CLJS.GI);return $CLJS.F.g(b,$CLJS.GI)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dk,new $CLJS.k(null,1,[$CLJS.zu,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"binning strategy"],null),$CLJS.pI,$CLJS.GI,$CLJS.gi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pI,new $CLJS.k(null,1,[$CLJS.It,!0],null),eW],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.GI,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,$CLJS.yl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.Tl)],null)],null)],null)],null)));$CLJS.yW=function yW(a){switch(arguments.length){case 1:return yW.h(arguments[0]);case 2:return yW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.yW.h=function(a){var b=$CLJS.Ge(a);a=$CLJS.J.g(b,$CLJS.CE);b=$CLJS.J.g(b,$CLJS.sK);return $CLJS.yW.g(a,b)};$CLJS.yW.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.PF(a,$CLJS.vG)?kW:$CLJS.PF(a,$CLJS.TH)?lW:$CLJS.PF(a,$CLJS.dH)?mW:null:null;return $CLJS.n(a)?$CLJS.Id(a,b):!0};$CLJS.yW.A=2;
$CLJS.X(YU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dk,new $CLJS.k(null,1,[$CLJS.zu,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,cW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,gW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sK,new $CLJS.k(null,
1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,nW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gT,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,bW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jV,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,Gea],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.yW],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.HI)],null)],null));
$CLJS.X(yV,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.Ge(b);b=$CLJS.J.g(b,$CLJS.CE);return"string"===typeof c?b:!0}],null)],null)}(JP($CLJS.mK,$CLJS.G(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,gW,bW],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YU],null)],null)]))));var zW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yV],null);
$CLJS.X(xT,KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof xW?new $CLJS.Gc(function(){return xW},$CLJS.qd(yR,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hR,"metabase/mbql/schema.cljc",60,$CLJS.zE,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zE,$CLJS.OV,$CLJS.V($CLJS.Mj,DQ),$CLJS.ej,$CLJS.V($CLJS.Mj,$CLJS.V(XU,$CLJS.dk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(xW)?xW.H:null])):null));return $CLJS.n(a)?a:hR}(),xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof zW?new $CLJS.Gc(function(){return zW},
$CLJS.qd(QR,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Tr,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,$CLJS.zi,$CLJS.T,$CLJS.gk],["0.39.0",$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",51,$CLJS.mK,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(zW)?zW.H:null])):null));return $CLJS.n(a)?a:PQ}(),zW],null)])));
$CLJS.AW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xT],null);$CLJS.X(PP,JP($CLJS.bK,$CLJS.G(["aggregation-clause-index",$CLJS.yt,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,$CLJS.dk],null)])));var BW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);
$CLJS.X(jR,KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof BW?new $CLJS.Gc(function(){return BW},$CLJS.qd(Xda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iV,"metabase/mbql/schema.cljc",23,$CLJS.bK,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bK,wea,$CLJS.V($CLJS.Mj,$CLJS.yt),$CLJS.ej,$CLJS.V($CLJS.Mj,$CLJS.V(XU,$CLJS.dk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(BW)?BW.H:null])):null));return $CLJS.n(a)?a:iV}(),BW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof xW?new $CLJS.Gc(function(){return xW},
$CLJS.qd(yR,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hR,"metabase/mbql/schema.cljc",60,$CLJS.zE,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zE,$CLJS.OV,$CLJS.V($CLJS.Mj,DQ),$CLJS.ej,
$CLJS.V($CLJS.Mj,$CLJS.V(XU,$CLJS.dk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(xW)?xW.H:null])):null));return $CLJS.n(a)?a:hR}(),xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof zW?new $CLJS.Gc(function(){return zW},$CLJS.qd(QR,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Tr,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,$CLJS.zi,
$CLJS.T,$CLJS.gk],["0.39.0",$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",51,$CLJS.mK,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(zW)?zW.H:null])):null));return $CLJS.n(a)?a:PQ}(),zW],null)])));
var CW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jR],null),DW=new $CLJS.dh(null,new $CLJS.k(null,11,[$CLJS.Pu,null,$CLJS.GK,null,$CLJS.JJ,null,$CLJS.LK,null,$CLJS.OJ,null,$CLJS.pK,null,$CLJS.XJ,null,$CLJS.dv,null,$CLJS.EK,null,$CLJS.oK,null,$CLJS.MK,null],null),null),EW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null);
$CLJS.X(YP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,1,[$CLJS.Pi,function(a){return"string"===typeof a?$CLJS.Vj:$CLJS.n(IP(DW,a))?rQ:$CLJS.n(IP($CLJS.Bj,a))?$CLJS.Bj:$CLJS.Uu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vj,$CLJS.Vj],null),new $CLJS.P(null,2,5,$CLJS.Q,[rQ,EW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,wW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,$CLJS.AW],null)],null));FW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YP],null);
GW=new $CLJS.dh(null,new $CLJS.k(null,26,[$CLJS.LJ,null,$CLJS.BN,null,$CLJS.mk,null,$CLJS.xN,null,$CLJS.uN,null,$CLJS.aK,null,$CLJS.pu,null,$CLJS.IJ,null,$CLJS.yN,null,$CLJS.rN,null,$CLJS.pK,null,$CLJS.IK,null,$CLJS.vN,null,$CLJS.rK,null,$CLJS.MJ,null,$CLJS.dN,null,$CLJS.Us,null,$CLJS.PJ,null,$CLJS.XJ,null,$CLJS.YM,null,$CLJS.KA,null,$CLJS.eN,null,$CLJS.qN,null,$CLJS.iN,null,$CLJS.KK,null,$CLJS.kK,null],null),null);
HW=new $CLJS.dh(null,new $CLJS.k(null,9,[$CLJS.Qs,null,$CLJS.Ks,null,$CLJS.mt,null,$CLJS.ot,null,$CLJS.Ms,null,$CLJS.FK,null,$CLJS.st,null,$CLJS.Rs,null,$CLJS.Os,null],null),null);IW=new $CLJS.dh(null,new $CLJS.k(null,17,[$CLJS.Un,null,$CLJS.DK,null,$CLJS.vK,null,$CLJS.nK,null,$CLJS.HK,null,$CLJS.JK,null,$CLJS.TJ,null,$CLJS.ZJ,null,$CLJS.cQ,null,$CLJS.UJ,null,$CLJS.AK,null,$CLJS.tK,null,$CLJS.ek,null,$CLJS.TA,null,$CLJS.gK,null,$CLJS.hK,null,$CLJS.zK,null],null),null);
JW=new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.nN,null,$CLJS.$M,null,$CLJS.zN,null,$CLJS.Us,null,$CLJS.tN,null],null),null);KW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PU],null);LW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kR],null);$CLJS.MW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oU],null);$CLJS.NW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WU],null);
$CLJS.X(cV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,"numeric expression argument",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.uv:$CLJS.n(IP(GW,a))?RS:$CLJS.n(IP(IW,a))?$CLJS.bK:$CLJS.n(IP($CLJS.Bj,a))?$CLJS.Bj:$CLJS.mK}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uv,$CLJS.yl],null),new $CLJS.P(null,2,5,$CLJS.Q,[RS,KW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,$CLJS.NW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,wW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mK,
$CLJS.AW],null)],null));var OW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cV],null);
$CLJS.X(jU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,"datetime expression argument",$CLJS.Pi,function(a){return $CLJS.n(IP(IW,a))?$CLJS.bK:$CLJS.n(IP($CLJS.Bj,a))?$CLJS.Bj:$CLJS.n(IP(JW,a))?rR:$CLJS.Uu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,$CLJS.NW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,wW],null),new $CLJS.P(null,2,5,$CLJS.Q,[rR,$CLJS.MW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,uW,$CLJS.AW],null)],null)],null));
var PW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jU],null);
$CLJS.X(NT,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,"expression argument",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.uv:$CLJS.Dd(a)?$CLJS.Ct:$CLJS.n(IP(HW,a))?bU:$CLJS.n(IP(GW,a))?RS:$CLJS.n(IP(JW,a))?rR:"string"===typeof a?$CLJS.Vj:$CLJS.n(IP(DW,a))?rQ:$CLJS.n(IP($CLJS.Bj,a))?$CLJS.Bj:$CLJS.Uu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uv,$CLJS.yl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ct,$CLJS.Ct],null),new $CLJS.P(null,2,5,$CLJS.Q,[bU,LW],null),new $CLJS.P(null,
2,5,$CLJS.Q,[RS,KW],null),new $CLJS.P(null,2,5,$CLJS.Q,[rR,$CLJS.MW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vj,$CLJS.Vj],null),new $CLJS.P(null,2,5,$CLJS.Q,[rQ,EW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,wW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,$CLJS.AW],null)],null));var QW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NT],null);$CLJS.X(LT,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.zu,"numeric expression arg or interval"],null),Fea,OW],null));
var RW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LT],null);$CLJS.X(iQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,"int greater than zero or numeric expression",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.uv:$CLJS.Uu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uv,eW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,KW],null)],null));var Hea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null);
$CLJS.X(sR,JP($CLJS.pK,$CLJS.G(["a",QW,"b",QW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,QW],null)])));var SW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sR],null);$CLJS.X(UP,JP($CLJS.JJ,$CLJS.G(["s",FW,"start",Hea,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,OW],null)])));var TW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UP],null);$CLJS.X(cU,JP($CLJS.KA,$CLJS.G(["s",FW])));var UW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cU],null);$CLJS.X(xQ,JP($CLJS.EK,$CLJS.G(["s",FW])));
var VW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null);$CLJS.X(AQ,JP($CLJS.MK,$CLJS.G(["s",FW])));var WW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null);$CLJS.X(UQ,JP($CLJS.GK,$CLJS.G(["s",FW])));var XW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UQ],null);$CLJS.X(GR,JP($CLJS.Pu,$CLJS.G(["s",FW])));var YW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GR],null);$CLJS.X(nQ,JP($CLJS.dv,$CLJS.G(["s",FW])));var ZW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null);
$CLJS.X(bV,JP($CLJS.LK,$CLJS.G(["s",FW,"match",$CLJS.Vj,"replacement",$CLJS.Vj])));var $W=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bV],null);$CLJS.X(XV,JP($CLJS.OJ,$CLJS.G(["a",FW,"b",FW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,FW],null)])));var aX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XV],null);$CLJS.X(yS,JP($CLJS.oK,$CLJS.G(["s",FW,"pattern",$CLJS.Vj])));var bX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yS],null);
$CLJS.X(wV,JP($CLJS.Us,$CLJS.G(["x",RW,"y",RW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,RW],null)])));var cX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wV],null);$CLJS.X(uV,JP($CLJS.pu,$CLJS.G(["x",OW,"y",RW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,RW],null)])));var dX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null);$CLJS.X(tV,JP($CLJS.IK,$CLJS.G(["x",OW,"y",OW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,OW],null)])));var eX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null);
$CLJS.X(vV,JP($CLJS.mk,$CLJS.G(["x",OW,"y",OW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,OW],null)])));var fX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null);$CLJS.X(KT,JP($CLJS.kK,$CLJS.G(["x",OW])));var gX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KT],null);$CLJS.X(EU,JP($CLJS.LJ,$CLJS.G(["x",OW])));var hX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EU],null);$CLJS.X($V,JP($CLJS.rK,$CLJS.G(["x",OW])));var iX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$V],null);$CLJS.X(PS,JP($CLJS.PJ,$CLJS.G(["x",OW])));
var jX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PS],null);$CLJS.X(VS,JP($CLJS.MJ,$CLJS.G(["x",OW,"y",OW])));var kX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VS],null);$CLJS.X(rT,JP($CLJS.aK,$CLJS.G(["x",OW])));var lX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rT],null);$CLJS.X(jQ,JP($CLJS.IJ,$CLJS.G(["x",OW])));var mX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jQ],null);$CLJS.X(QT,JP($CLJS.KK,$CLJS.G(["x",OW])));var nX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QT],null);
$CLJS.X(JQ,JP($CLJS.iN,$CLJS.G(["datetime-x",PW,"datetime-y",PW,"unit",Eea])));var oX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);$CLJS.X(bR,JP($CLJS.xN,$CLJS.G(["datetime",PW,"unit",Dea,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,pW],null)])));var pX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bR],null);$CLJS.X(XS,JP($CLJS.eN,$CLJS.G(["date",PW])));var qX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XS],null);$CLJS.X(yT,JP($CLJS.BN,$CLJS.G(["date",PW])));
var rX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yT],null);$CLJS.X(WP,JP($CLJS.YM,$CLJS.G(["date",PW])));var sX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WP],null);$CLJS.X(YV,JP($CLJS.rN,$CLJS.G(["date",PW,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,pW],null)])));var tX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YV],null);$CLJS.X(iS,JP($CLJS.qN,$CLJS.G(["date",PW])));var uX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iS],null);$CLJS.X(sT,JP($CLJS.vN,$CLJS.G(["date",PW])));
var vX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sT],null);$CLJS.X(FQ,JP($CLJS.yN,$CLJS.G(["datetime",PW])));var wX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);$CLJS.X(nS,JP($CLJS.uN,$CLJS.G(["datetime",PW])));var xX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nS],null);$CLJS.X(yQ,JP($CLJS.dN,$CLJS.G(["datetime",PW])));var yX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);$CLJS.X(RR,JP($CLJS.$M,$CLJS.G(["datetime",PW,"to",oW,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,oW],null)])));
var zX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RR],null),AX=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"datetime arithmetic unit"],null),$CLJS.rL,$CLJS.oL,$CLJS.Dv,$CLJS.Fv,$CLJS.Gv,$CLJS.mj,$CLJS.vj,$CLJS.ui,$CLJS.ni],null);$CLJS.X(TP,JP($CLJS.tN,$CLJS.G(["datetime",PW,"amount",OW,"unit",AX])));var BX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TP],null);
$CLJS.X(sQ,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return JP(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)}($CLJS.nN));var CX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sQ],null);$CLJS.X(SP,JP($CLJS.zN,$CLJS.G(["datetime",PW,"amount",OW,"unit",AX])));var DX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SP],null);
$CLJS.X(oU,KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof cX?new $CLJS.Gc(function(){return cX},$CLJS.qd(ET,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rS,"metabase/mbql/schema.cljc",51,$CLJS.Us,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Us,$CLJS.DI,$CLJS.V($CLJS.Mj,IT),hV,$CLJS.V($CLJS.Mj,IT),tR,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,IT))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(cX)?cX.H:null])):null));return $CLJS.n(a)?a:rS}(),cX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof BX?new $CLJS.Gc(function(){return BX},
$CLJS.qd(hda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[KR,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zT,"metabase/mbql/schema.cljc",67,$CLJS.tN,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.tN,$CLJS.mN,$CLJS.V($CLJS.Mj,TV),AU,$CLJS.V($CLJS.Mj,
FU),$CLJS.xi,$CLJS.V($CLJS.Mj,NU)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(BX)?BX.H:null])):null));return $CLJS.n(a)?a:zT}(),BX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof DX?new $CLJS.Gc(function(){return DX},$CLJS.qd(bca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,
$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[KR,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RV,"metabase/mbql/schema.cljc",72,$CLJS.zN,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zN,$CLJS.mN,$CLJS.V($CLJS.Mj,TV),AU,$CLJS.V($CLJS.Mj,FU),$CLJS.xi,$CLJS.V($CLJS.Mj,NU)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(DX)?DX.H:null])):null));
return $CLJS.n(a)?a:RV}(),DX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof zX?new $CLJS.Gc(function(){return zX},$CLJS.qd(fea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.$M,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",71,$CLJS.$M,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.$M,$CLJS.mN,$CLJS.V($CLJS.Mj,TV),$ca,$CLJS.V($CLJS.Mj,kV),fca,$CLJS.V($CLJS.Mj,$CLJS.V(XU,kV))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(zX)?zX.H:null])):null));return $CLJS.n(a)?a:qR}(),zX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&
"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof CX?new $CLJS.Gc(function(){return CX},$CLJS.qd(Ida,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YS,"metabase/mbql/schema.cljc",
45,$CLJS.nN,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nN],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(CX)?CX.H:null])):null));return $CLJS.n(a)?a:YS}(),CX],null)])));$CLJS.EX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uT],null);$CLJS.X(ZP,JP($CLJS.st,$CLJS.G(["first-clause",$CLJS.EX,"second-clause",$CLJS.EX,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$CLJS.EX],null)])));var FX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZP],null);
$CLJS.X(NR,JP($CLJS.mt,$CLJS.G(["first-clause",$CLJS.EX,"second-clause",$CLJS.EX,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$CLJS.EX],null)])));var GX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(uQ,JP($CLJS.ot,$CLJS.G(["clause",$CLJS.EX])));
var HX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uQ],null),IX=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,3,[$CLJS.zu,":field or :expression reference or :relative-datetime",$CLJS.Bu,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.Pi,function(a){return $CLJS.n(IP($CLJS.lN,a))?$CLJS.lN:$CLJS.Uu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lN,rW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,$CLJS.AW],null)],null);
$CLJS.X(lU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qk,new $CLJS.k(null,1,[$CLJS.zu,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.mt,$CLJS.Ct,$CLJS.yl,$CLJS.Vj,vW,IX,QW,wW],null)],null));var JX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lU],null);
$CLJS.X(DS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,"order comparable",$CLJS.Pi,function(a){return $CLJS.n(IP($CLJS.Bj,a))?$CLJS.Bj:$CLJS.Uu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,wW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mt,$CLJS.yl,$CLJS.Vj,vW,QW,IX],null)],null)],null));var KX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DS],null);
$CLJS.X(nV,JP($CLJS.Rs,$CLJS.G(["field",JX,"value-or-field",JX,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,JX],null)])));var LX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null);$CLJS.X(qV,JP($CLJS.FK,$CLJS.G(["field",JX,"value-or-field",JX,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,JX],null)])));var MX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null);$CLJS.X(mV,JP($CLJS.Os,$CLJS.G(["field",KX,"value-or-field",KX])));
var NX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null);$CLJS.X(lV,JP($CLJS.Ks,$CLJS.G(["field",KX,"value-or-field",KX])));var OX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null);$CLJS.X(VQ,JP($CLJS.Qs,$CLJS.G(["field",KX,"value-or-field",KX])));var PX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);$CLJS.X(aR,JP($CLJS.Ms,$CLJS.G(["field",KX,"value-or-field",KX])));var QX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aR],null);$CLJS.X(UT,JP($CLJS.RJ,$CLJS.G(["field",KX,"min",KX,"max",KX])));
var RX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UT],null);$CLJS.X(xU,JP($CLJS.WJ,$CLJS.G(["lat-field",KX,"lon-field",KX,"lat-max",KX,"lon-min",KX,"lat-min",KX,"lon-max",KX])));var SX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xU],null);$CLJS.X(FR,JP($CLJS.QJ,$CLJS.G(["field",$CLJS.AW])));var TX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FR],null);$CLJS.X(xS,JP($CLJS.$J,$CLJS.G(["field",$CLJS.AW])));var UX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xS],null);$CLJS.X(ZR,JP($CLJS.SJ,$CLJS.G(["field",$CLJS.AW])));
var VX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZR],null);$CLJS.X(bQ,JP($CLJS.YJ,$CLJS.G(["field",$CLJS.AW])));var WX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null),XX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uK,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null)],null);$CLJS.X(lS,JP($CLJS.dK,$CLJS.G(["field",FW,"string-or-field",FW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,XX],null)])));var YX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lS],null);
$CLJS.X(fT,JP($CLJS.xK,$CLJS.G(["field",FW,"string-or-field",FW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,XX],null)])));var ZX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fT],null);$CLJS.X(WR,JP($CLJS.lK,$CLJS.G(["field",FW,"string-or-field",FW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,XX],null)])));var $X=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WR],null);$CLJS.X(OT,JP($CLJS.eK,$CLJS.G(["field",FW,"string-or-field",FW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,XX],null)])));
var aY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OT],null);$CLJS.X(kQ,JP($CLJS.yK,$CLJS.G(["field",$CLJS.AW,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,$CLJS.yt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jt,$CLJS.Lx,$CLJS.dB,$CLJS.VJ],null)],null),"unit",qW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null)],null)],null)])));var bY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kQ],null);
$CLJS.X(eU,JP($CLJS.NJ,$CLJS.G(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oI],null),bW],null)])));var cY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eU],null);
$CLJS.X(kR,KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof FX?new $CLJS.Gc(function(){return FX},$CLJS.qd(aV,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HS,"metabase/mbql/schema.cljc",15,$CLJS.st,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.st,uR,$CLJS.V($CLJS.Mj,MS),pT,$CLJS.V($CLJS.Mj,MS),ER,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,MS))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(FX)?FX.H:null])):null));return $CLJS.n(a)?a:HS}(),FX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof GX?new $CLJS.Gc(function(){return GX},
$CLJS.qd(tT,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wT,"metabase/mbql/schema.cljc",14,$CLJS.mt,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mt,uR,$CLJS.V($CLJS.Mj,MS),pT,$CLJS.V($CLJS.Mj,MS),ER,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,MS))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(GX)?GX.H:null])):null));return $CLJS.n(a)?a:wT}(),GX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof HX?new $CLJS.Gc(function(){return HX},$CLJS.qd(fU,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZS,"metabase/mbql/schema.cljc",15,$CLJS.ot,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.NV,$CLJS.V($CLJS.Mj,MS)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(HX)?HX.H:null])):null));return $CLJS.n(a)?a:ZS}(),HX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof NX?new $CLJS.Gc(function(){return NX},$CLJS.qd(CT,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AS,"metabase/mbql/schema.cljc",13,$CLJS.Os,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Os,$CLJS.mK,$CLJS.V($CLJS.Mj,wR),LU,$CLJS.V($CLJS.Mj,wR)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(NX)?NX.H:null])):null));return $CLJS.n(a)?a:AS}(),NX],null),
new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof PX?new $CLJS.Gc(function(){return PX},$CLJS.qd(GV,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zS,"metabase/mbql/schema.cljc",14,$CLJS.Qs,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Qs,$CLJS.mK,$CLJS.V($CLJS.Mj,wR),LU,$CLJS.V($CLJS.Mj,wR)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(PX)?PX.H:null])):null));return $CLJS.n(a)?a:zS}(),PX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof OX?new $CLJS.Gc(function(){return OX},$CLJS.qd(AT,new $CLJS.k(null,
1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BS,"metabase/mbql/schema.cljc",13,$CLJS.Ks,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ks,$CLJS.mK,$CLJS.V($CLJS.Mj,wR),LU,$CLJS.V($CLJS.Mj,wR)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(OX)?OX.H:null])):null));
return $CLJS.n(a)?a:BS}(),OX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof QX?new $CLJS.Gc(function(){return QX},$CLJS.qd(zV,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IS,"metabase/mbql/schema.cljc",14,$CLJS.Ms,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ms,$CLJS.mK,$CLJS.V($CLJS.Mj,wR),LU,$CLJS.V($CLJS.Mj,wR)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(QX)?QX.H:null])):null));return $CLJS.n(a)?a:IS}(),QX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof LX?new $CLJS.Gc(function(){return LX},$CLJS.qd(BT,new $CLJS.k(null,
1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CS,"metabase/mbql/schema.cljc",13,$CLJS.Rs,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Rs,$CLJS.mK,$CLJS.V($CLJS.Mj,LV),LU,$CLJS.V($CLJS.Mj,LV),mU,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,LV))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",
$CLJS.n(LX)?LX.H:null])):null));return $CLJS.n(a)?a:CS}(),LX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof MX?new $CLJS.Gc(function(){return MX},$CLJS.qd(KQ,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gR,"metabase/mbql/schema.cljc",14,$CLJS.FK,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.FK,$CLJS.mK,$CLJS.V($CLJS.Mj,LV),LU,$CLJS.V($CLJS.Mj,LV),mU,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,LV))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(MX)?MX.H:null])):null));return $CLJS.n(a)?a:gR}(),MX],null)])));
$CLJS.X(uT,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,"valid filter expression",$CLJS.Pi,function(a){return $CLJS.n(IP(JW,a))?$CLJS.mN:$CLJS.n(IP(GW,a))?UV:$CLJS.n(IP(DW,a))?$CLJS.Vj:$CLJS.n(IP(HW,a))?$CLJS.Ct:$CLJS.Uu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mN,$CLJS.MW],null),new $CLJS.P(null,2,5,$CLJS.Q,[UV,KW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vj,EW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ct,LW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,KP($CLJS.G([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof FX?new $CLJS.Gc(function(){return FX},$CLJS.qd(aV,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HS,"metabase/mbql/schema.cljc",
15,$CLJS.st,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.st,uR,$CLJS.V($CLJS.Mj,MS),pT,$CLJS.V($CLJS.Mj,MS),ER,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,MS))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(FX)?FX.H:null])):null));return $CLJS.n(a)?a:HS}(),FX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof GX?new $CLJS.Gc(function(){return GX},$CLJS.qd(tT,new $CLJS.k(null,1,[$CLJS.Xj,
!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wT,"metabase/mbql/schema.cljc",14,$CLJS.mt,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mt,uR,$CLJS.V($CLJS.Mj,MS),pT,$CLJS.V($CLJS.Mj,MS),ER,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,MS))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(GX)?
GX.H:null])):null));return $CLJS.n(a)?a:wT}(),GX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof HX?new $CLJS.Gc(function(){return HX},$CLJS.qd(fU,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZS,"metabase/mbql/schema.cljc",15,$CLJS.ot,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.NV,$CLJS.V($CLJS.Mj,MS)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(HX)?HX.H:null])):null));return $CLJS.n(a)?a:ZS}(),HX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof LX?new $CLJS.Gc(function(){return LX},$CLJS.qd(BT,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CS,"metabase/mbql/schema.cljc",13,$CLJS.Rs,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Rs,$CLJS.mK,$CLJS.V($CLJS.Mj,LV),LU,$CLJS.V($CLJS.Mj,LV),mU,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,LV))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(LX)?LX.H:null])):null));
return $CLJS.n(a)?a:CS}(),LX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof MX?new $CLJS.Gc(function(){return MX},$CLJS.qd(KQ,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gR,"metabase/mbql/schema.cljc",14,$CLJS.FK,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.FK,$CLJS.mK,$CLJS.V($CLJS.Mj,LV),LU,$CLJS.V($CLJS.Mj,LV),mU,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,LV))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(MX)?MX.H:null])):null));return $CLJS.n(a)?a:gR}(),MX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof NX?new $CLJS.Gc(function(){return NX},
$CLJS.qd(CT,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AS,"metabase/mbql/schema.cljc",13,$CLJS.Os,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Os,$CLJS.mK,$CLJS.V($CLJS.Mj,wR),LU,$CLJS.V($CLJS.Mj,wR)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(NX)?NX.H:null])):null));return $CLJS.n(a)?a:AS}(),NX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof OX?new $CLJS.Gc(function(){return OX},$CLJS.qd(AT,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BS,"metabase/mbql/schema.cljc",13,$CLJS.Ks,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ks,$CLJS.mK,$CLJS.V($CLJS.Mj,wR),LU,$CLJS.V($CLJS.Mj,wR)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(OX)?OX.H:null])):null));return $CLJS.n(a)?a:BS}(),OX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof PX?new $CLJS.Gc(function(){return PX},$CLJS.qd(GV,new $CLJS.k(null,
1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zS,"metabase/mbql/schema.cljc",14,$CLJS.Qs,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Qs,$CLJS.mK,$CLJS.V($CLJS.Mj,wR),LU,$CLJS.V($CLJS.Mj,wR)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(PX)?PX.H:null])):
null));return $CLJS.n(a)?a:zS}(),PX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof QX?new $CLJS.Gc(function(){return QX},$CLJS.qd(zV,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IS,"metabase/mbql/schema.cljc",14,$CLJS.Ms,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ms,$CLJS.mK,$CLJS.V($CLJS.Mj,wR),LU,$CLJS.V($CLJS.Mj,wR)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(QX)?QX.H:null])):null));return $CLJS.n(a)?a:IS}(),QX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof RX?new $CLJS.Gc(function(){return RX},$CLJS.qd(zda,new $CLJS.k(null,
1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",19,$CLJS.RJ,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.RJ,$CLJS.mK,$CLJS.V($CLJS.Mj,wR),$CLJS.Un,$CLJS.V($CLJS.Mj,wR),$CLJS.ek,$CLJS.V($CLJS.Mj,wR)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(RX)?RX.H:null])):null));return $CLJS.n(a)?a:MQ}(),RX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof YX?new $CLJS.Gc(function(){return YX},$CLJS.qd(Bda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QU,"metabase/mbql/schema.cljc",23,$CLJS.dK,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dK,$CLJS.mK,$CLJS.V($CLJS.Mj,KU),fR,$CLJS.V($CLJS.Mj,KU),$CLJS.ej,$CLJS.V($CLJS.Mj,$CLJS.V(XU,dU))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(YX)?YX.H:null])):null));return $CLJS.n(a)?a:QU}(),YX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof ZX?new $CLJS.Gc(function(){return ZX},
$CLJS.qd(Dca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lR,"metabase/mbql/schema.cljc",21,$CLJS.xK,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xK,$CLJS.mK,$CLJS.V($CLJS.Mj,KU),fR,$CLJS.V($CLJS.Mj,KU),$CLJS.ej,$CLJS.V($CLJS.Mj,$CLJS.V(XU,dU))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(ZX)?ZX.H:null])):null));return $CLJS.n(a)?a:lR}(),ZX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof $X?new $CLJS.Gc(function(){return $X},$CLJS.qd(Pca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xR,"metabase/mbql/schema.cljc",20,$CLJS.lK,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lK,$CLJS.mK,$CLJS.V($CLJS.Mj,KU),fR,$CLJS.V($CLJS.Mj,KU),$CLJS.ej,$CLJS.V($CLJS.Mj,$CLJS.V(XU,dU))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n($X)?$X.H:null])):null));return $CLJS.n(a)?a:xR}(),$X],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==
typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof aY?new $CLJS.Gc(function(){return aY},$CLJS.qd(tea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",36,$CLJS.eK,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.eK,$CLJS.mK,$CLJS.V($CLJS.Mj,KU),fR,$CLJS.V($CLJS.Mj,KU),$CLJS.ej,$CLJS.V($CLJS.Mj,$CLJS.V(XU,dU))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(aY)?aY.H:null])):null));return $CLJS.n(a)?a:$P}(),aY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof SX?new $CLJS.Gc(function(){return SX},$CLJS.qd(cea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),
$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OU,"metabase/mbql/schema.cljc",26,$CLJS.WJ,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.WJ,lca,$CLJS.V($CLJS.Mj,wR),xea,$CLJS.V($CLJS.Mj,wR),jea,$CLJS.V($CLJS.Mj,wR),oca,$CLJS.V($CLJS.Mj,wR),dea,$CLJS.V($CLJS.Mj,wR),kca,$CLJS.V($CLJS.Mj,
wR)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(SX)?SX.H:null])):null));return $CLJS.n(a)?a:OU}(),SX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof VX?new $CLJS.Gc(function(){return VX},$CLJS.qd(Qca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hT,"metabase/mbql/schema.cljc",28,$CLJS.SJ,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SJ,$CLJS.mK,$CLJS.V($CLJS.Mj,pR)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(VX)?VX.H:null])):null));return $CLJS.n(a)?a:hT}(),VX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&
"undefined"!==typeof WX?new $CLJS.Gc(function(){return WX},$CLJS.qd(Oda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VT,"metabase/mbql/schema.cljc",29,$CLJS.YJ,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,$CLJS.mK,$CLJS.V($CLJS.Mj,pR)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(WX)?WX.H:null])):null));return $CLJS.n(a)?a:VT}(),WX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof TX?new $CLJS.Gc(function(){return TX},$CLJS.qd(oea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DR,"metabase/mbql/schema.cljc",27,$CLJS.QJ,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QJ,$CLJS.mK,$CLJS.V($CLJS.Mj,pR)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(TX)?TX.H:null])):null));return $CLJS.n(a)?a:DR}(),TX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&
"undefined"!==typeof UX?new $CLJS.Gc(function(){return UX},$CLJS.qd(bda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",28,$CLJS.$J,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,$CLJS.mK,$CLJS.V($CLJS.Mj,pR)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(UX)?UX.H:null])):null));return $CLJS.n(a)?a:SQ}(),UX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof bY?new $CLJS.Gc(function(){return bY},$CLJS.qd(cca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uU,"metabase/mbql/schema.cljc",33,$CLJS.yK,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.yK,$CLJS.mK,$CLJS.V($CLJS.Mj,pR),$CLJS.bz,$CLJS.V($CLJS.Mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,$CLJS.yt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jt,$CLJS.Lx,$CLJS.dB,$CLJS.VJ],null)],null)),$CLJS.xi,$CLJS.V($CLJS.Mj,GS),$CLJS.ej,$CLJS.V($CLJS.Mj,$CLJS.V(XU,Eda))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(bY)?bY.H:null])):null));return $CLJS.n(a)?a:uU}(),bY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof cY?new $CLJS.Gc(function(){return cY},$CLJS.qd(jda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xV,"metabase/mbql/schema.cljc",27,$CLJS.NJ,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NJ,sca,$CLJS.V($CLJS.Mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,Uda,DQ],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(cY)?cY.H:null])):null));return $CLJS.n(a)?a:xV}(),cY],null)]))],null)],null));
$CLJS.X(gU,JP($CLJS.XJ,$CLJS.G(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.pt,new $CLJS.k(null,1,[$CLJS.zu,":case subclause"],null),$CLJS.EX,QW],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.k(null,1,[$CLJS.zu,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.It,!0],null),QW],null)],null)],null)])));
var dY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gU],null);
$CLJS.X(PU,KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof cX?new $CLJS.Gc(function(){return cX},$CLJS.qd(ET,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rS,"metabase/mbql/schema.cljc",51,$CLJS.Us,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Us,$CLJS.DI,$CLJS.V($CLJS.Mj,IT),hV,$CLJS.V($CLJS.Mj,IT),tR,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,IT))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(cX)?cX.H:null])):null));return $CLJS.n(a)?a:rS}(),cX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof dX?new $CLJS.Gc(function(){return dX},
$CLJS.qd(vda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sS,"metabase/mbql/schema.cljc",51,$CLJS.pu,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.pu,$CLJS.DI,$CLJS.V($CLJS.Mj,FU),
hV,$CLJS.V($CLJS.Mj,IT),tR,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,IT))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(dX)?dX.H:null])):null));return $CLJS.n(a)?a:sS}(),dX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP?new $CLJS.Gc(function(){return eX},$CLJS.qd(uda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,WS,$CLJS.Ui,$CLJS.lj,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,
new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Vu,"metabase/mbql/schema.cljc",$CLJS.IK,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IK,$CLJS.DI,$CLJS.V($CLJS.Mj,FU),hV,$CLJS.V($CLJS.Mj,FU),tR,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,FU))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(eX)?eX.H:null])):null));return $CLJS.n(a)?a:$CLJS.Vu}(),eX],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof fX?new $CLJS.Gc(function(){return fX},$CLJS.qd(wda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qS,"metabase/mbql/schema.cljc",51,$CLJS.mk,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mk,$CLJS.DI,$CLJS.V($CLJS.Mj,FU),hV,$CLJS.V($CLJS.Mj,FU),tR,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,FU))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(fX)?fX.H:null])):null));return $CLJS.n(a)?a:qS}(),fX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof SW?new $CLJS.Gc(function(){return SW},
$CLJS.qd(qU,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HU,"metabase/mbql/schema.cljc",58,$CLJS.pK,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.pK,pV,$CLJS.V($CLJS.Mj,eQ),oV,$CLJS.V($CLJS.Mj,
eQ),tR,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,eQ))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(SW)?SW.H:null])):null));return $CLJS.n(a)?a:HU}(),SW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof UW?new $CLJS.Gc(function(){return UW},$CLJS.qd(Nda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,
$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YT,"metabase/mbql/schema.cljc",56,$CLJS.KA,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KA,$CLJS.RB,$CLJS.V($CLJS.Mj,KU)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(UW)?UW.H:null])):null));return $CLJS.n(a)?a:YT}(),UW],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof gX?new $CLJS.Gc(function(){return gX},$CLJS.qd(Eca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TS,"metabase/mbql/schema.cljc",55,$CLJS.kK,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kK,$CLJS.DI,$CLJS.V($CLJS.Mj,FU)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(gX)?gX.H:null])):null));return $CLJS.n(a)?a:TS}(),gX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof hX?new $CLJS.Gc(function(){return hX},$CLJS.qd(yca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),
$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CQ,"metabase/mbql/schema.cljc",54,$CLJS.LJ,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LJ,$CLJS.DI,$CLJS.V($CLJS.Mj,FU)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(hX)?
hX.H:null])):null));return $CLJS.n(a)?a:CQ}(),hX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof iX?new $CLJS.Gc(function(){return iX},$CLJS.qd(Mca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JS,"metabase/mbql/schema.cljc",55,$CLJS.rK,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rK,$CLJS.DI,$CLJS.V($CLJS.Mj,FU)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(iX)?iX.H:null])):null));return $CLJS.n(a)?a:JS}(),iX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&
"undefined"!==typeof jX?new $CLJS.Gc(function(){return jX},$CLJS.qd(uea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lT,"metabase/mbql/schema.cljc",53,$CLJS.PJ,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.PJ,$CLJS.DI,$CLJS.V($CLJS.Mj,FU)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(jX)?jX.H:null])):null));return $CLJS.n(a)?a:lT}(),jX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof kX?new $CLJS.Gc(function(){return kX},$CLJS.qd(hea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,
NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[DV,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$U,"metabase/mbql/schema.cljc",69,$CLJS.MJ,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.MJ,$CLJS.DI,$CLJS.V($CLJS.Mj,FU),hV,$CLJS.V($CLJS.Mj,FU)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(kX)?kX.H:null])):null));return $CLJS.n(a)?a:$U}(),kX],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof lX?new $CLJS.Gc(function(){return lX},$CLJS.qd(eda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[DV,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mS,"metabase/mbql/schema.cljc",68,$CLJS.aK,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,$CLJS.DI,$CLJS.V($CLJS.Mj,FU)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(lX)?lX.H:null])):null));return $CLJS.n(a)?a:mS}(),lX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof mX?new $CLJS.Gc(function(){return mX},$CLJS.qd(iea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),
$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[DV,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LS,"metabase/mbql/schema.cljc",67,$CLJS.IJ,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IJ,$CLJS.DI,$CLJS.V($CLJS.Mj,FU)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(mX)?
mX.H:null])):null));return $CLJS.n(a)?a:LS}(),mX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof nX?new $CLJS.Gc(function(){return nX},$CLJS.qd(rca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[DV,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IV,"metabase/mbql/schema.cljc",67,$CLJS.KK,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KK,$CLJS.DI,$CLJS.V($CLJS.Mj,FU)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(nX)?nX.H:null])):null));return $CLJS.n(a)?a:IV}(),nX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&
"undefined"!==typeof dY?new $CLJS.Gc(function(){return dY},$CLJS.qd(pQ,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cT,"metabase/mbql/schema.cljc",61,$CLJS.XJ,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.XJ,$CLJS.ez,$CLJS.V($CLJS.Mj,JR),$CLJS.ej,$CLJS.V($CLJS.Mj,$CLJS.V(XU,vT))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(dY)?dY.H:null])):null));return $CLJS.n(a)?a:cT}(),dY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof oX?new $CLJS.Gc(function(){return oX},$CLJS.qd(uca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,
$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.iN,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mT,"metabase/mbql/schema.cljc",65,$CLJS.iN,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iN,Gca,$CLJS.V($CLJS.Mj,TV),Hca,$CLJS.V($CLJS.Mj,TV),$CLJS.xi,$CLJS.V($CLJS.Mj,mda)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(oX)?oX.H:null])):null));return $CLJS.n(a)?a:mT}(),oX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof pX?new $CLJS.Gc(function(){return pX},$CLJS.qd(Rda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),$CLJS.qd(DU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FT,"metabase/mbql/schema.cljc",71,$CLJS.xN,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xN,$CLJS.mN,$CLJS.V($CLJS.Mj,TV),$CLJS.xi,$CLJS.V($CLJS.Mj,nea),$CLJS.Ci,$CLJS.V($CLJS.Mj,$CLJS.V(XU,aW))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(pX)?pX.H:null])):null));return $CLJS.n(a)?a:FT}(),pX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof qX?new $CLJS.Gc(function(){return qX},$CLJS.qd(Yca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$Q,"metabase/mbql/schema.cljc",71,$CLJS.eN,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eN,$CLJS.oT,$CLJS.V($CLJS.Mj,TV)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(qX)?qX.H:null])):null));return $CLJS.n(a)?a:$Q}(),qX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof rX?new $CLJS.Gc(function(){return rX},$CLJS.qd(Nca,new $CLJS.k(null,1,[$CLJS.Xj,!0],
null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DT,"metabase/mbql/schema.cljc",74,$CLJS.BN,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BN,$CLJS.oT,$CLJS.V($CLJS.Mj,TV)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(rX)?rX.H:null])):null));return $CLJS.n(a)?a:DT}(),rX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof sX?new $CLJS.Gc(function(){return sX},$CLJS.qd(tda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),
$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$T,"metabase/mbql/schema.cljc",72,$CLJS.YM,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YM,$CLJS.oT,$CLJS.V($CLJS.Mj,TV)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(sX)?sX.H:null])):null));return $CLJS.n(a)?a:$T}(),sX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==
typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof tX?new $CLJS.Gc(function(){return tX},$CLJS.qd(mea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZT,"metabase/mbql/schema.cljc",
71,$CLJS.rN,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rN,$CLJS.oT,$CLJS.V($CLJS.Mj,TV),$CLJS.Ci,$CLJS.V($CLJS.Mj,$CLJS.V(XU,aW))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(tX)?tX.H:null])):null));return $CLJS.n(a)?a:ZT}(),tX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof uX?new $CLJS.Gc(function(){return uX},$CLJS.qd(ica,new $CLJS.k(null,1,[$CLJS.Xj,
!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ST,"metabase/mbql/schema.cljc",70,$CLJS.qN,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qN,$CLJS.oT,$CLJS.V($CLJS.Mj,TV)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(uX)?uX.H:null])):null));return $CLJS.n(a)?a:ST}(),uX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof vX?new $CLJS.Gc(function(){return vX},$CLJS.qd(nda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),
$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JT,"metabase/mbql/schema.cljc",78,$CLJS.vN,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vN,$CLJS.oT,$CLJS.V($CLJS.Mj,TV)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(vX)?vX.H:null])):null));return $CLJS.n(a)?a:JT}(),vX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==
typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof wX?new $CLJS.Gc(function(){return wX},$CLJS.qd(lda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",
71,$CLJS.yN,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yN,$CLJS.mN,$CLJS.V($CLJS.Mj,TV)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(wX)?wX.H:null])):null));return $CLJS.n(a)?a:fQ}(),wX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof xX?new $CLJS.Gc(function(){return xX},$CLJS.qd(Vda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,
$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MV,"metabase/mbql/schema.cljc",73,$CLJS.uN,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uN,$CLJS.mN,$CLJS.V($CLJS.Mj,TV)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(xX)?xX.H:null])):null));
return $CLJS.n(a)?a:MV}(),xX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof yX?new $CLJS.Gc(function(){return yX},$CLJS.qd(ida,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.xN,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eT,"metabase/mbql/schema.cljc",73,$CLJS.dN,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dN,$CLJS.mN,$CLJS.V($CLJS.Mj,TV)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(yX)?yX.H:null])):null));return $CLJS.n(a)?a:eT}(),yX],null)])));
$CLJS.X(vQ,KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof TW?new $CLJS.Gc(function(){return TW},$CLJS.qd(Lda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hU,"metabase/mbql/schema.cljc",59,$CLJS.JJ,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JJ,$CLJS.RB,$CLJS.V($CLJS.Mj,KU),$CLJS.qx,$CLJS.V($CLJS.Mj,Jca),$CLJS.KA,$CLJS.V($CLJS.Mj,$CLJS.V(XU,FU))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(TW)?TW.H:null])):null));return $CLJS.n(a)?a:hU}(),TW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof VW?new $CLJS.Gc(function(){return VW},
$CLJS.qd(kea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GU,"metabase/mbql/schema.cljc",54,$CLJS.EK,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EK,$CLJS.RB,$CLJS.V($CLJS.Mj,KU)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(VW)?VW.H:null])):null));return $CLJS.n(a)?a:GU}(),VW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof XW?new $CLJS.Gc(function(){return XW},$CLJS.qd(oda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,
1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nU,"metabase/mbql/schema.cljc",55,$CLJS.GK,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GK,$CLJS.RB,$CLJS.V($CLJS.Mj,KU)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(XW)?XW.H:null])):null));return $CLJS.n(a)?a:nU}(),XW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&
"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof WW?new $CLJS.Gc(function(){return WW},$CLJS.qd(Mda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qT,"metabase/mbql/schema.cljc",
55,$CLJS.MK,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MK,$CLJS.RB,$CLJS.V($CLJS.Mj,KU)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(WW)?WW.H:null])):null));return $CLJS.n(a)?a:qT}(),WW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof $W?new $CLJS.Gc(function(){return $W},$CLJS.qd(dca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,
$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rV,"metabase/mbql/schema.cljc",57,$CLJS.LK,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LK,$CLJS.RB,$CLJS.V($CLJS.Mj,KU),pca,$CLJS.V($CLJS.Mj,$CLJS.Vj),$CLJS.Tu,$CLJS.V($CLJS.Mj,$CLJS.Vj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n($W)?$W.H:null])):null));return $CLJS.n(a)?a:rV}(),$W],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof ZW?new $CLJS.Gc(function(){return ZW},$CLJS.qd(Pda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WV,"metabase/mbql/schema.cljc",55,$CLJS.dv,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dv,$CLJS.RB,$CLJS.V($CLJS.Mj,KU)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(ZW)?ZW.H:null])):null));return $CLJS.n(a)?a:WV}(),ZW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==
typeof OP&&"undefined"!==typeof YW?new $CLJS.Gc(function(){return YW},$CLJS.qd(lea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UU,"metabase/mbql/schema.cljc",55,$CLJS.Pu,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pu,$CLJS.RB,$CLJS.V($CLJS.Mj,KU)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(YW)?YW.H:null])):null));return $CLJS.n(a)?a:UU}(),YW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof aX?new $CLJS.Gc(function(){return aX},$CLJS.qd(Dda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,
$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dS,"metabase/mbql/schema.cljc",56,$CLJS.OJ,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OJ,pV,$CLJS.V($CLJS.Mj,KU),oV,$CLJS.V($CLJS.Mj,KU),tR,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,KU))],null),$CLJS.Kc,"Schema for a valid concat clause.",$CLJS.n(aX)?
aX.H:null])):null));return $CLJS.n(a)?a:dS}(),aX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof bX?new $CLJS.Gc(function(){return bX},$CLJS.qd(Kda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.MT,"null",$CLJS.Zu,"null"],null),null),
$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",74,$CLJS.oK,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.oK,$CLJS.RB,$CLJS.V($CLJS.Mj,KU),$CLJS.Ou,$CLJS.V($CLJS.Mj,$CLJS.Vj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(bX)?bX.H:null])):null));return $CLJS.n(a)?a:YQ}(),bX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==
typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof SW?new $CLJS.Gc(function(){return SW},$CLJS.qd(qU,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.MT,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
HU,"metabase/mbql/schema.cljc",58,$CLJS.pK,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.pK,pV,$CLJS.V($CLJS.Mj,eQ),oV,$CLJS.V($CLJS.Mj,eQ),tR,$CLJS.V($CLJS.Mj,$CLJS.V($CLJS.Ni,eQ))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(SW)?SW.H:null])):null));return $CLJS.n(a)?a:HU}(),SW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof dY?new $CLJS.Gc(function(){return dY},
$CLJS.qd(pQ,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cT,"metabase/mbql/schema.cljc",61,$CLJS.XJ,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.XJ,$CLJS.ez,$CLJS.V($CLJS.Mj,JR),$CLJS.ej,
$CLJS.V($CLJS.Mj,$CLJS.V(XU,vT))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(dY)?dY.H:null])):null));return $CLJS.n(a)?a:cT}(),dY],null)])));
$CLJS.eY=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,":field or :expression reference or expression",$CLJS.Pi,function(a){return $CLJS.n(IP(GW,a))?UV:$CLJS.n(IP(DW,a))?$CLJS.Vj:$CLJS.n(IP(HW,a))?$CLJS.Ct:$CLJS.n(IP(JW,a))?$CLJS.mN:$CLJS.n(IP($CLJS.XJ,a))?$CLJS.XJ:$CLJS.Uu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[UV,KW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vj,EW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ct,LW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mN,$CLJS.MW],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,dY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,$CLJS.AW],null)],null);$CLJS.X(AV,JP($CLJS.TA,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,$CLJS.AW],null)])));var fY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AV],null);$CLJS.X(nT,JP($CLJS.nK,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.It,$CLJS.AW],null)])));var gY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nT],null);$CLJS.X(tQ,JP($CLJS.gK,$CLJS.G(["field-or-expression",$CLJS.eY])));
var hY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tQ],null);$CLJS.X(sV,JP($CLJS.JK,$CLJS.G(["field-or-expression",$CLJS.eY])));var iY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null);$CLJS.X(UR,JP($CLJS.TJ,$CLJS.G(["field-or-expression",$CLJS.eY])));var jY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UR],null);$CLJS.X(IU,JP($CLJS.zK,$CLJS.G(["field-or-expression",$CLJS.eY])));var kY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IU],null);$CLJS.X(PR,JP($CLJS.Un,$CLJS.G(["field-or-expression",$CLJS.eY])));
var lY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);$CLJS.X(BR,JP($CLJS.ek,$CLJS.G(["field-or-expression",$CLJS.eY])));var mY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BR],null);$CLJS.X(vR,JP($CLJS.HK,$CLJS.G(["field-or-expression",$CLJS.eY,"pred",$CLJS.EX])));var nY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vR],null);$CLJS.X(MR,JP($CLJS.vK,$CLJS.G(["pred",$CLJS.EX])));var oY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MR],null);$CLJS.X(zU,JP($CLJS.tK,$CLJS.G(["pred",$CLJS.EX])));
var pY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zU],null);$CLJS.X(QQ,JP($CLJS.DK,$CLJS.G(["field-or-expression",$CLJS.eY])));var qY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QQ],null);$CLJS.X(QP,JP($CLJS.UJ,$CLJS.G(["field-or-expression",$CLJS.eY])));var rY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null);$CLJS.X($R,JP($CLJS.AK,$CLJS.G(["field-or-expression",$CLJS.eY])));var sY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$R],null);$CLJS.X(OS,JP($CLJS.ZJ,$CLJS.G(["field-or-expression",$CLJS.eY,"percentile",OW])));
var tY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OS],null);$CLJS.X(CU,JP($CLJS.hK,$CLJS.G(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mI],null),bW],null)])));var uY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CU],null);
$CLJS.X(yU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,"unnamed aggregation clause or numeric expression",$CLJS.Pi,function(a){return $CLJS.n(IP(GW,a))?RS:$CLJS.Uu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[RS,KW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof hY?new $CLJS.Gc(function(){return hY},$CLJS.qd(Jda,
new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$S,"metabase/mbql/schema.cljc",60,$CLJS.gK,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gK,VU,$CLJS.V($CLJS.Mj,MU)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(hY)?hY.H:null])):null));return $CLJS.n(a)?a:$S}(),hY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof iY?new $CLJS.Gc(function(){return iY},$CLJS.qd(Uca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gV,"metabase/mbql/schema.cljc",64,$CLJS.JK,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JK,VU,$CLJS.V($CLJS.Mj,MU)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(iY)?iY.H:null])):null));return $CLJS.n(a)?a:gV}(),iY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==
typeof OP&&"undefined"!==typeof jY?new $CLJS.Gc(function(){return jY},$CLJS.qd(Lca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dT,"metabase/mbql/schema.cljc",65,$CLJS.TJ,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TJ,VU,$CLJS.V($CLJS.Mj,MU)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(jY)?jY.H:null])):null));return $CLJS.n(a)?a:dT}(),jY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof qY?new $CLJS.Gc(function(){return qY},$CLJS.qd(eea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,
$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.wK,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",76,$CLJS.DK,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DK,VU,$CLJS.V($CLJS.Mj,MU)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(qY)?qY.H:null])):null));return $CLJS.n(a)?a:mQ}(),qY],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof kY?new $CLJS.Gc(function(){return kY},$CLJS.qd(yda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IQ,"metabase/mbql/schema.cljc",60,$CLJS.zK,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zK,VU,$CLJS.V($CLJS.Mj,MU)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(kY)?kY.H:null])):null));return $CLJS.n(a)?a:IQ}(),kY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof lY?new $CLJS.Gc(function(){return lY},$CLJS.qd(mca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,
$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Xt,"metabase/mbql/schema.cljc",60,$CLJS.Un,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Un,VU,$CLJS.V($CLJS.Mj,MU)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(lY)?lY.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Xt}(),lY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof mY?new $CLJS.Gc(function(){return mY},$CLJS.qd(hca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bu,"metabase/mbql/schema.cljc",60,$CLJS.ek,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,VU,$CLJS.V($CLJS.Mj,MU)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(mY)?mY.H:null])):null));return $CLJS.n(a)?a:$CLJS.bu}(),mY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&
"undefined"!==typeof uY?new $CLJS.Gc(function(){return uY},$CLJS.qd(Ica,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cS,"metabase/mbql/schema.cljc",18,$CLJS.hK,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,Vca,$CLJS.V($CLJS.Mj,new $CLJS.P(null,3,5,
$CLJS.Q,[$CLJS.mt,Zca,DQ],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(uY)?uY.H:null])):null));return $CLJS.n(a)?a:cS}(),uY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof pY?new $CLJS.Gc(function(){return pY},$CLJS.qd(gda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,
$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TR,"metabase/mbql/schema.cljc",62,$CLJS.tK,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tK,$CLJS.mo,$CLJS.V($CLJS.Mj,MS)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(pY)?pY.H:null])):null));return $CLJS.n(a)?a:TR}(),pY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof oY?new $CLJS.Gc(function(){return oY},$CLJS.qd(nca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EQ,"metabase/mbql/schema.cljc",68,$CLJS.vK,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vK,$CLJS.mo,$CLJS.V($CLJS.Mj,MS)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(oY)?oY.H:null])):null));return $CLJS.n(a)?a:EQ}(),oY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof nY?new $CLJS.Gc(function(){return nY},$CLJS.qd(sea,new $CLJS.k(null,1,[$CLJS.Xj,!0],
null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aT,"metabase/mbql/schema.cljc",66,$CLJS.HK,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.HK,VU,$CLJS.V($CLJS.Mj,MU),$CLJS.mo,$CLJS.V($CLJS.Mj,MS)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(nY)?nY.H:null])):null));return $CLJS.n(a)?a:aT}(),nY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof dY?new $CLJS.Gc(function(){return dY},$CLJS.qd(pQ,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cT,"metabase/mbql/schema.cljc",61,$CLJS.XJ,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.XJ,$CLJS.ez,$CLJS.V($CLJS.Mj,JR),$CLJS.ej,$CLJS.V($CLJS.Mj,$CLJS.V(XU,vT))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(dY)?dY.H:null])):null));return $CLJS.n(a)?a:cT}(),dY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&
"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof sY?new $CLJS.Gc(function(){return sY},$CLJS.qd(Yda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.iK,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eV,"metabase/mbql/schema.cljc",
68,$CLJS.AK,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AK,VU,$CLJS.V($CLJS.Mj,MU)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(sY)?sY.H:null])):null));return $CLJS.n(a)?a:eV}(),sY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof tY?new $CLJS.Gc(function(){return tY},$CLJS.qd(sda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,
WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.iK,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BU,"metabase/mbql/schema.cljc",72,$CLJS.ZJ,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZJ,VU,$CLJS.V($CLJS.Mj,MU),$CLJS.ZJ,$CLJS.V($CLJS.Mj,FU)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(tY)?tY.H:null])):
null));return $CLJS.n(a)?a:BU}(),tY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof rY?new $CLJS.Gc(function(){return rY},$CLJS.qd(xca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hQ,"metabase/mbql/schema.cljc",77,$CLJS.UJ,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UJ,VU,$CLJS.V($CLJS.Mj,MU)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(rY)?rY.H:null])):null));return $CLJS.n(a)?a:hQ}(),rY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof gY?new $CLJS.Gc(function(){return gY},$CLJS.qd(jca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,
$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NS,"metabase/mbql/schema.cljc",74,$CLJS.nK,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nK,$CLJS.mK,$CLJS.V($CLJS.Mj,$CLJS.V(XU,pR))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(gY)?gY.H:null])):null));return $CLJS.n(a)?a:NS}(),gY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof fY?new $CLJS.Gc(function(){return fY},$CLJS.qd(Kca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([US,$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,ZU,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),
$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Bi,"metabase/mbql/schema.cljc",70,$CLJS.TA,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TA,$CLJS.mK,$CLJS.V($CLJS.Mj,$CLJS.V(XU,pR))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(fY)?fY.H:null])):null));return $CLJS.n(a)?a:$CLJS.Bi}(),fY],null)]))],null)],null));
$CLJS.X(PT,JP($CLJS.cQ,$CLJS.G(["aggregation",yU,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dk,new $CLJS.k(null,1,[$CLJS.zu,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.It,!0],null),bW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rI,new $CLJS.k(null,1,[$CLJS.It,!0],null),bW],null)],null)])));
$CLJS.X(WU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,2,[$CLJS.zu,"aggregation clause or numeric expression",$CLJS.Pi,function(a){return $CLJS.n(IP($CLJS.cQ,a))?$CLJS.cQ:IR}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cQ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PT],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[IR,yU],null)],null));$CLJS.X(lQ,JP($CLJS.jK,$CLJS.G(["field",CW])));var vY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);$CLJS.X(QS,JP($CLJS.cK,$CLJS.G(["field",CW])));
wY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QS],null);
Iea=KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof vY?new $CLJS.Gc(function(){return vY},$CLJS.qd(bea,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
wS,"metabase/mbql/schema.cljc",15,$CLJS.jK,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jK,$CLJS.mK,$CLJS.V($CLJS.Mj,VR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(vY)?vY.H:null])):null));return $CLJS.n(a)?a:wS}(),vY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof wY?new $CLJS.Gc(function(){return wY},$CLJS.qd(Tca,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rU,"metabase/mbql/schema.cljc",16,$CLJS.cK,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cK,$CLJS.mK,$CLJS.V($CLJS.Mj,VR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(wY)?wY.H:null])):null));return $CLJS.n(a)?a:rU}(),wY],null)]));
xY=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jt,$CLJS.oQ,$CLJS.eR,$CLJS.RP,$CLJS.uv,$CLJS.HE,$CLJS.oT],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,bW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rI,bW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.k(null,1,[$CLJS.It,!0],null),bW],null)],null);
Jea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,xY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.oQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aQ,bW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GT,eW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HT,new $CLJS.k(null,1,[$CLJS.It,!0],null),eW],null)],null)],null);
Kea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,xY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.eR],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RU,eW],null)],null)],null);
yY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,xY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.pi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HR,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null)],null)],null);Lea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fV],null);
Mea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,yY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.RP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RP,zW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rt,$CLJS.Wi,$CLJS.pi],null)],null)],null)],null);
$CLJS.zY=new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.oT,null,$CLJS.uv,null,$CLJS.Ct,null,$CLJS.HE,null],null),null);Nea=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jt],null),$CLJS.zY);
$CLJS.X(kU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,1,[$CLJS.Pi,$CLJS.kj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RP,Mea],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oQ,Jea],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eR,Kea],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,yY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,Nea],null)],null)],null)],null)],null));
var AY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sU,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rt,bW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.F.g(c,
$CLJS.S.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YR,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,bW],null)],null)],null),Oea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,AY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qQ,$CLJS.pi],null)],null)],null),BY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TT],null),CY=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,1,[$CLJS.Pi,function(a){return $CLJS.n($CLJS.vP($CLJS.yd,
$CLJS.WT)(a))?$CLJS.WT:$CLJS.qI}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,AY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WT,$CLJS.pi],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qI,BY],null)],null),DY=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,bW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RG,cW],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ada,bW],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.pS,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,dW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CR,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.dk],null)],null)],null),EY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,Aea,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,new $CLJS.k(null,1,[$CLJS.zu,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Pea=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jt],null),
new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.pJ,null,$CLJS.TI,null,$CLJS.hJ,null,$CLJS.nJ,null],null),null)),FY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dV],null);
$CLJS.X(SR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SS,new $CLJS.k(null,1,[$CLJS.It,!0],null),EY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vU,new $CLJS.k(null,1,[$CLJS.It,!0],null),CY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EV,$CLJS.EX],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HI,new $CLJS.k(null,1,[$CLJS.It,!0],null),Pea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XI,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.zu,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jt,$CLJS.cC,$CLJS.Wu],null),FY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aJ,new $CLJS.k(null,1,[$CLJS.It,!0],null),bW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tU,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,gW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ES,new $CLJS.k(null,
1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,DY],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.vP($CLJS.WD($CLJS.SS,$CLJS.vU),$CLJS.Oe($CLJS.vP($CLJS.SS,$CLJS.vU)))],null)],null));var Qea=$CLJS.Q,GY;var HY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null)],null);
if($CLJS.xd(HY)&&$CLJS.F.g($CLJS.B(HY),$CLJS.xj)){var IY=$CLJS.z(HY);$CLJS.B(IY);var JY=$CLJS.C(IY),Rea=$CLJS.yd($CLJS.B(JY))?JY:$CLJS.de(null,JY),KY=$CLJS.z(Rea),Sea=$CLJS.B(KY),Tea=$CLJS.C(KY);GY=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,$CLJS.R.j(Sea,$CLJS.Un,1)],null),Tea)}else GY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,HY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"non-empty"],null),$CLJS.z],null)],null);
$CLJS.X(TQ,new $CLJS.P(null,3,5,Qea,[$CLJS.st,GY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"All join aliases must be unique."],null),function(a){return $CLJS.tP($CLJS.jf($CLJS.Ua,$CLJS.ff.g($CLJS.aJ,a)))}],null)],null));var Uea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TQ],null);
$CLJS.X(dV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nl,new $CLJS.k(null,1,[$CLJS.zu,"Distinct, non-empty sequence of Field clauses"],null),LP(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.k(null,1,[$CLJS.Un,1],null),$CLJS.AW],null))],null));
$CLJS.X(TT,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vU,new $CLJS.k(null,1,[$CLJS.It,!0],null),CY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SS,new $CLJS.k(null,1,[$CLJS.It,!0],null),EY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.k(null,1,[$CLJS.Un,1],null),$CLJS.NW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tS,new $CLJS.k(null,1,
[$CLJS.It,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.k(null,1,[$CLJS.Un,1],null),$CLJS.AW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MT,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rt,bW,$CLJS.eY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XI,new $CLJS.k(null,1,[$CLJS.It,!0],null),FY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.EX],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZV,new $CLJS.k(null,
1,[$CLJS.It,!0],null),fW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CV,new $CLJS.k(null,1,[$CLJS.It,!0],null),LP(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.k(null,1,[$CLJS.Un,1],null),Iea],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[XT,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[XT,eW],null),new $CLJS.P(null,2,5,$CLJS.Q,[Cca,eW],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nR,new $CLJS.k(null,1,[$CLJS.It,!0],null),Uea],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ES,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,DY],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.F.g(1,$CLJS.E($CLJS.bh(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vU,$CLJS.SS],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,
new $CLJS.k(null,1,[$CLJS.zu,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.Ge(a);a=$CLJS.J.g(b,$CLJS.tS);b=$CLJS.J.g(b,$CLJS.XI);return $CLJS.ud($CLJS.Qv.g($CLJS.ih(a),$CLJS.ih(b)))}],null)],null));
$CLJS.LY=$CLJS.Ig([$CLJS.TU,$CLJS.oT,OR,zR,FS,QV,kS,$CLJS.PV,$CLJS.uv,jS,wQ,JU,FV,$CLJS.LQ,BV,LR,$CLJS.KS,hS,$CLJS.Yi,$CLJS.gS,dQ,$CLJS.iR,$CLJS.fS,jT,$CLJS.Ct,$CLJS.HE,bS],[new $CLJS.k(null,1,[vS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.TU,null,$CLJS.oT,null,$CLJS.uv,null,$CLJS.Ct,null,$CLJS.HE,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,$CLJS.oT,vS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.TU,null,$CLJS.oT,null,$CLJS.PV,null,$CLJS.KS,null,$CLJS.Yi,null],null),null)],null),new $CLJS.k(null,
3,[$CLJS.kj,$CLJS.Vj,$CLJS.zI,SU,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[OR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.kj,UV,$CLJS.zI,SU,vS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.TU,null,zR,null,$CLJS.uv,null,$CLJS.Yi,null,dQ,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.kj,$CLJS.Vj,$CLJS.zI,zQ,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[FS,null],null),null)],null),new $CLJS.k(null,1,[vS,new $CLJS.dh(null,new $CLJS.k(null,1,[QV,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.kj,
UV,$CLJS.zI,Sda,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[kS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,$CLJS.oT,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.PV,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,UV,vS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.TU,null,zR,null,$CLJS.uv,null,$CLJS.Yi,null,dQ,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.kj,UV,$CLJS.zI,zQ,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[jS,null],null),null)],null),new $CLJS.k(null,1,[vS,new $CLJS.dh(null,
new $CLJS.k(null,1,[wQ,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.kj,$CLJS.Vj,$CLJS.zI,SU,vS,new $CLJS.dh(null,new $CLJS.k(null,8,[$CLJS.TU,null,QV,null,wQ,null,JU,null,hS,null,$CLJS.Yi,null,dQ,null,$CLJS.HE,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.kj,$CLJS.Vj,$CLJS.zI,zQ,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[FV,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,$CLJS.oT,vS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.PV,null,$CLJS.LQ,null],null),null)],null),new $CLJS.k(null,
3,[$CLJS.kj,$CLJS.Vj,$CLJS.zI,zQ,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[BV,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.kj,$CLJS.Vj,$CLJS.zI,zQ,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[LR,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,$CLJS.oT,vS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.TU,null,$CLJS.oT,null,$CLJS.PV,null,$CLJS.KS,null,$CLJS.Yi,null],null),null)],null),new $CLJS.k(null,1,[vS,new $CLJS.dh(null,new $CLJS.k(null,1,[hS,null],null),null)],null),new $CLJS.k(null,1,[vS,
new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.Yi,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,$CLJS.oT,vS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.PV,null,$CLJS.gS,null],null),null)],null),new $CLJS.k(null,1,[vS,new $CLJS.dh(null,new $CLJS.k(null,1,[dQ,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,$CLJS.oT,vS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.PV,null,$CLJS.iR,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,$CLJS.oT,vS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.PV,
null,$CLJS.fS,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.kj,UV,$CLJS.zI,SU,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[jT,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,$CLJS.Ct,vS,new $CLJS.dh(null,new $CLJS.k(null,3,[$CLJS.TU,null,$CLJS.Yi,null,$CLJS.Ct,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.kj,$CLJS.Vj,vS,new $CLJS.dh(null,new $CLJS.k(null,8,[$CLJS.TU,null,QV,null,wQ,null,JU,null,hS,null,$CLJS.Yi,null,dQ,null,$CLJS.HE,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.kj,
UV,$CLJS.zI,zQ,vS,new $CLJS.dh(null,new $CLJS.k(null,1,[bS,null],null),null)],null)]);$CLJS.X(fV,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.LY)));$CLJS.X(HQ,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"valid template tag widget type"],null),$CLJS.Wu],null),$CLJS.Xg($CLJS.LY)));
$CLJS.X(pU,JP(tca,$CLJS.G(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,bW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,bW],null)],null)],null)])));var MY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pU],null);$CLJS.X(gQ,JP($CLJS.RP,$CLJS.G(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,$CLJS.AW,MY],null)])));var NY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null);$CLJS.X(cR,JP(eS,$CLJS.G(["target",MY])));
var OY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cR],null),Vea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,$CLJS.AW,KP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof NY?new $CLJS.Gc(function(){return NY},$CLJS.qd(qda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aU,"metabase/mbql/schema.cljc",21,$CLJS.RP,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RP,$CLJS.EN,$CLJS.V($CLJS.Mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,pR,uS],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(NY)?NY.H:null])):null));return $CLJS.n(a)?a:aU}(),NY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=WS.h($CLJS.rd("undefined"!==typeof $CLJS.MP&&
"undefined"!==typeof NP&&"undefined"!==typeof OP&&"undefined"!==typeof OY?new $CLJS.Gc(function(){return OY},$CLJS.qd(Gda,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,WS,$CLJS.Ui,$CLJS.lj,$CLJS.rk,NQ,$CLJS.zi,$CLJS.T,$CLJS.gk],[$CLJS.qd(DU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VV,"metabase/mbql/schema.cljc",20,eS,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[eS,$CLJS.EN,
$CLJS.V($CLJS.Mj,uS)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(OY)?OY.H:null])):null));return $CLJS.n(a)?a:VV}(),OY],null)]))],null),FP=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OQ],null);
$CLJS.X(OQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.vP($CLJS.WD($CLJS.WT,$CLJS.qQ),$CLJS.Oe($CLJS.vP($CLJS.WT,$CLJS.qQ)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.Ge(b);b=$CLJS.J.g(c,$CLJS.WT);var d=$CLJS.J.g(c,$CLJS.qQ);c=$CLJS.J.g(c,$CLJS.kj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HT,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.zu,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$O],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZO],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jt,$CLJS.qQ,$CLJS.WT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WT,new $CLJS.k(null,1,[$CLJS.It,!0],null),Oea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qQ,new $CLJS.k(null,1,[$CLJS.It,!0],null),BY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dR,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,Lea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.k(null,1,[$CLJS.It,!0],null),bW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,new $CLJS.k(null,1,[$CLJS.It,!0],null),Vea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.pi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.It,!0],null),bW],null),new $CLJS.P(null,3,5,$CLJS.Q,[zca,new $CLJS.k(null,1,[$CLJS.It,!0],null),
bW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.pi],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wU,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[qea,new $CLJS.k(null,1,[$CLJS.It,!0],null),oW],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[pda,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[bT,new $CLJS.k(null,1,[$CLJS.It,!0],null),fW],null),new $CLJS.P(null,3,5,$CLJS.Q,[GQ,new $CLJS.k(null,1,[$CLJS.It,!0],null),fW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.Ge(a);a=$CLJS.J.g(b,bT);b=$CLJS.J.g(b,GQ);return $CLJS.Ta($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[fda,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[aea,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[vca,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[eca,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[Bca,new $CLJS.k(null,1,[$CLJS.It,
!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tda,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[gca,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[gea,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qC,new $CLJS.k(null,
1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZQ,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.jt,Fda,Cda,$CLJS.YR,rda,Hda,dda,qca,wca,Fca,Sca,Qda,yea,Wca,Oca],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wda,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,eW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ada,
new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,eW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RU,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,zea],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rea,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,bW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[cda,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,eW],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[pea,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rt,$CLJS.pi,$CLJS.pi],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xca,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,eW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aS,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rt,$CLJS.pi,
$CLJS.pi],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XP,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.pi],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Oe($CLJS.ES)],null)],null));var PY=$CLJS.EP(FP);
(function(){var a=$ba();return function(b){if($CLJS.n(PY.h?PY.h(b):PY.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.BP(b);throw $CLJS.li($CLJS.LI("Invalid query: {0}",$CLJS.G([$CLJS.Ph.l($CLJS.G([c]))])),new $CLJS.k(null,2,[$CLJS.kC,c,kda,b],null));}})();