var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var nD=function(){},oD=function(a){return $CLJS.J.g($CLJS.ZB,a).value},pD=function(a){var b=oD(a);return $CLJS.n(function(){var c=oD($CLJS.tC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.ZC:$CLJS.n(function(){var c=oD($CLJS.sC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.iD:$CLJS.n(function(){var c=oD($CLJS.qC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.bD:$CLJS.n(function(){var c=oD($CLJS.hC);return $CLJS.Dk.g?
$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.hD:$CLJS.n(function(){var c=oD($CLJS.rC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.jD:$CLJS.n(function(){var c=oD($CLJS.mC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.eD:$CLJS.n(function(){var c=oD($CLJS.dC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.fD:$CLJS.gD},qD=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},rD=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ae.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.mD,c))].join(""),$CLJS.G(["color:black"]))],null)},sD=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ae.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.mD,$CLJS.kD)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.mD,
c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],null)},tD=function(a){function b(c,d){return d>=c}a=oD(a);if(b(oD($CLJS.tC),a))return"error";if(b(oD($CLJS.sC),a))return"warn";if(b(oD($CLJS.qC),a))return"info";b(oD($CLJS.hC),a);return"log"},uD=function(a){return function(b){var c=$CLJS.Ge(b),d=$CLJS.J.g(c,$CLJS.eC),e=$CLJS.J.g(c,$CLJS.cv);b=$CLJS.J.g(c,$CLJS.uC);e=tD(e);e=$CLJS.Da(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.O.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=["[",
$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},vD=function vD(a,b){for(;;){if($CLJS.F.g($CLJS.YC,b))return rD(a,", ",$CLJS.gD);if($CLJS.F.g($CLJS.$C,b))return qD(a);if(b instanceof $CLJS.M)return rD(a,b,$CLJS.bD);if(b instanceof $CLJS.w)return rD(a,b,$CLJS.hD);if("string"===typeof b)return rD(a,$CLJS.Ph.l($CLJS.G([b])),$CLJS.aD);if($CLJS.yf(b)){var d=qD(function(){var f=a,h=$CLJS.Ab(b);return vD.g?vD.g(f,h):vD.call(null,f,h)}()),e=$CLJS.Bb(b);
return vD.g?vD.g(d,e):vD.call(null,d,e)}if(b instanceof $CLJS.k||b instanceof $CLJS.Hg)return d=a,d=rD(d,"{",$CLJS.lD),d=$CLJS.bb(vD,d,$CLJS.bf($CLJS.YC,b)),rD(d,"}",$CLJS.lD);if($CLJS.yd(b))return d=a,d=rD(d,["#",$CLJS.p.h(function(){var f=$CLJS.Ya(b),h=f.name;return $CLJS.ud(h)?$CLJS.Ph.l($CLJS.G([f])):h}())," "].join(""),$CLJS.cD),d=rD(d,"{",$CLJS.lD),d=$CLJS.bb(vD,d,$CLJS.bf($CLJS.YC,b)),rD(d,"}",$CLJS.lD);if($CLJS.wd(b))return d=a,d=rD(d,"#{",$CLJS.lD),d=$CLJS.bb(vD,d,$CLJS.bf($CLJS.$C,b)),rD(d,
"}",$CLJS.lD);if($CLJS.Ad(b))return d=a,d=rD(d,"[",$CLJS.lD),d=$CLJS.bb(vD,d,$CLJS.bf($CLJS.$C,b)),rD(d,"]",$CLJS.lD);if(b instanceof $CLJS.Rf)d=rD(a,"#queue ",$CLJS.cD),e=$CLJS.eg.g($CLJS.Bf,b),a=d,b=e;else{if($CLJS.Ed(b))return d=a,d=rD(d,"(",$CLJS.cD),d=$CLJS.bb(vD,d,$CLJS.bf($CLJS.$C,b)),rD(d,")",$CLJS.cD);if(null!=b?b.I&16384||$CLJS.r===b.Ei||(b.I?0:$CLJS.Xa(nD,b)):$CLJS.Xa(nD,b))d=rD(a,"#atom ",$CLJS.cD),e=$CLJS.q(b),a=d,b=e;else if($CLJS.ii(b))d=rD(a,"#uuid ",$CLJS.cD),e=$CLJS.p.h(b),a=d,b=
e;else if($CLJS.Va(b))d=rD(a,"#js ",$CLJS.cD),e=$CLJS.bb(function(f,h){return function(l,m){return $CLJS.R.j(l,$CLJS.Ch.h(m),$CLJS.Da(h,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Sa(b))d=rD(a,"#js ",$CLJS.cD),e=$CLJS.eg.g($CLJS.Bf,b),a=d,b=e;else return rD(a,$CLJS.Ph.l($CLJS.G([b])),$CLJS.dD)}}};$CLJS.wD=uD(function(a){a=$CLJS.Ge(a);$CLJS.J.g(a,$CLJS.cv);var b=$CLJS.J.g(a,$CLJS.eC),c=$CLJS.J.g(a,$CLJS.Ri);$CLJS.J.g(a,$CLJS.uC);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.xD=uD(function(a){var b=$CLJS.Ge(a),c=$CLJS.J.g(b,$CLJS.cv);a=$CLJS.J.g(b,$CLJS.eC);var d=$CLJS.J.g(b,$CLJS.Ri);$CLJS.J.g(b,$CLJS.uC);b=pD(c);d=vD(qD(sD(sD(sD(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Bf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.de(a,d)});
$CLJS.yD=uD(function(a){a=$CLJS.Ge(a);$CLJS.J.g(a,$CLJS.cv);var b=$CLJS.J.g(a,$CLJS.eC),c=$CLJS.J.g(a,$CLJS.Ri);$CLJS.J.g(a,$CLJS.uC);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Ph.l($CLJS.G([c]))],null)});