var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var V$=function(a){return new $CLJS.me(null,function(){return $CLJS.de(a.o?a.o():a.call(null),V$(a))},null,null)},W$=function(a,b){return new $CLJS.me(null,function(){var c=$CLJS.z(b);if(c){var d=$CLJS.B(c),e=a.h?a.h(d):a.call(null,d),f=$CLJS.de(d,$CLJS.ew(function(h){return $CLJS.F.g(e,a.h?a.h(h):a.call(null,h))},$CLJS.C(c)));return $CLJS.de(f,W$(a,new $CLJS.me(null,function(){return $CLJS.Ye($CLJS.E(f),c)},null,null)))}return null},null,null)},vpa=function(a,b){switch(a){case "minutes":return $CLJS.oZ("Minute",
"Minutes",b);case "hours":return $CLJS.oZ("Hour","Hours",b);case "days":return $CLJS.oZ("Day","Days",b);case "weeks":return $CLJS.oZ("Week","Weeks",b);case "months":return $CLJS.oZ("Month","Months",b);case "quarters":return $CLJS.oZ("Quarter","Quarters",b);case "years":return $CLJS.oZ("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},wpa=function(a,b,c){b=parseInt(b);c=vpa(c,b);a=new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.F.g(b,1)],null);if($CLJS.F.g(new $CLJS.P(null,
2,5,$CLJS.Q,["past",!0],null),a))return $CLJS.LI("Previous {0}",$CLJS.G([c]));if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,["past",!1],null),a))return $CLJS.LI("Previous {0} {1}",$CLJS.G([b,c]));if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!0],null),a))return $CLJS.LI("Next {0}",$CLJS.G([c]));if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!1],null),a))return $CLJS.LI("Next {0} {1}",$CLJS.G([b,c]));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));},ypa=function(a){return $CLJS.aE(a,
xpa,function(b){return["\\",$CLJS.p.h(b)].join("")})},X$=function(a,b,c,d,e){this.tag=a;this.source=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264},Apa=function(a){var b=$CLJS.sv(a,zpa),c=$CLJS.ff.g($CLJS.B,$CLJS.gZ(Y$,a)),d=function(){var e=$CLJS.E(b),f=$CLJS.E(c);return e>f?e:f}();a=$CLJS.cf.g(b,$CLJS.Xe(d-$CLJS.E(b),V$($CLJS.Pe(""))));d=$CLJS.cf.g(c,$CLJS.Xe(d-$CLJS.E(c),V$($CLJS.Pe(""))));a=$CLJS.af.g(a,d);return $CLJS.ff.g(function(e){var f=$CLJS.qh(Y$,e);return $CLJS.n(f)?($CLJS.I(f,
0,null),f=$CLJS.I(f,1,null),new X$(f,e,null,null,null)):e},a)},Bpa=function(a){return $CLJS.gf(function(b){return"string"===typeof $CLJS.B(b)?new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mZ(b)],null):b},$CLJS.G([W$(function(b){return"string"===typeof b||$CLJS.Ta($CLJS.Bj.h(b))},a)]))},Cpa=function(a,b,c,d){return $CLJS.ff.g(function(e){if(e instanceof X$){var f=$CLJS.R.j;var h=$CLJS.Ej.h(e),l=$CLJS.J.g(a,h);h=$CLJS.Bj.h(l);l=$CLJS.kj.h(l);if($CLJS.n(h))try{var m=Z$.j(l,h,b);var t=$CLJS.n(c)?ypa(m):m}catch(u){if(u instanceof
Error)t=Z$.j($CLJS.gi,h,b);else throw u;}else t=null;e=f.call($CLJS.R,e,$CLJS.Bj,t)}return e},d)},Fpa=function(a){a=$CLJS.mZ($CLJS.ff.g(function(b){return b instanceof X$?b:$CLJS.aE(b,Dpa,"")},a));return $CLJS.aE(a,Epa,$CLJS.jd)},Gpa=function(a){return $CLJS.YD($CLJS.B($CLJS.A8(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dR],null),new $CLJS.P(null,1,5,$CLJS.Q,[a],null))),$CLJS.Ch)};var Z$=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.Ch.h(f)},e,a,b,c,d)}();Z$.m(null,$CLJS.KS,function(a,b,c){return(0,$CLJS.XM)(b).locale(c).format("MMMM D, YYYY")});
Z$.m(null,$CLJS.iR,function(a,b,c){a=(0,$CLJS.XM)(b,"YYYY-MM").locale(c);return $CLJS.n(a.isValid())?a.format("MMMM, YYYY"):""});Z$.m(null,$CLJS.fS,function(a,b,c){a=(0,$CLJS.XM)(b,"[Q]Q-YYYY").locale(c);return $CLJS.n(a.isValid())?a.format("[Q]Q, YYYY"):""});Z$.m(null,$CLJS.LQ,function(a,b,c){b=$CLJS.sv(b,/~/);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?[$CLJS.p.h(Z$.j($CLJS.KS,a,c))," - ",$CLJS.p.h(Z$.j($CLJS.KS,b,c))].join(""):""});
Z$.m(null,$CLJS.gS,function(a,b){function c(d,e){return $CLJS.C($CLJS.rh(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.MI("Today");if(c(/^thisweek$/,b))return $CLJS.MI("This Week");if(c(/^thismonth$/,b))return $CLJS.MI("This Month");if(c(/^thisquarter$/,b))return $CLJS.MI("This Quarter");if(c(/^thisyear$/,b))return $CLJS.MI("This Year");if(c(/^past1days$/,b))return $CLJS.MI("Yesterday");if(c(/^next1days$/,b))return $CLJS.MI("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.O.g(wpa,
a);throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));});Z$.m(null,$CLJS.PV,function(a,b,c){function d(e,f){return $CLJS.jd($CLJS.rh(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.n(a))return Z$.j($CLJS.gS,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.n(a))return Z$.j($CLJS.KS,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.n(a)?Z$.j($CLJS.LQ,a,c):Z$.j($CLJS.gS,b,c)});
Z$.m(null,$CLJS.gi,function(a,b){$CLJS.xd(b)?(a=$CLJS.E(b),b=$CLJS.n($CLJS.F.g?$CLJS.F.g(1,a):$CLJS.F.call(null,1,a))?$CLJS.p.h($CLJS.B(b)):$CLJS.n($CLJS.F.g?$CLJS.F.g(2,a):$CLJS.F.call(null,2,a))?$CLJS.LI("{0} and {1}",$CLJS.G([$CLJS.B(b),$CLJS.jd(b)])):$CLJS.LI("{0}, {1}, and {2}",$CLJS.G([$CLJS.Lu(", ",$CLJS.fw(2,b)),$CLJS.cd(b,$CLJS.E(b)-2),$CLJS.kd(b)]))):b=$CLJS.p.h(b);return b});var xpa=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,Y$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,zpa=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.g=X$.prototype;$CLJS.g.toString=function(){var a=$CLJS.Bj.h(this);return $CLJS.n(a)?a:this.source};$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Cb=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.cf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,this.tag],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Su,this.source],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Ju(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.Su],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Je())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-884441273^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.tag,b.tag)&&$CLJS.F.g(this.source,b.source)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Mb=function(a,b){return $CLJS.Id(new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.Su,null,$CLJS.Ej,null],null),null),b)?$CLJS.Ak.g($CLJS.Lb($CLJS.eg.g($CLJS.N,this),this.S),b):new X$(this.tag,this.source,this.S,$CLJS.He($CLJS.Ak.g(this.G,b)),null)};$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.ge.g?$CLJS.ge.g($CLJS.Ej,b):$CLJS.ge.call(null,$CLJS.Ej,b))?new X$(c,this.source,this.S,this.G,null):$CLJS.n($CLJS.ge.g?$CLJS.ge.g($CLJS.Su,b):$CLJS.ge.call(null,$CLJS.Su,b))?new X$(this.tag,c,this.S,this.G,null):new X$(this.tag,this.source,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.cf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.Yf($CLJS.Ej,this.tag),new $CLJS.Yf($CLJS.Su,this.source)],null),this.G))};
$CLJS.g.P=function(a,b){return new X$(this.tag,this.source,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Ad(b)?this.na(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.bb($CLJS.lb,this,b)};var Dpa=/\[\[.+\]\]/,Epa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.g(arguments[0],arguments[1]);case 4:return $$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.za("metabase.shared.parameters.parameters.substitute_tags",$$);$$.g=function(a,b){return $$.v(a,b,"en",!0)};$$.v=function(a,b,c,d){return $CLJS.n(a)?(b=$CLJS.ED(b),b=$CLJS.Ku(b,Gpa),Fpa(Bpa(Cpa(b,c,d,Apa(a))))):null};$$.A=4;module.exports={tag_names:function(a){a=$CLJS.ih($CLJS.ff.g($CLJS.jd,$CLJS.gZ(Y$,$CLJS.n(a)?a:"")));return $CLJS.bl(a)},substitute_tags:$$};