var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var fZ,kZ,nZ;$CLJS.gZ=function(a,b){if("string"===typeof b)return fZ(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.hZ=function(a,b){return $CLJS.fc($CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.ec(c,d):c},$CLJS.cc($CLJS.Bf),b))};$CLJS.iZ=function(a){return a instanceof Error?a.message:null};
$CLJS.jZ=function(a,b,c){if($CLJS.Zd(c)){var d=$CLJS.O.g($CLJS.V,$CLJS.ff.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.yf(c)?(d=new $CLJS.Yf(function(){var e=$CLJS.Ab(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Bb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Ed(c)?(d=$CLJS.ph($CLJS.ff.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.zd(c)?(d=$CLJS.bb(function(e,f){return $CLJS.ae.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.vd(c)?
(d=$CLJS.eg.g($CLJS.ld(c),$CLJS.ff.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.lZ=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ch.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return kZ(function(c){return $CLJS.yd(c)?$CLJS.eg.g($CLJS.N,$CLJS.ff.g(b,c)):c},a)};$CLJS.mZ=function(a){var b=new $CLJS.Fa;for(a=$CLJS.z(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.B(a))),a=$CLJS.C(a);else return b.toString()};
$CLJS.oZ=function(a,b,c){a=$CLJS.aE(a,/''/,"'");var d=$CLJS.sv(a,nZ);d=$CLJS.F.g($CLJS.E(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.rh(/.*\{0\}.*/,a);return $CLJS.Eu.ngettext($CLJS.Eu.msgid($CLJS.bl(d),$CLJS.n(a)?c:""),$CLJS.aE($CLJS.aE(b,/''/,"'"),nZ,$CLJS.p.h(c)),c)};$CLJS.pZ=function(a,b){return $CLJS.bb(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Id(a,e)?$CLJS.R.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.O.j($CLJS.Ak,a,$CLJS.Xg(b)),b)};
$CLJS.qZ=function(a,b,c){return $CLJS.yd(c)?$CLJS.gf(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.ae.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.G([c])):$CLJS.xd(c)?$CLJS.gf($CLJS.Qe(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.ae.g(b,$CLJS.B(c)):b),$CLJS.G([c])):null};
$CLJS.rZ=function(a,b,c){return $CLJS.yd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.md(l,A),H=$CLJS.I(D,0,null),K=$CLJS.I(D,1,null);D=t;var U=$CLJS.Q,Z=H;H=$CLJS.ae.g(b,H);H=a.g?a.g(H,K):a.call(null,H,K);D.add(new $CLJS.P(null,2,5,U,[Z,H],null));A+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.B(h),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.ae.g(b,v),D=x;return a.g?a.g(A,D):a.call(null,A,D)}()],null),f($CLJS.Lc(h)))}return null}},null,null)}(c)}()):$CLJS.xd(c)?$CLJS.Jk.g($CLJS.Qe(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.ae.g(b,$CLJS.B(c)):b),c):c};
fZ=function fZ(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Cf(d);return $CLJS.de(f,new $CLJS.me(null,function(){var h=e.length;h=d.index+(1>h?1:h);return h<=b.length?(h=b.substring(h),fZ.g?fZ.g(a,h):fZ.call(null,a,h)):null},null,null))};
$CLJS.sZ=function sZ(a){switch(arguments.length){case 3:return sZ.j(arguments[0],arguments[1],arguments[2]);case 4:return sZ.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return sZ.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return sZ.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return sZ.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.sZ.j=function(a,b,c){var d=$CLJS.z(b);b=$CLJS.B(d);if(d=$CLJS.C(d))a=$CLJS.R.j(a,b,$CLJS.sZ.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.sZ.v=function(a,b,c,d){var e=$CLJS.z(b);b=$CLJS.B(e);if(e=$CLJS.C(e))a=$CLJS.R.j(a,b,$CLJS.sZ.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.sZ.N=function(a,b,c,d,e){var f=$CLJS.z(b);b=$CLJS.B(f);if(f=$CLJS.C(f))a=$CLJS.R.j(a,b,$CLJS.sZ.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var h=$CLJS.J.g(a,b);c=c.j?c.j(h,d,e):c.call(null,h,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.sZ.W=function(a,b,c,d,e,f){var h=$CLJS.z(b);b=$CLJS.B(h);if(h=$CLJS.C(h))a=$CLJS.R.j(a,b,$CLJS.sZ.W($CLJS.J.g(a,b),h,c,d,e,f));else{h=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=h.call($CLJS.R,a,b,c)}return a};$CLJS.sZ.l=function(a,b,c,d,e,f,h){var l=$CLJS.z(b);b=$CLJS.B(l);return(l=$CLJS.C(l))?$CLJS.R.j(a,b,$CLJS.O.l($CLJS.sZ,$CLJS.J.g(a,b),l,c,d,$CLJS.G([e,f,h]))):$CLJS.R.j(a,b,$CLJS.O.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.G([h])))};
$CLJS.sZ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var h=$CLJS.C(f);f=$CLJS.B(h);h=$CLJS.C(h);return this.l(b,a,c,d,e,f,h)};$CLJS.sZ.A=6;kZ=function kZ(a,b){return $CLJS.jZ($CLJS.Qe(kZ,a),a,b)};nZ=/\{0\}/;
$CLJS.tZ=function tZ(a){switch(arguments.length){case 3:return tZ.j(arguments[0],arguments[1],arguments[2]);case 4:return tZ.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return tZ.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return tZ.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return tZ.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.tZ.j=function(a,b,c){var d=$CLJS.bm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Bb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.tZ.v=function(a,b,c,d){var e=$CLJS.bm(a,b);if($CLJS.n(e)){var f=$CLJS.R.j;e=$CLJS.Bb(e);c=c.g?c.g(e,d):c.call(null,e,d);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.tZ.N=function(a,b,c,d,e){var f=$CLJS.bm(a,b);if($CLJS.n(f)){var h=$CLJS.R.j;f=$CLJS.Bb(f);c=c.j?c.j(f,d,e):c.call(null,f,d,e);a=h.call($CLJS.R,a,b,c)}return a};$CLJS.tZ.W=function(a,b,c,d,e,f){var h=$CLJS.bm(a,b);if($CLJS.n(h)){var l=$CLJS.R.j;h=$CLJS.Bb(h);c=c.v?c.v(h,d,e,f):c.call(null,h,d,e,f);a=l.call($CLJS.R,a,b,c)}return a};$CLJS.tZ.l=function(a,b,c,d,e,f,h){var l=$CLJS.bm(a,b);return $CLJS.n(l)?$CLJS.R.j(a,b,$CLJS.O.l(c,$CLJS.Bb(l),d,e,f,$CLJS.G([h]))):a};
$CLJS.tZ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var h=$CLJS.C(f);f=$CLJS.B(h);h=$CLJS.C(h);return this.l(b,a,c,d,e,f,h)};$CLJS.tZ.A=6;$CLJS.uZ=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.vZ=new $CLJS.M(null,"field-id","field-id",-353751335);$CLJS.wZ=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);
$CLJS.xZ=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var Zea=$CLJS.Ig([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rN,$CLJS.kN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YM,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vN,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.rN,$CLJS.bN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rN,$CLJS.pN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dN,null],null)],[$CLJS.fl,$CLJS.sL,$CLJS.CN,$CLJS.hN,$CLJS.el,$CLJS.il,$CLJS.hl,$CLJS.jl,$CLJS.gl,$CLJS.hN,$CLJS.DN,$CLJS.pL]),zZ;$CLJS.ih($CLJS.ff.g($CLJS.B,$CLJS.Xg(Zea)));var yZ,$ea=$CLJS.Te($CLJS.N),afa=$CLJS.Te($CLJS.N),bfa=$CLJS.Te($CLJS.N),cfa=$CLJS.Te($CLJS.N),dfa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
yZ=new $CLJS.hi($CLJS.Ih.g("metabase.mbql.util","negate*"),$CLJS.B,dfa,$ea,afa,bfa,cfa);yZ.m(null,$CLJS.ot,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});yZ.m(null,$CLJS.st,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mt],null),$CLJS.ff.g(yZ,a))});yZ.m(null,$CLJS.mt,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.st],null),$CLJS.ff.g(yZ,a))});
yZ.m(null,$CLJS.Rs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FK,b,a],null)});yZ.m(null,$CLJS.FK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rs,b,a],null)});yZ.m(null,$CLJS.Ks,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qs,b,a],null)});
yZ.m(null,$CLJS.Os,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,b,a],null)});yZ.m(null,$CLJS.Ms,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,b,a],null)});yZ.m(null,$CLJS.Qs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,b,a],null)});
yZ.m(null,$CLJS.RJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,b,a],null)],null)});yZ.m(null,$CLJS.lK,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ot,a],null)});yZ.m(null,$CLJS.dK,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ot,a],null)});
yZ.m(null,$CLJS.xK,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ot,a],null)});
zZ=function zZ(a){return $CLJS.yd(a)?$CLJS.He($CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.me(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Bd(f)){var h=$CLJS.kc(f),l=$CLJS.E(h),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(h,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=zZ.h?zZ.h(u):zZ.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{h=!0;break a}return h?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}h=$CLJS.B(f);
m=$CLJS.I(h,0,null);h=$CLJS.I(h,1,null);h=zZ.h?zZ.h(h):zZ.call(null,h);if(null!=h)return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[m,h],null),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(a)}())):$CLJS.xd(a)?$CLJS.He($CLJS.eg.g($CLJS.ld(a),$CLJS.jf($CLJS.Ua,$CLJS.ff.g(zZ,a)))):a};
$CLJS.AZ=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var h=Array(arguments.length-2);f<h.length;)h[f]=arguments[f+2],++f;f=new $CLJS.y(h,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),h=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.He(zZ($CLJS.O.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,h,d],null):$CLJS.F.g(f,$CLJS.mK)?new $CLJS.P(null,3,5,$CLJS.Q,[f,h,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,h],null)}a.A=2;a.B=function(c){var d=
$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();