var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var ZM,aN,cN,oN,sN,AN;$CLJS.YM=new $CLJS.M(null,"get-month","get-month",-369374731);ZM=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.$M=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);aN=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.bN=new $CLJS.M(null,"iso","iso",-1366207543);cN=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.dN=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.eN=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.fN=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.gN=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.hN=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.iN=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.jN=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.kN=new $CLJS.M(null,"us","us",746429226);$CLJS.lN=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.mN=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.nN=new $CLJS.M(null,"now","now",-1650525531);oN=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.pN=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.qN=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.rN=new $CLJS.M(null,"get-week","get-week",-888059349);
sN=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.tN=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.uN=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.vN=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.wN=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.xN=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.yN=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.zN=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);AN=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.BN=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.CN=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.DN=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.EN=new $CLJS.M(null,"target","target",253001721);$CLJS.FJ($CLJS.jN,$CLJS.G([$CLJS.pu,$CLJS.OG,$CLJS.yt,$CLJS.lI]));$CLJS.OI.m(null,$CLJS.gN,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.PI(a);return $CLJS.wd(a)?(a=$CLJS.Qv.g(a,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.vG,null,$CLJS.dH,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.yJ($CLJS.gN,$CLJS.jJ);
for(var FN=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tN,$CLJS.zN],null)),GN=null,HN=0,IN=0;;)if(IN<HN){var JN=GN.X(null,IN);$CLJS.FJ(JN,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null),$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lI],null)]));$CLJS.yJ(JN,$CLJS.gN);IN+=1}else{var KN=$CLJS.z(FN);if(KN){var LN=KN;if($CLJS.Bd(LN)){var MN=$CLJS.kc(LN),gba=$CLJS.lc(LN),hba=MN,iba=$CLJS.E(MN);FN=gba;GN=hba;HN=iba}else{var NN=$CLJS.B(LN);$CLJS.FJ(NN,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.$I],null),$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lI],null)]));$CLJS.yJ(NN,$CLJS.gN);FN=$CLJS.C(LN);GN=null;HN=0}IN=0}else break}
for(var ON=$CLJS.z(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eN,$CLJS.YM,$CLJS.qN,$CLJS.yN,$CLJS.uN,$CLJS.dN,$CLJS.BN],null)),PN=null,QN=0,RN=0;;)if(RN<QN){var jba=PN.X(null,RN);$CLJS.FJ(jba,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null)]));RN+=1}else{var SN=$CLJS.z(ON);if(SN){var TN=SN;if($CLJS.Bd(TN)){var UN=$CLJS.kc(TN),kba=$CLJS.lc(TN),lba=UN,mba=$CLJS.E(UN);ON=kba;PN=lba;QN=mba}else{var nba=$CLJS.B(TN);$CLJS.FJ(nba,$CLJS.G([$CLJS.pu,
$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null)]));ON=$CLJS.C(TN);PN=null;QN=0}RN=0}else break}$CLJS.FJ($CLJS.iN,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tL],null)]));
for(var VN=$CLJS.z(new $CLJS.dh(null,new $CLJS.k(null,8,[$CLJS.BN,null,$CLJS.uN,null,$CLJS.yN,null,$CLJS.vN,null,$CLJS.dN,null,$CLJS.YM,null,$CLJS.eN,null,$CLJS.qN,null],null),null)),WN=null,XN=0,YN=0;;)if(YN<XN){var oba=WN.X(null,YN);$CLJS.FJ(oba,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null)]));YN+=1}else{var ZN=$CLJS.z(VN);if(ZN){var $N=ZN;if($CLJS.Bd($N)){var aO=$CLJS.kc($N),pba=$CLJS.lc($N),qba=aO,rba=$CLJS.E(aO);
VN=pba;WN=qba;XN=rba}else{var sba=$CLJS.B($N);$CLJS.FJ(sba,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null)]));VN=$CLJS.C($N);WN=null;XN=0}YN=0}else break}$CLJS.X(AN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jt,$CLJS.bN,$CLJS.kN,$CLJS.pN],null));
$CLJS.HJ($CLJS.rN,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AN],null)],null)],null)],null)]));
$CLJS.X($CLJS.wN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,$CLJS.wI,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,2,[$CLJS.zu,"valid timezone ID",$CLJS.Bu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Bj);return["invalid timezone ID: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.nL($CLJS.Jd,$CLJS.XM.tz.names()))],null));
$CLJS.HJ($CLJS.$M,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wN],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Su,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wN],null)],null)],null)],
null)]));$CLJS.yJ($CLJS.$M,$CLJS.gN);$CLJS.FJ($CLJS.nN,$CLJS.G([$CLJS.pu,$CLJS.AG]));$CLJS.X(cN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Ne(function(b){return $CLJS.PF(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vG,$CLJS.dH],null))}],null)],null));
$CLJS.X(ZM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null)],null)],null)],null));
$CLJS.NK.g($CLJS.fN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.zu,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.fN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ct,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.zu,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NM],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PM],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IM],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.gi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uL],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.zu,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.Lx],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RM],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.gi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qL],null)],null)],null)],null)],null)],null));
$CLJS.OI.m(null,$CLJS.fN,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.F.g(b,$CLJS.Lx)?$CLJS.F.g(a,$CLJS.gi)?$CLJS.dH:$CLJS.n($CLJS.xJ($CLJS.uL,a))?$CLJS.vG:$CLJS.dH:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.qh($CLJS.VM,b))?$CLJS.vG:$CLJS.n($CLJS.qh($CLJS.WM,b))?$CLJS.vG:null:null;if($CLJS.n(a))return a;b=$CLJS.PI(b);b=$CLJS.wd(b)?$CLJS.eg.j($CLJS.eh,$CLJS.hf(function(c){return $CLJS.PF(c,$CLJS.Sj)}),b):b;return $CLJS.wd(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.X(sN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.Lx],null),$CLJS.yt],null));
$CLJS.HJ($CLJS.lN,$CLJS.G([$CLJS.pu,$CLJS.dH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bz,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lI],null)],null)],null)],null)]));
$CLJS.FJ($CLJS.pC,$CLJS.G([$CLJS.pu,$CLJS.TH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kI],null)]));$CLJS.X(aN,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.jt,$CLJS.sL,$CLJS.fl,$CLJS.jl,$CLJS.hN,$CLJS.CN,$CLJS.DN,$CLJS.il,$CLJS.gl,$CLJS.el,$CLJS.hl,$CLJS.pL],null));$CLJS.X(oN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jt,$CLJS.bN,$CLJS.kN,$CLJS.pN],null));
$CLJS.HJ($CLJS.xN,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aN],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null)],null)],
null)],null)]));