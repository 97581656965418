var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");
'use strict';var V6=function(a){var b=U6;return $CLJS.cE(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},Kka=function(a){var b=W6;return $CLJS.cE(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},X6=function(a){if($CLJS.od(a))return a;throw $CLJS.li("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.Ii,a],null));},Y6=function(a){var b=(b=a instanceof
$CLJS.M)?$CLJS.he(a):b;return $CLJS.n(b)?[$CLJS.he(a),"/",$CLJS.jh(a)].join(""):$CLJS.jh(a)},Z6=function(a,b){var c=$CLJS.xd(b)&&!$CLJS.yf(b)&&$CLJS.B(b)instanceof $CLJS.M;return c?$CLJS.vd(a)?(b=$CLJS.B(b),a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c},$6=function(a,b){return $CLJS.gf(function(c){var d=$CLJS.jf;a:try{if($CLJS.Ad(c)&&1<=$CLJS.E(c))try{var e=$CLJS.Ok.j(c,0,1);if($CLJS.Ad(e)&&1===$CLJS.E(e))try{var f=$CLJS.cd(e,0);if($CLJS.n($CLJS.Qe($CLJS.F,a)(f))){var h=$CLJS.Ok.g(c,
1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[h],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.B($CLJS.z(d($CLJS.Ua,l)))},$CLJS.G([b]))},Lka=function(a){return function e(c,
d){try{if($CLJS.n(function(){var xr=X6($CLJS.Ed);return xr.h?xr.h(d):xr.call(null,d)}()))return e(c,$CLJS.Cf(d));throw $CLJS.Y;}catch(xr){if(xr instanceof Error){var f=xr;if(f===$CLJS.Y)try{if(null===d)return null;throw $CLJS.Y;}catch(XL){if(XL instanceof Error)if(f=XL,f===$CLJS.Y)try{if($CLJS.Ad(d)&&0<=$CLJS.E(d))try{var h=$CLJS.Ok.j(d,0,0);if($CLJS.Ad(h)&&0===$CLJS.E(h))try{var l=$CLJS.Ok.g(d,0);if($CLJS.n($CLJS.Qe($CLJS.Me,$CLJS.Ra)(l)))return null;throw $CLJS.Y;}catch(pl){if(pl instanceof Error){var m=
pl;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw pl;}else throw $CLJS.Y;}catch(pl){if(pl instanceof Error){m=pl;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw pl;}else throw $CLJS.Y;}catch(pl){if(pl instanceof Error)if(m=pl,m===$CLJS.Y)try{if($CLJS.Ad(d)&&0===$CLJS.E(d))return null;throw $CLJS.Y;}catch(YL){if(YL instanceof Error)if(h=YL,h===$CLJS.Y)try{if($CLJS.Ad(d)&&1===$CLJS.E(d))try{var t=$CLJS.cd(d,0);if($CLJS.ge(t,$CLJS.st))return null;throw $CLJS.Y;}catch(yr){if(yr instanceof Error)if(h=yr,h===
$CLJS.Y)try{t=$CLJS.cd(d,0);if($CLJS.ge(t,$CLJS.mt))return null;throw $CLJS.Y;}catch(df){if(df instanceof Error){var u=df;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw df;}else throw h;else throw yr;}else throw $CLJS.Y;}catch(yr){if(yr instanceof Error)if(h=yr,h===$CLJS.Y)try{if($CLJS.Ad(d)&&1<=$CLJS.E(d))try{var v=$CLJS.Ok.j(d,0,1);if($CLJS.Ad(v)&&1===$CLJS.E(v))try{var x=$CLJS.cd(v,0);if($CLJS.n(function(){var df=new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.mt,null,$CLJS.st,null],null),null);return df.h?
df.h(x):df.call(null,x)}()))try{var A=$CLJS.Ok.g(d,1);if($CLJS.n($CLJS.Qe($CLJS.Ne,$CLJS.Ra)(A))){var D=$CLJS.Ok.g(d,1),H=$CLJS.cd(v,0);return e(c,$CLJS.O.j($CLJS.Ft,H,$CLJS.hZ($CLJS.Ua,D)))}throw $CLJS.Y;}catch(df){if(df instanceof Error){u=df;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw df;}else throw $CLJS.Y;}catch(df){if(df instanceof Error){u=df;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw df;}else throw $CLJS.Y;}catch(df){if(df instanceof Error){u=df;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw df;
}else throw $CLJS.Y;}catch(df){if(df instanceof Error)if(u=df,u===$CLJS.Y)try{if($CLJS.Ad(d)&&2===$CLJS.E(d))try{var K=$CLJS.cd(d,0);if($CLJS.ge(K,$CLJS.ot))try{var U=$CLJS.cd(d,1);if($CLJS.Ad(U)&&1<=$CLJS.E(U))try{var Z=$CLJS.Ok.j(U,0,1);if($CLJS.Ad(Z)&&1===$CLJS.E(Z))try{var ca=$CLJS.cd(Z,0);if($CLJS.ge(ca,$CLJS.st))return D=$CLJS.Ok.g(U,1),e(c,$CLJS.O.j($CLJS.Ft,$CLJS.mt,$CLJS.ff.g(function(Ff){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ot,Ff],null)},D)));throw $CLJS.Y;}catch(Ff){if(Ff instanceof
Error){var ra=Ff;if(ra===$CLJS.Y)try{ca=$CLJS.cd(Z,0);if($CLJS.ge(ca,$CLJS.mt))return D=$CLJS.Ok.g(U,1),e(c,$CLJS.O.j($CLJS.Ft,$CLJS.st,$CLJS.ff.g(function(Fe){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ot,Fe],null)},D)));throw $CLJS.Y;}catch(Fe){if(Fe instanceof Error){var Ca=Fe;if(Ca===$CLJS.Y)throw $CLJS.Y;throw Ca;}throw Fe;}else throw ra;}else throw Ff;}else throw $CLJS.Y;}catch(Ff){if(Ff instanceof Error){ra=Ff;if(ra===$CLJS.Y)throw $CLJS.Y;throw ra;}throw Ff;}else throw $CLJS.Y;}catch(Ff){if(Ff instanceof
Error){ra=Ff;if(ra===$CLJS.Y)throw $CLJS.Y;throw ra;}throw Ff;}else throw $CLJS.Y;}catch(Ff){if(Ff instanceof Error)if(ra=Ff,ra===$CLJS.Y)try{K=$CLJS.cd(d,0);if($CLJS.ge(K,$CLJS.st)){var hb=$CLJS.cd(d,1);return e(c,hb)}throw $CLJS.Y;}catch(Fe){if(Fe instanceof Error)if(Ca=Fe,Ca===$CLJS.Y)try{K=$CLJS.cd(d,0);if($CLJS.ge(K,$CLJS.mt))return hb=$CLJS.cd(d,1),e(c,hb);throw $CLJS.Y;}catch(pe){if(pe instanceof Error){var Ob=pe;if(Ob===$CLJS.Y)throw $CLJS.Y;throw Ob;}throw pe;}else throw Ca;else throw Fe;
}else throw ra;else throw Ff;}else throw $CLJS.Y;}catch(Ff){if(Ff instanceof Error)if(ra=Ff,ra===$CLJS.Y)try{if($CLJS.Ad(d)&&1<=$CLJS.E(d))try{var Tb=$CLJS.Ok.j(d,0,1);if($CLJS.Ad(Tb)&&1===$CLJS.E(Tb))try{var zc=$CLJS.cd(Tb,0);if($CLJS.ge(zc,$CLJS.st))try{var dc=$CLJS.Ok.g(d,1);if($CLJS.n($CLJS.Qe($CLJS.Ne,$CLJS.Qe(Z6,$CLJS.st))(dc)))return D=$CLJS.Ok.g(d,1),e(c,$CLJS.O.j($CLJS.Ft,$CLJS.st,$6($CLJS.st,D)));throw $CLJS.Y;}catch(Fe){if(Fe instanceof Error){Ca=Fe;if(Ca===$CLJS.Y)throw $CLJS.Y;throw Ca;
}throw Fe;}else throw $CLJS.Y;}catch(Fe){if(Fe instanceof Error)if(Ca=Fe,Ca===$CLJS.Y)try{if(zc=$CLJS.cd(Tb,0),$CLJS.ge(zc,$CLJS.mt))try{dc=$CLJS.Ok.g(d,1);if($CLJS.n($CLJS.Qe($CLJS.Ne,$CLJS.Qe(Z6,$CLJS.mt))(dc)))return D=$CLJS.Ok.g(d,1),e(c,$CLJS.O.j($CLJS.Ft,$CLJS.mt,$6($CLJS.mt,D)));throw $CLJS.Y;}catch(pe){if(pe instanceof Error){Ob=pe;if(Ob===$CLJS.Y)throw $CLJS.Y;throw Ob;}throw pe;}else throw $CLJS.Y;}catch(pe){if(pe instanceof Error)if(Ob=pe,Ob===$CLJS.Y)try{if(zc=$CLJS.cd(Tb,0),$CLJS.n(function(){var ef=
new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.mt,null,$CLJS.st,null],null),null);return ef.h?ef.h(zc):ef.call(null,zc)}()))try{dc=$CLJS.Ok.g(d,1);if($CLJS.Ta($CLJS.O.g($CLJS.WF,dc))){D=$CLJS.Ok.g(d,1);var Ac=$CLJS.cd(Tb,0);return e(c,$CLJS.O.j($CLJS.Ft,Ac,$CLJS.al.h(D)))}throw $CLJS.Y;}catch(ef){if(ef instanceof Error){var wb=ef;if(wb===$CLJS.Y)throw $CLJS.Y;throw wb;}throw ef;}else throw $CLJS.Y;}catch(ef){if(ef instanceof Error){wb=ef;if(wb===$CLJS.Y)throw $CLJS.Y;throw wb;}throw ef;}else throw Ob;
else throw pe;}else throw Ca;else throw Fe;}else throw $CLJS.Y;}catch(Fe){if(Fe instanceof Error){Ca=Fe;if(Ca===$CLJS.Y)throw $CLJS.Y;throw Ca;}throw Fe;}else throw $CLJS.Y;}catch(Fe){if(Fe instanceof Error)if(Ca=Fe,Ca===$CLJS.Y)try{if($CLJS.Ad(d)&&2===$CLJS.E(d))try{var Ib=$CLJS.cd(d,0);if($CLJS.ge(Ib,$CLJS.ot))try{var dd=$CLJS.cd(d,1);if($CLJS.Ad(dd)&&2===$CLJS.E(dd))try{var ri=$CLJS.cd(dd,0);if($CLJS.ge(ri,$CLJS.ot))return hb=$CLJS.cd(dd,1),e(c,hb);throw $CLJS.Y;}catch(pe){if(pe instanceof Error){Ob=
pe;if(Ob===$CLJS.Y)throw $CLJS.Y;throw Ob;}throw pe;}else throw $CLJS.Y;}catch(pe){if(pe instanceof Error){Ob=pe;if(Ob===$CLJS.Y)throw $CLJS.Y;throw Ob;}throw pe;}else throw $CLJS.Y;}catch(pe){if(pe instanceof Error){Ob=pe;if(Ob===$CLJS.Y)throw $CLJS.Y;throw Ob;}throw pe;}else throw $CLJS.Y;}catch(pe){if(pe instanceof Error)if(Ob=pe,Ob===$CLJS.Y)try{if($CLJS.Ad(d)&&1<=$CLJS.E(d))try{var rf=$CLJS.Ok.j(d,0,1);if($CLJS.Ad(rf)&&1===$CLJS.E(rf))try{var Se=$CLJS.cd(rf,0);if($CLJS.ge(Se,$CLJS.Uu))return a;
throw $CLJS.Y;}catch(ef){if(ef instanceof Error){wb=ef;if(wb===$CLJS.Y)throw $CLJS.Y;throw wb;}throw ef;}else throw $CLJS.Y;}catch(ef){if(ef instanceof Error){wb=ef;if(wb===$CLJS.Y)throw $CLJS.Y;throw wb;}throw ef;}else throw $CLJS.Y;}catch(ef){if(ef instanceof Error){wb=ef;if(wb===$CLJS.Y)return $CLJS.rZ(e,c,d);throw wb;}throw ef;}else throw Ob;else throw pe;}else throw Ca;else throw Fe;}else throw ra;else throw Ff;}else throw u;else throw df;}else throw h;else throw yr;}else throw h;else throw YL;
}else throw m;else throw pl;}else throw f;else throw XL;}else throw f;}else throw xr;}}($CLJS.Bf,a)},a7=function(a){var b=$CLJS.xd(a);return b?(b=!$CLJS.yf(a))?$CLJS.WD($CLJS.fe,$CLJS.Wa)($CLJS.B(a)):b:b},U6=function(a){return $CLJS.n($CLJS.WD($CLJS.fe,$CLJS.Wa)(a))?$CLJS.Ch.h($CLJS.aE(Y6(a).toLowerCase(),/_/,"-")):a},b7=function(a,b){var c=a7(b);return $CLJS.n(c)?(b=U6($CLJS.B(b)),$CLJS.vd(a)?(a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,b)):c},Mka=function(a){return $CLJS.J.j(new $CLJS.k(null,
3,[$CLJS.gi,$CLJS.Td,$CLJS.kj,U6,$CLJS.AR,U6],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=c7.g?c7.g(b,d7):c7.call(null,b,d7);return d.call(c,b,a)})},Nka=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.ff.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=U6(c);var d=Mka(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.F.g($CLJS.kj.h(a),$CLJS.RP)&&$CLJS.Ta($CLJS.AR.h(a))?$CLJS.R.j(a,$CLJS.AR,$CLJS.TU):a},Oka=function(a){return $CLJS.eg.j($CLJS.N,
$CLJS.ff.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=Y6(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(Nka(b),$CLJS.S,c)],null)}),a)},e7=function(a){return $CLJS.tZ.j($CLJS.tZ.j($CLJS.bb(function(b,c){return $CLJS.tZ.j(b,c,$CLJS.Ch)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.RG,$CLJS.iH,$CLJS.pS,Pka,$CLJS.Su,$CLJS.xi],null)),$CLJS.BF,$CLJS.Hk.g(W6,c7)),$CLJS.CR,$CLJS.lZ)},f7=function(a){return $CLJS.Gd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,a,null],null):a},h7=function(a){return g7.h(f7(a))},
i7=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return Lka($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.ff.g(g7,b)))},j7=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,h7(b)],null),$CLJS.ff.g(g7,c))},k7=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,h7(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)},l7=function(a){var b=$CLJS.I(a,
0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,h7(a)],null)},m7=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,g7.h(a)],null)},W6=function(a){return n7(function(b){if($CLJS.yd(b))return Kka(b);if($CLJS.Ta(a7(b)))return b;try{return g7.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.iE($CLJS.kC);if($CLJS.n($CLJS.hE("metabase.mbql.normalize",d))){var e=$CLJS.MI("Invalid clause:");e instanceof Error?$CLJS.gE("metabase.mbql.normalize",
d,$CLJS.vy.l($CLJS.G([b])),e):$CLJS.gE("metabase.mbql.normalize",d,$CLJS.vy.l($CLJS.G([e,b])),null)}throw $CLJS.ki($CLJS.LI("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.iZ(c)])),new $CLJS.k(null,1,[$CLJS.NV,b],null),c);}throw f;}},a)},Qka=function(a){return $CLJS.Cf($CLJS.$l(W6,o7(a)))},Rka=function(a){for(;;)if($CLJS.n(a7(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.He($CLJS.Jk.g(f7,a))},p7=function(a){a=$CLJS.Ge(a);var b=$CLJS.J.g(a,$CLJS.RP);return $CLJS.n(b)?$CLJS.Kk.j(a,$CLJS.RP,
g7):a},Ska=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.me(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Bd(e)){var f=$CLJS.kc(e),h=$CLJS.E(f),l=$CLJS.qe(h);a:for(var m=0;;)if(m<h){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,p7(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.lc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.B(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.de(new $CLJS.P(null,
2,5,$CLJS.Q,[l,p7(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},q7=function(a){a=$CLJS.Ge(a);var b=$CLJS.J.g(a,$CLJS.sU);return $CLJS.n(b)?$CLJS.Kk.j(a,$CLJS.sU,Ska):a},Tka=function(a){var b=$CLJS.Ge(a);a=$CLJS.J.g(b,$CLJS.WT);b=$CLJS.Ta(a)?r7.h?r7.h(b):r7.call(null,b):b;return $CLJS.n(a)?q7(b):b},s7=function(a){return $CLJS.vd(a)?$CLJS.z(a):null!=a},Uka=function(a){var b=$CLJS.Ge(a);a=$CLJS.J.g(b,$CLJS.qQ);var c=$CLJS.J.g(b,$CLJS.dR),d=$CLJS.J.g(b,$CLJS.ES),e=$CLJS.J.g(b,$CLJS.WT);
try{if($CLJS.n(d)){var f=$CLJS.Ge(b),h=$CLJS.J.g(f,$CLJS.ES);var l=$CLJS.CP($CLJS.Ak.g(f,$CLJS.ES),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qQ,$CLJS.ES],null),h)}else l=b;var m=$CLJS.n(a)?$CLJS.Kk.j(l,$CLJS.qQ,r7):l,t=$CLJS.n(c)?$CLJS.Kk.j(m,$CLJS.dR,$CLJS.Qe($CLJS.Jk,W6)):m,u=$CLJS.n(e)?$CLJS.Kk.j(t,$CLJS.WT,q7):t;return W6(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.ki($CLJS.LI("Error canonicalizing query: {0}",$CLJS.G([$CLJS.iZ(m)])),new $CLJS.k(null,1,[$CLJS.qQ,a],null),m);throw v;}},Vka=function(a){a=
$CLJS.Ge(a);var b=$CLJS.J.g(a,$CLJS.qQ),c=$CLJS.Ge(b);b=$CLJS.J.g(c,$CLJS.tS);c=$CLJS.J.g(c,$CLJS.XI);return $CLJS.z(b)&&$CLJS.z(c)?(b=$CLJS.eg.j($CLJS.eh,$CLJS.fv,$CLJS.z($CLJS.jf($CLJS.Ua,function h(e,f){try{if($CLJS.Ad(f)&&3===$CLJS.E(f))try{var l=$CLJS.cd(f,0);if($CLJS.ge(l,$CLJS.mK)){var m=$CLJS.cd(f,1),t=$CLJS.cd(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,m,$CLJS.Ak.g(t,$CLJS.sK)],null)],null)],null)}throw $CLJS.Y;}catch(u){if(u instanceof
Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.qZ(h,e,f);throw l;}throw u;}}($CLJS.Bf,b)))),$CLJS.sZ.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qQ,$CLJS.XI],null),$CLJS.Hk.g($CLJS.Cf,$CLJS.Qe($CLJS.Ik,b)))):a},Wka=function(a){try{return Vka(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.ki($CLJS.MI("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.qQ,a],null),b);}throw c;}},
Xka=function(a,b){var c=$CLJS.eg.g($CLJS.ld(a),function(){return function f(e){return new $CLJS.me(null,function(){for(var h=e;;)if(h=$CLJS.z(h)){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.md(l,A),H=$CLJS.I(D,0,null);D=$CLJS.I(D,1,null);var K=$CLJS.ae.g(b,H);D=t7.g?t7.g(D,K):t7.call(null,D,K);null!=D&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[H,D],null));A+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.B(h),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,D=$CLJS.ae.g(b,v);return t7.g?t7.g(A,D):t7.call(null,A,D)}();if(null!=u)return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(h)));h=$CLJS.Lc(h)}else return null},null,null)}(a)}());return $CLJS.z(c)?c:null},Yka=function(a,b){a=$CLJS.Jk.g(function(c){var d=$CLJS.ae.g(b,u7);return t7.g?t7.g(c,d):t7.call(null,c,d)},a);return $CLJS.n($CLJS.Ne($CLJS.Ua,a))?a:null},n7=function n7(a,b){return $CLJS.jZ($CLJS.Qe(n7,
a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},Zka=new $CLJS.M(null,"value_field","value_field",-980977878),v7=new $CLJS.M(null,"ascending","ascending",-988350486),w7=new $CLJS.M(null,"named","named",-422393479),x7=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),y7=new $CLJS.M(null,"descending","descending",-24766135),z7=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158),A7=new $CLJS.M(null,"datetime-field","datetime-field",21731696),$ka=new $CLJS.M(null,"use-as-display-name?",
"use-as-display-name?",686752941),B7=new $CLJS.M(null,"field-literal","field-literal",-1295883554),C7=new $CLJS.M(null,"viz-settings","viz-settings",256055379),ala=new $CLJS.M(null,"rows","rows",850049680),bla=new $CLJS.M(null,"special-fn","special-fn",1290649344),u7=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),d7=new $CLJS.M(null,"ignore-path","ignore-path",944069061),cla=new $CLJS.M(null,"label_field","label_field",-1573182765),dla=new $CLJS.M(null,
"fk-\x3e","fk-\x3e",-499026738),ela=new $CLJS.M(null,"joined-field","joined-field",-2048778268),Pka=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var D7,fla=$CLJS.Te($CLJS.N),gla=$CLJS.Te($CLJS.N),hla=$CLJS.Te($CLJS.N),ila=$CLJS.Te($CLJS.N),jla=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));D7=new $CLJS.hi($CLJS.Ih.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Hk.g(U6,$CLJS.B),jla,fla,gla,hla,ila);D7.m(null,$CLJS.bK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,b],null);return null!=a?$CLJS.ae.g(b,a):b});
D7.m(null,$CLJS.zE,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,a instanceof $CLJS.M?Y6(a):a],null)});D7.m(null,z7,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.ae.g(D7.h(new $CLJS.P(null,3,5,$CLJS.Q,[z7,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[z7,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(c)],null)});
D7.m(null,$CLJS.mK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=c7.g?c7.g(a,d7):c7.call(null,a,d7);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,b,function(){var d=$CLJS.n($CLJS.CE.h(c))?$CLJS.Kk.j(c,$CLJS.CE,$CLJS.Ch):c;d=$CLJS.n($CLJS.sK.h(c))?$CLJS.Kk.j(d,$CLJS.sK,$CLJS.Ch):d;return $CLJS.n($CLJS.jV.h(c))?$CLJS.Kk.j(d,$CLJS.jV,function(e){return $CLJS.n($CLJS.HI.h(e))?$CLJS.Kk.j(e,$CLJS.HI,$CLJS.Ch):e}):d}()],null)});
D7.m(null,B7,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[B7,b instanceof $CLJS.M?Y6(b):b,$CLJS.Ch.h(a)],null)});D7.m(null,A7,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[A7,c7.g?c7.g(b,d7):c7.call(null,b,d7),$CLJS.nu,U6(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[A7,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(c)],null)});
D7.m(null,$CLJS.yK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.ae.g(D7.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.yK,b,c,d],null)),c7.g?c7.g(a,d7):c7.call(null,a,d7)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.yK,c7.g?c7.g(b,d7):c7.call(null,b,d7),$CLJS.Gd(c)?c:U6(c),U6(d)],null)});
D7.m(null,$CLJS.lN,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lN,b,U6(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lN,$CLJS.Lx],null)});D7.m(null,$CLJS.jN,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jN,b,U6(a)],null)});
D7.m(null,$CLJS.tN,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tN,c7.g?c7.g(b,d7):c7.call(null,b,d7),c,U6(a)],null)});D7.m(null,$CLJS.zN,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zN,c7.g?c7.g(b,d7):c7.call(null,b,d7),c,U6(a)],null)});
D7.m(null,$CLJS.rN,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rN,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rN,c7.g?c7.g(b,d7):c7.call(null,b,d7)],null)});
D7.m(null,$CLJS.xN,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xN,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(c),U6(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xN,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(c)],null)});
D7.m(null,$CLJS.iN,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.iN,c7.g?c7.g(b,d7):c7.call(null,b,d7),c7.g?c7.g(c,d7):c7.call(null,c,d7),U6(a)],null)});D7.m(null,$CLJS.Bj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,b,a],null)});
D7.m(null,$CLJS.gi,function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[U6(a)],null),$CLJS.ff.h(function(c){return c7.g?c7.g(c,d7):c7.call(null,c,d7)}),b)});
var E7=function E7(a){if($CLJS.n($CLJS.WD($CLJS.fe,$CLJS.Wa)(a))){var c=U6(a);var d=new $CLJS.dh(null,new $CLJS.k(null,18,[$CLJS.Un,null,$CLJS.mk,null,$CLJS.DK,null,$CLJS.vK,null,$CLJS.pu,null,$CLJS.nK,null,$CLJS.HK,null,$CLJS.IK,null,$CLJS.TJ,null,$CLJS.ZJ,null,$CLJS.Us,null,$CLJS.UJ,null,$CLJS.AK,null,$CLJS.tK,null,$CLJS.ek,null,$CLJS.TA,null,$CLJS.gK,null,$CLJS.zK,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(a7(a))?(a=$CLJS.B(a),E7.h?E7.h(a):E7.call(null,
a)):null},kla=new $CLJS.k(null,8,[$CLJS.kj,U6,$CLJS.WT,function(a){a=V6(a);return $CLJS.z($CLJS.sU.h(a))?$CLJS.Kk.j(a,$CLJS.sU,Oka):a},$CLJS.qQ,new $CLJS.k(null,6,[$CLJS.bK,function F7(a){if($CLJS.n($CLJS.WD($CLJS.fe,$CLJS.Wa)(a)))return U6(a);if($CLJS.n(b7(w7,a))){a=$CLJS.z(a);$CLJS.B(a);var c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[w7,F7.h?F7.h(a):F7.call(null,a)],null),c)}return $CLJS.n($CLJS.n(a7(a))?E7($CLJS.jd(a)):null)?$CLJS.Jk.g(F7,a):c7.g?c7.g(a,
d7):c7.call(null,a,d7)},$CLJS.MT,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.me(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Bd(e)){var f=$CLJS.kc(e),h=$CLJS.E(f),l=$CLJS.qe(h);a:for(var m=0;;)if(m<h){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[Y6(u),c7.g?c7.g(t,d7):c7.call(null,t,d7)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.lc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.B(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[Y6(l),c7.g?c7.g(f,d7):c7.call(null,f,d7)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.CV,function(a){return $CLJS.Cf(function(){return function d(c){return new $CLJS.me(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Bd(e)){var f=$CLJS.kc(e),h=$CLJS.E(f),l=$CLJS.qe(h);a:for(var m=0;;)if(m<h){var t=$CLJS.md(f,m);t=$CLJS.n(a7(t))?D7.h(t):$CLJS.be(D7.h($CLJS.be(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.lc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.B(e);return $CLJS.de($CLJS.n(a7(l))?D7.h(l):$CLJS.be(D7.h($CLJS.be(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.vU,function(a){a=V6(a);a=$CLJS.Ge(a);var b=$CLJS.J.g(a,$CLJS.WT);if($CLJS.n(b))return a=$CLJS.pZ(a,new $CLJS.k(null,1,[$CLJS.WT,$CLJS.qQ],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WT],null),a=c7.g?c7.g(a,b):c7.call(null,a,b),$CLJS.pZ(a,new $CLJS.k(null,1,[$CLJS.qQ,$CLJS.WT],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qQ],null);return c7.g?c7.g(a,b):c7.call(null,a,b)},$CLJS.ES,new $CLJS.k(null,1,[u7,e7],null),$CLJS.nR,new $CLJS.k(null,1,[u7,function(a){a=c7.g?c7.g(a,$CLJS.qQ):c7.call(null,a,$CLJS.qQ);var b=$CLJS.Ge(a),c=$CLJS.J.g(b,$CLJS.HI),d=$CLJS.J.g(b,$CLJS.XI);a=$CLJS.J.g(b,$CLJS.aJ);b=$CLJS.n(c)?$CLJS.Kk.j(b,$CLJS.HI,U6):b;d=$CLJS.n($CLJS.WD($CLJS.fe,$CLJS.Wa)(d))?$CLJS.Kk.j(b,$CLJS.XI,U6):b;return $CLJS.n(a)?$CLJS.Kk.j(d,$CLJS.aJ,Y6):d}],null)],null),$CLJS.qC,
new $CLJS.k(null,1,[$CLJS.aS,$CLJS.Td],null),$CLJS.dR,new $CLJS.k(null,1,[u7,function(a){var b=$CLJS.Ge(a),c=$CLJS.J.g(b,$CLJS.kj),d=$CLJS.J.g(b,$CLJS.EN),e=$CLJS.J.g(b,$CLJS.Yi);a=$CLJS.J.g(b,x7);b=$CLJS.n(e)?$CLJS.Kk.j(b,$CLJS.Yi,Y6):b;c=$CLJS.n(c)?$CLJS.Kk.j(b,$CLJS.kj,U6):b;d=$CLJS.n(d)?$CLJS.Kk.j(c,$CLJS.EN,function(f){return c7.g?c7.g(f,d7):c7.call(null,f,d7)}):c;d=$CLJS.n(a)?$CLJS.sZ.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[x7,cla],null),function(f){return c7.g?c7.g(f,d7):c7.call(null,f,d7)}):d;return $CLJS.n(a)?
$CLJS.sZ.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[x7,Zka],null),function(f){return c7.g?c7.g(f,d7):c7.call(null,f,d7)}):d}],null),$CLJS.ZQ,function(a){return null==a?null:U6(a)},$CLJS.ES,new $CLJS.k(null,1,[u7,e7],null),C7,U6],null),c7=function c7(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return c7.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
c7.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Cf(b);b=$CLJS.z(c)?$CLJS.XD(kla,c):null;try{return $CLJS.od(b)?b.h?b.h(a):b.call(null,a):$CLJS.zd(a)?a:$CLJS.yd(a)?$CLJS.eg.g($CLJS.N,function(){return function h(f){return new $CLJS.me(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Bd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=U6(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,c7.l(x,$CLJS.G([$CLJS.ae.g($CLJS.Cf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),h($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.B(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=U6(u);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[u,c7.l(m,$CLJS.G([$CLJS.ae.g($CLJS.Cf(c),u)]))],null),h($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(a7(a))?D7.h(a):$CLJS.xd(a)?$CLJS.Jk.g(function(e){return c7.l(e,$CLJS.G([$CLJS.ae.g($CLJS.Cf(c),u7)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.ki($CLJS.LI("Error normalizing form: {0}",$CLJS.G([$CLJS.iZ(d)])),new $CLJS.k(null,3,[$CLJS.Ru,a,$CLJS.ml,c,bla,b],null),d);}throw e;}};c7.A=1;c7.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var g7=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(a7(f))?$CLJS.B(f):null},e,a,b,c,d)}();g7.m(null,$CLJS.gi,function(a){return a});
g7.m(null,$CLJS.mK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b7($CLJS.mK,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return g7.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,c,$CLJS.He($CLJS.Zk.l($CLJS.G([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,b,$CLJS.He(a)],null)});
g7.m(null,$CLJS.bK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.ud(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,b,a],null)});g7.m(null,$CLJS.vZ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a7(a))?g7.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,a,null],null)});
g7.m(null,B7,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,b,new $CLJS.k(null,1,[$CLJS.CE,a],null)],null)});g7.m(null,dla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=h7(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=h7(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,a,$CLJS.R.j(c,$CLJS.oR,b)],null)});
g7.m(null,ela,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=h7(a);return $CLJS.O.v($CLJS.AZ,a,$CLJS.R,$CLJS.G([$CLJS.gT,b]))});
g7.m(null,A7,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=h7(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.Ge(c);var d=$CLJS.J.g(c,$CLJS.CE);$CLJS.Ta(d)||$CLJS.yW.g(d,b)?a=$CLJS.O.v($CLJS.AZ,a,$CLJS.R,$CLJS.G([$CLJS.sK,b])):(c=$CLJS.iE($CLJS.oC),$CLJS.n($CLJS.hE("metabase.mbql.util",c))&&(b=$CLJS.LI("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.G([b,d,$CLJS.Ph.l($CLJS.G([a]))])),
b instanceof Error?$CLJS.gE("metabase.mbql.util",c,$CLJS.vy(),b):$CLJS.gE("metabase.mbql.util",c,$CLJS.vy.l($CLJS.G([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),g7.h(new $CLJS.P(null,3,5,$CLJS.Q,[A7,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
g7.m(null,z7,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=h7(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,b,$CLJS.R.j(e,$CLJS.jV,$CLJS.Zk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.HI,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var G7=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,$CLJS.mt,$CLJS.ot],null)),H7=null,I7=0,J7=0;;)if(J7<I7){var K7=H7.X(null,J7);g7.m(null,K7,function(){return function(a){return i7(a)}}(G7,H7,I7,J7,K7));J7+=1}else{var L7=$CLJS.z(G7);if(L7){var M7=L7;if($CLJS.Bd(M7)){var N7=$CLJS.kc(M7),lla=$CLJS.lc(M7),mla=N7,nla=$CLJS.E(N7);G7=lla;H7=mla;I7=nla}else{var O7=$CLJS.B(M7);g7.m(null,O7,function(){return function(a){return i7(a)}}(G7,H7,I7,J7,O7,M7,L7));G7=$CLJS.C(M7);H7=null;I7=0}J7=0}else break}
g7.m(null,$CLJS.WJ,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WJ,h7(a),h7(b)],null),c)});g7.m(null,$CLJS.yK,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=h7(a);a=$CLJS.n(Z6($CLJS.mK,a))?$CLJS.AZ.l(c,$CLJS.Ak,$CLJS.G([$CLJS.sK])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yK,a],null),b)});
for(var P7=$CLJS.z(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.dK,$CLJS.xK,$CLJS.lK,$CLJS.eK,$CLJS.Rs,$CLJS.FK,$CLJS.Os,$CLJS.Qs,$CLJS.Ks,$CLJS.Ms,$CLJS.SJ,$CLJS.YJ,$CLJS.QJ,$CLJS.$J,$CLJS.RJ],null)),Q7=null,R7=0,S7=0;;)if(S7<R7){var T7=Q7.X(null,S7);g7.m(null,T7,function(){return function(a){return j7(a)}}(P7,Q7,R7,S7,T7));S7+=1}else{var U7=$CLJS.z(P7);if(U7){var V7=U7;if($CLJS.Bd(V7)){var W7=$CLJS.kc(V7),ola=$CLJS.lc(V7),pla=W7,qla=$CLJS.E(W7);P7=ola;Q7=pla;R7=qla}else{var X7=$CLJS.B(V7);g7.m(null,X7,
function(){return function(a){return j7(a)}}(P7,Q7,R7,S7,X7,V7,U7));P7=$CLJS.C(V7);Q7=null;R7=0}S7=0}else break}g7.m(null,ala,function(){return null});g7.m(null,$CLJS.cQ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cQ,g7.h(b),a],null)});
g7.m(null,w7,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=g7.h;var e=$CLJS.Q;b=g7.h(b);d=$CLJS.I(d,0,null);d=$CLJS.Ge(d);a=!1===$CLJS.J.g(d,$ka)?new $CLJS.k(null,1,[$CLJS.S,a],null):new $CLJS.k(null,1,[$CLJS.rI,a],null);return c.call(g7,new $CLJS.P(null,3,5,e,[$CLJS.cQ,b,a],null))});
for(var Y7=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.nK],null)),Z7=null,$7=0,a8=0;;)if(a8<$7){var b8=Z7.X(null,a8);g7.m(null,b8,function(){return function(a){return k7(a)}}(Y7,Z7,$7,a8,b8));a8+=1}else{var c8=$CLJS.z(Y7);if(c8){var d8=c8;if($CLJS.Bd(d8)){var e8=$CLJS.kc(d8),rla=$CLJS.lc(d8),sla=e8,tla=$CLJS.E(e8);Y7=rla;Z7=sla;$7=tla}else{var f8=$CLJS.B(d8);g7.m(null,f8,function(){return function(a){return k7(a)}}(Y7,Z7,$7,a8,f8,d8,c8));Y7=$CLJS.C(d8);Z7=null;$7=0}a8=0}else break}
for(var g8=$CLJS.z(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.gK,$CLJS.JK,$CLJS.TJ,$CLJS.DK,$CLJS.zK,$CLJS.Un,$CLJS.ek,$CLJS.AK,$CLJS.UJ],null)),h8=null,i8=0,j8=0;;)if(j8<i8){var k8=h8.X(null,j8);g7.m(null,k8,function(){return function(a){return l7(a)}}(g8,h8,i8,j8,k8));j8+=1}else{var l8=$CLJS.z(g8);if(l8){var m8=l8;if($CLJS.Bd(m8)){var n8=$CLJS.kc(m8),ula=$CLJS.lc(m8),vla=n8,wla=$CLJS.E(n8);g8=ula;h8=vla;i8=wla}else{var o8=$CLJS.B(m8);g7.m(null,o8,function(){return function(a){return l7(a)}}(g8,h8,i8,j8,
o8,m8,l8));g8=$CLJS.C(m8);h8=null;i8=0}j8=0}else break}g7.m(null,$CLJS.ZJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,h7(b),a],null)});
for(var p8=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tK,$CLJS.vK],null)),q8=null,r8=0,s8=0;;)if(s8<r8){var t8=q8.X(null,s8);g7.m(null,t8,function(){return function(a){return m7(a)}}(p8,q8,r8,s8,t8));s8+=1}else{var u8=$CLJS.z(p8);if(u8){var v8=u8;if($CLJS.Bd(v8)){var w8=$CLJS.kc(v8),xla=$CLJS.lc(v8),yla=w8,zla=$CLJS.E(w8);p8=xla;q8=yla;r8=zla}else{var x8=$CLJS.B(v8);g7.m(null,x8,function(){return function(a){return m7(a)}}(p8,q8,r8,s8,x8,v8,u8));p8=$CLJS.C(v8);q8=null;r8=0}s8=0}else break}
g7.m(null,$CLJS.HK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HK,g7.h(b),g7.h(a)],null)});
g7.m(null,$CLJS.XJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.ae.g(g7.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,b],null)),c7.l(a,$CLJS.G([d7]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,$CLJS.Cf(function(){return function e(d){return new $CLJS.me(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.Bd(f)){var h=$CLJS.kc(f),l=$CLJS.E(h),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(h,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[g7.h(v),g7.h(u)],null);m.add(v);t+=1}else{h=!0;break a}return h?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}h=$CLJS.B(f);m=$CLJS.I(h,0,null);h=$CLJS.I(h,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[g7.h(m),g7.h(h)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
g7.m(null,$CLJS.JJ,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JJ,g7.h(a),$CLJS.F.g(0,b)?1:g7.h(b)],null),$CLJS.ff.g(g7,c))});
var o7=function o7(a){return function f(d,e){try{if($CLJS.n(function(){var x=X6($CLJS.Ed);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Cf(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var A=X6($CLJS.fe);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Y)try{if($CLJS.Ad(e)&&2<=$CLJS.E(e))try{var h=$CLJS.Ok.j(e,
0,2);if($CLJS.Ad(h)&&2===$CLJS.E(h))try{var l=$CLJS.cd(h,0);if($CLJS.n($CLJS.vP($CLJS.fe,$CLJS.Oe(new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.mk,null,$CLJS.pu,null,$CLJS.IK,null,$CLJS.Us,null,w7,null],null),null)))(l)))try{var m=$CLJS.cd(h,1);if($CLJS.n(E7(m)))return $CLJS.eg.j($CLJS.Bf,$CLJS.gv(o7),a);throw $CLJS.Y;}catch(D){if(D instanceof Error){var t=D;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw D;
}else throw $CLJS.Y;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error)if(t=D,t===$CLJS.Y)try{if($CLJS.Ad(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Ok.j(e,0,1);if($CLJS.Ad(u)&&1===$CLJS.E(u))try{if($CLJS.cd(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw H;}else throw $CLJS.Y;}catch(H){if(H instanceof Error){v=
H;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw H;}else throw $CLJS.Y;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Y)return e;throw v;}throw H;}else throw t;else throw D;}else throw d;else throw A;}else throw d;else throw x;}}($CLJS.Bf,a)},y8=function y8(a){return function f(d,e){try{var h=X6($CLJS.Ed);var l=h.h?h.h(e):h.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Cf(e));throw $CLJS.Y;}catch(D){if(D instanceof Error)if(l=D,l===$CLJS.Y)try{if($CLJS.Ad(e)&&2===$CLJS.E(e))try{var m=$CLJS.cd(e,1);
if($CLJS.ge(m,$CLJS.jK)){var t=$CLJS.cd(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jK,t],null))}throw $CLJS.Y;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Y)try{m=$CLJS.cd(e,1);if($CLJS.ge(m,$CLJS.cK))return t=$CLJS.cd(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cK,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.cd(e,1);if($CLJS.ge(m,v7))return t=$CLJS.cd(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jK,t],null));throw $CLJS.Y;}catch(U){if(U instanceof
Error)if(l=U,l===$CLJS.Y)try{m=$CLJS.cd(e,1);if($CLJS.ge(m,y7))return t=$CLJS.cd(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cK,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{var v=$CLJS.cd(e,0);if($CLJS.ge(v,v7))return t=$CLJS.cd(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jK,t],null));throw $CLJS.Y;}catch(ca){if(ca instanceof Error)if(m=ca,m===$CLJS.Y)try{v=$CLJS.cd(e,0);if($CLJS.ge(v,y7))return t=$CLJS.cd(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cK,t],null));
throw $CLJS.Y;}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.cd(e,0);if($CLJS.ge(v,$CLJS.jK))return t=$CLJS.cd(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jK,h7(t)],null);throw $CLJS.Y;}catch(Ca){if(Ca instanceof Error)if(m=Ca,m===$CLJS.Y)try{v=$CLJS.cd(e,0);if($CLJS.ge(v,$CLJS.cK))return t=$CLJS.cd(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cK,h7(t)],null);throw $CLJS.Y;}catch(hb){if(hb instanceof Error){t=hb;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw hb;}else throw m;else throw Ca;
}else throw m;else throw ra;}else throw m;else throw ca;}else throw m;else throw Z;}else throw l;else throw U;}else throw u;}else throw K;}else throw l;else throw H;}else throw $CLJS.Y;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Y)try{if($CLJS.Ad(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Ok.j(e,0,0);if($CLJS.Ad(x)&&0===$CLJS.E(x))try{var A=$CLJS.Ok.g(e,0);if($CLJS.F.g(A,a))return $CLJS.Cf($CLJS.al.h($CLJS.ff.g(y8,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;
}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.rZ(f,d,e);throw u;}throw K;}else throw l;else throw H;}else throw l;else throw D;}}($CLJS.Bf,a)},r7=$CLJS.Hk.g(W6,function(a){var b=s7($CLJS.bK.h(a))?$CLJS.Kk.j(a,$CLJS.bK,Qka):a;b=s7($CLJS.tS.h(a))?$CLJS.Kk.j(b,$CLJS.tS,Rka):b;b=s7($CLJS.XI.h(a))?$CLJS.Kk.j(b,$CLJS.XI,$CLJS.Qe($CLJS.Jk,f7)):b;b=s7($CLJS.CV.h(a))?
$CLJS.Kk.j(b,$CLJS.CV,y8):b;return s7($CLJS.vU.h(a))?$CLJS.Kk.j(b,$CLJS.vU,Tka):b}),Ala=new $CLJS.k(null,3,[$CLJS.WT,$CLJS.Td,$CLJS.qQ,new $CLJS.k(null,2,[$CLJS.vU,function(a){a=$CLJS.Ge(a);var b=$CLJS.J.g(a,$CLJS.WT);if($CLJS.n(b))return a=$CLJS.pZ(a,new $CLJS.k(null,1,[$CLJS.WT,$CLJS.qQ],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WT],null),a=t7.g?t7.g(a,b):t7.call(null,a,b),$CLJS.pZ(a,new $CLJS.k(null,1,[$CLJS.qQ,$CLJS.WT],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qQ],null);return t7.g?t7.g(a,
b):t7.call(null,a,b)},$CLJS.nR,new $CLJS.k(null,1,[u7,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qQ],null);return t7.g?t7.g(a,b):t7.call(null,a,b)}],null)],null),C7,$CLJS.Td],null),t7=function t7(a){switch(arguments.length){case 1:return t7.h(arguments[0]);case 2:return t7.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};t7.h=function(a){return t7.g(a,$CLJS.Bf)};
t7.g=function(a,b){try{var c=$CLJS.z(b)?$CLJS.XD(Ala,b):null;return $CLJS.od(c)?c.h?c.h(a):c.call(null,a):$CLJS.zd(a)?a:$CLJS.yd(a)?Xka(a,b):$CLJS.xd(a)?Yka(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.ki("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.Ru,a,$CLJS.ml,b],null),d);throw d;}};t7.A=2;
$CLJS.z8=function(){var a=$CLJS.Hk.l(t7,Wka,Uka,$CLJS.G([c7]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.ki($CLJS.LI("Error normalizing query: {0}",$CLJS.G([$CLJS.iZ(c)])),new $CLJS.k(null,1,[$CLJS.qQ,b],null),c);}throw d;}}}();$CLJS.A8=function A8(a,b){if($CLJS.Ta($CLJS.z(a)))a=(0,$CLJS.z8)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Iu(a);b=$CLJS.Ee([$CLJS.kd(a),b]);f=A8.g?A8.g(f,b):A8.call(null,f,b);a=e.call(d,f,$CLJS.kd(a))}return a};