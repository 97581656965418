var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var q9,s9,r9,yoa,x9,y9,zoa,Aoa,Boa,Coa,t9,Doa,D9,Eoa,Foa,Goa;q9=function(a,b){return $CLJS.n(a)?$CLJS.Da(a,b):null};s9=function(a,b){return r9(a,b,$CLJS.N)};
r9=function(a,b,c){c=$CLJS.Ge(c);c=$CLJS.J.j(c,t9,!0);if($CLJS.yd(b))return $CLJS.eg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=q9(b,"_plainObject");d=null==d?null:$CLJS.ED(d);return null==d?null:$CLJS.He(d)}():null;return $CLJS.n(c)?$CLJS.eg.j($CLJS.N,a,c):$CLJS.eg.j($CLJS.N,$CLJS.Hk.j($CLJS.ff.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,q9(b,d)],null)}),$CLJS.Ik.h(function(d){$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.F.g($CLJS.wa(d),"function")}),a),$CLJS.Ba(b))};
yoa=function(a){var b=u9.h(a),c=v9.h(a);return $CLJS.Hk.j($CLJS.ff.h(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ch.h((0,$CLJS.nE)(e)),d],null)}),$CLJS.ud(b)?$CLJS.Td:$CLJS.Ik.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return $CLJS.Id(b,e)}),$CLJS.Ta(c)?$CLJS.Td:$CLJS.ff.h(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[e,c.g?c.g(e,d):c.call(null,e,d)],null)}))};
x9=function(a,b){var c=yoa(a),d=w9.h(a);return function(e){try{var f=$CLJS.R.j(r9(c,e,b),$CLJS.Ev,d),h=$CLJS.iE($CLJS.iC);$CLJS.n($CLJS.hE("metabase.lib.js.metadata",h))&&$CLJS.gE("metabase.lib.js.metadata",h,$CLJS.m_("Parsed metadata %s %s\n%s",$CLJS.G([a,$CLJS.Yi.h(f),$CLJS.n_(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,h=$CLJS.iE($CLJS.kC),$CLJS.n($CLJS.hE("metabase.lib.js.metadata",h))&&(f instanceof Error?$CLJS.gE("metabase.lib.js.metadata",h,$CLJS.m_("Error parsing %s %s: %s",
$CLJS.G([a,$CLJS.Ph.l($CLJS.G([e])),$CLJS.iZ(f)])),f):$CLJS.gE("metabase.lib.js.metadata",h,$CLJS.m_(f,$CLJS.G(["Error parsing %s %s: %s",a,$CLJS.Ph.l($CLJS.G([e])),$CLJS.iZ(f)])),null)),null;throw l;}}};y9=function(a){return $CLJS.Jk.g(x9($CLJS.mK,$CLJS.N),a)};zoa=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.z9=new $CLJS.M(null,"cards","cards",169174038);Aoa=new $CLJS.M(null,"db","db",993250759);$CLJS.A9=new $CLJS.M(null,"metrics","metrics",394093469);
Boa=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.B9=new $CLJS.M(null,"segments","segments",1937535949);Coa=new $CLJS.M(null,"dimensions","dimensions",-254818097);t9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);Doa=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.C9=new $CLJS.M(null,"tables","tables",1334623052);D9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);Eoa=new $CLJS.M(null,"schema-name","schema-name",1666725119);
Foa=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);Goa=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);$CLJS.E9=new $CLJS.M(null,"databases","databases",2040134125);var u9,Hoa=$CLJS.Te($CLJS.N),Ioa=$CLJS.Te($CLJS.N),Joa=$CLJS.Te($CLJS.N),Koa=$CLJS.Te($CLJS.N),Loa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null)),G9,Woa,Xoa,Yoa,Zoa,$oa;u9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.Ch,Loa,Hoa,Ioa,Joa,Koa);u9.m(null,$CLJS.gi,function(){return null});var v9,Moa=$CLJS.Te($CLJS.N),Noa=$CLJS.Te($CLJS.N),Ooa=$CLJS.Te($CLJS.N),Poa=$CLJS.Te($CLJS.N),Qoa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
v9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Ch,Qoa,Moa,Noa,Ooa,Poa);v9.m(null,$CLJS.gi,function(){return null});var w9,Roa=$CLJS.Te($CLJS.N),Soa=$CLJS.Te($CLJS.N),Toa=$CLJS.Te($CLJS.N),Uoa=$CLJS.Te($CLJS.N),Voa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));w9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","lib-type"),$CLJS.Ch,Voa,Roa,Soa,Toa,Uoa);
$CLJS.F9=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Ch.h(f)},e,a,b,c,d)}();Woa=$CLJS.Te($CLJS.N);Xoa=$CLJS.Te($CLJS.N);Yoa=$CLJS.Te($CLJS.N);Zoa=$CLJS.Te($CLJS.N);$oa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
G9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Ch,$oa,Woa,Xoa,Yoa,Zoa);$CLJS.F9.m(null,$CLJS.gi,function(a,b){var c=x9(a,$CLJS.N);return s9($CLJS.ff.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.l_(e),new $CLJS.Mh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),q9(b,G9.h(a)))});w9.m(null,$CLJS.HT,function(){return $CLJS.zfa});
u9.m(null,$CLJS.HT,function(){return new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.XI,null,$CLJS.C9,null],null),null)});v9.m(null,$CLJS.HT,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]));case "features":return $CLJS.eg.j($CLJS.eh,$CLJS.ff.h($CLJS.Ch),b);case "native-permissions":return $CLJS.Ch.h(b);default:return b}}});G9.m(null,$CLJS.HT,function(){return"databases"});w9.m(null,$CLJS.F_,function(){return $CLJS.C_});
u9.m(null,$CLJS.F_,function(){return new $CLJS.dh(null,new $CLJS.k(null,5,[D9,null,$CLJS.XI,null,$CLJS.B9,null,$CLJS.HT,null,$CLJS.A9,null],null),null)});v9.m(null,$CLJS.F_,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.Ch.h(b);case "field-order":return $CLJS.Ch.h(b);case "initial-sync-status":return $CLJS.Ch.h(b);case "visibility-type":return $CLJS.Ch.h(b);default:return b}}});
$CLJS.F9.m(null,$CLJS.F_,function(a,b){var c=x9(a,$CLJS.N);return s9($CLJS.Hk.g($CLJS.Ik.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.ff.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.l_(e),new $CLJS.Mh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),q9(b,"tables"))});w9.m(null,$CLJS.mK,function(){return $CLJS.h_});
u9.m(null,$CLJS.mK,function(){return new $CLJS.dh(null,new $CLJS.k(null,7,[D9,null,$CLJS.F_,null,Coa,null,Goa,null,$CLJS.HT,null,Foa,null,$CLJS.A9,null],null),null)});
v9.m(null,$CLJS.mK,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.Ch.h(b);case "coercion-strategy":return $CLJS.Ch.h(b);case "effective-type":return $CLJS.Ch.h(b);case "fingerprint":return $CLJS.yd(b)?$CLJS.lZ(b):$CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]));case "has-field-values":return $CLJS.Ch.h(b);case "semantic-type":return $CLJS.Ch.h(b);case "visibility-type":return $CLJS.Ch.h(b);case "id":return $CLJS.Ad(b)?$CLJS.jd(b):b;default:return b}}});
$CLJS.F9.m(null,$CLJS.mK,function(a,b){var c=x9(a,$CLJS.N),d=q9(b,"fields");return s9($CLJS.Ul(function(e){var f=$CLJS.I(e,0,null),h=$CLJS.I(e,1,null);e=function(){var l=$CLJS.l_(f);if($CLJS.n(l))return l;l=$CLJS.qh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==q9(d,l);return m?$CLJS.l_(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Mh(function(){return c.h?c.h(h):c.call(null,h)})],null):null}),d)});w9.m(null,$CLJS.eR,function(){return $CLJS.G_});
u9.m(null,$CLJS.eR,function(){return new $CLJS.dh(null,new $CLJS.k(null,11,[D9,null,$CLJS.nl,null,Boa,null,$CLJS.F_,null,Aoa,null,zoa,null,$CLJS.B9,null,$CLJS.HT,null,Doa,null,$CLJS.A9,null,Eoa,null],null),null)});
v9.m(null,$CLJS.eR,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.WD($CLJS.xd,$CLJS.Sa)(b))?y9(b):$CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]));case "fields":return y9(b);case "visibility-type":return $CLJS.Ch.h(b);case "dataset-query":return $CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]));case "dataset":return b;default:return b}}});
$CLJS.F9.m(null,$CLJS.eR,function(a,b){return $CLJS.eg.j($CLJS.N,$CLJS.ff.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Mh(function(){var d=x9($CLJS.eR,new $CLJS.k(null,1,[t9,!1],null)),e=x9($CLJS.eR,$CLJS.N),f=$CLJS.Zk.l;d=$CLJS.R.j(d(q9(q9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.Yi,c);var h=q9(q9(b,"questions"),$CLJS.p.h(c));var l=q9(h,"_card");h=$CLJS.n(l)?l:h;e=e.h?e.h(h):e.call(null,h);return f.call($CLJS.Zk,$CLJS.G([d,e]))})],null)}),$CLJS.eg.j($CLJS.eg.j($CLJS.eh,
$CLJS.Ul($CLJS.x_),$CLJS.Ba(q9(b,"tables"))),$CLJS.ff.h($CLJS.l_),$CLJS.Ba(q9(b,"questions"))))});w9.m(null,$CLJS.hK,function(){return $CLJS.i_});u9.m(null,$CLJS.hK,function(){return new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.F_,null,$CLJS.HT,null],null),null)});v9.m(null,$CLJS.hK,function(){return function(a,b){return b}});G9.m(null,$CLJS.hK,function(){return"metrics"});w9.m(null,$CLJS.NJ,function(){return $CLJS.B_});
u9.m(null,$CLJS.NJ,function(){return new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.F_,null,$CLJS.HT,null],null),null)});v9.m(null,$CLJS.NJ,function(){return function(a,b){return b}});G9.m(null,$CLJS.NJ,function(){return"segments"});