var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';$CLJS.f3=function(a,b,c){return null==c||$CLJS.Id(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.g3=function(a,b){if($CLJS.z(b)){if($CLJS.F.g($CLJS.E(b),1))return $CLJS.B(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.F.g($CLJS.E(b),2)?[$CLJS.p.h($CLJS.B(b)),a,$CLJS.p.h($CLJS.jd(b))].join(""):[$CLJS.Lu(", ",$CLJS.Iu(b)),",",a,$CLJS.p.h($CLJS.kd(b))].join("")}return null};$CLJS.h3=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var lha,mha,nha,oha,pha,l3;
$CLJS.i3=function(){function a(e,f){if($CLJS.Ta(f))return"";e=Math.abs(e);var h=$CLJS.Ch.h(f);switch(h instanceof $CLJS.M?h.T:null){case "default":return $CLJS.oZ("Default period","Default periods",e);case "millisecond":return $CLJS.oZ("Millisecond","Milliseconds",e);case "second":return $CLJS.oZ("Second","Seconds",e);case "minute":return $CLJS.oZ("Minute","Minutes",e);case "hour":return $CLJS.oZ("Hour","Hours",e);case "day":return $CLJS.oZ("Day","Days",e);case "week":return $CLJS.oZ("Week","Weeks",
e);case "month":return $CLJS.oZ("Month","Months",e);case "quarter":return $CLJS.oZ("Quarter","Quarters",e);case "year":return $CLJS.oZ("Year","Years",e);case "minute-of-hour":return $CLJS.oZ("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.oZ("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.oZ("Day of week","Days of week",e);case "day-of-month":return $CLJS.oZ("Day of month","Days of month",e);case "day-of-year":return $CLJS.oZ("Day of year","Days of year",e);case "week-of-year":return $CLJS.oZ("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.oZ("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.oZ("Quarter of year","Quarters of year",e);default:return f=$CLJS.sv($CLJS.jh(f),/-/),e=$CLJS.z(f),f=$CLJS.B(e),e=$CLJS.C(e),$CLJS.Lu(" ",$CLJS.de($CLJS.SD(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.j3=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.AF(f)},e,a,b,c,d)}();lha=$CLJS.Te($CLJS.N);mha=$CLJS.Te($CLJS.N);
nha=$CLJS.Te($CLJS.N);oha=$CLJS.Te($CLJS.N);pha=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.k3=new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.AF,pha,lha,mha,nha,oha);$CLJS.k3.m(null,$CLJS.gi,function(){return null});l3=new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.oL,null,$CLJS.rL,null,$CLJS.sL,null,$CLJS.pL,null],null),null);
$CLJS.qha=$CLJS.eg.j($CLJS.Bf,$CLJS.Hk.g($CLJS.Ik.h(l3),$CLJS.ff.h(function(a){var b=new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.vL,$CLJS.xi,a],null);return $CLJS.F.g(a,$CLJS.Fv)?$CLJS.R.j(b,$CLJS.gi,!0):b})),$CLJS.HL);$CLJS.rha=$CLJS.Jk.g(function(a){var b=new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.vL,$CLJS.xi,a],null);return $CLJS.F.g(a,$CLJS.Gv)?$CLJS.R.j(b,$CLJS.gi,!0):b},$CLJS.CL);
$CLJS.sha=$CLJS.eg.j($CLJS.Bf,$CLJS.Hk.g($CLJS.Ik.h(l3),$CLJS.ff.h(function(a){var b=new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.vL,$CLJS.xi,a],null);return $CLJS.F.g(a,$CLJS.Gv)?$CLJS.R.j(b,$CLJS.gi,!0):b})),$CLJS.IL);$CLJS.z1.m(null,$CLJS.vL,function(a,b,c){a=$CLJS.Ge(c);a=$CLJS.J.g(a,$CLJS.xi);return $CLJS.i3.h(a)});$CLJS.H1.m(null,$CLJS.vL,function(a,b,c){return $CLJS.Zk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.rI,$CLJS.d1.j(a,b,c)],null),$CLJS.bh(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gi,$CLJS.l1],null))]))});
$CLJS.m3=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,h,l){return $CLJS.AF(l)},e,a,b,c,d)}();$CLJS.m3.m(null,$CLJS.gi,function(){return $CLJS.eh});
$CLJS.n3=function(){function a(d,e,f){return $CLJS.m3.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();