var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var sI,uI,xI,II;$CLJS.mI=new $CLJS.M("metabase.lib.schema.id","metric","metabase.lib.schema.id/metric",369478774);$CLJS.nI=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.oI=new $CLJS.M("metabase.lib.schema.id","segment","metabase.lib.schema.id/segment",-983297351);$CLJS.pI=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.qI=new $CLJS.M(null,"mbql","mbql",69346710);
$CLJS.rI=new $CLJS.M(null,"display-name","display-name",694513143);sI=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.tI=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);uI=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.vI=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);
$CLJS.wI=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);xI=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.yI=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.zI=new $CLJS.M(null,"operator","operator",-1860875338);
$CLJS.AI=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.BI=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.CI=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.DI=new $CLJS.M(null,"x","x",2099068185);$CLJS.EI=new $CLJS.M("metabase.lib.schema.id","field","metabase.lib.schema.id/field",-985236431);
$CLJS.FI=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.GI=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.HI=new $CLJS.M(null,"strategy","strategy",-1471631918);II=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.X($CLJS.wI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vj,new $CLJS.k(null,1,[$CLJS.Un,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"non-blank string"],null),$CLJS.Oe($CLJS.bE)],null)],null));$CLJS.X($CLJS.AI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.Un,0],null)],null));$CLJS.X($CLJS.nI,$CLJS.Ml);$CLJS.X(II,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vj,new $CLJS.k(null,2,[$CLJS.Un,36,$CLJS.ek,36],null)],null));
$CLJS.X(xI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,2,[$CLJS.zu,"valid semantic type",$CLJS.Bu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Bj);return["Not a valid semantic type: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.PF(a,$CLJS.qj)}],null));
$CLJS.X(uI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,2,[$CLJS.zu,"valid relation type",$CLJS.Bu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Bj);return["Not a valid relation type: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.PF(a,$CLJS.si)}],null));$CLJS.X($CLJS.tI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uI],null)],null));
$CLJS.X($CLJS.FI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,2,[$CLJS.zu,"valid base type",$CLJS.Bu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Bj);return["Not a valid base type: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.PF(a,$CLJS.yj)}],null));
$CLJS.X($CLJS.BI,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CI,II],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.FI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.FI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rj,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,
$CLJS.tI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yI,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.wI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.wI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rI,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.wI],null)],null)],null));
$CLJS.X(sI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.vI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,$CLJS.Vj,$CLJS.Wi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,$CLJS.pi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.BI],null)],null));