var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var K_,Afa,L_,Bfa,Cfa,N_,Efa,Ffa,Gfa,Hfa,Q_,R_,S_,T_,V_,Jfa,W_,X_,Kfa,P_,Dfa,Y_,Z_,Ifa,$_;K_=function(a,b){var c=$CLJS.z(b);for(a=$CLJS.z($CLJS.Ye(a,b));;)if(a)c=$CLJS.C(c),a=$CLJS.C(a);else return c};
Afa=function(){return function(){function a(h,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj.j?$CLJS.kj.j(h,l,m):$CLJS.kj.call(null,h,l,m),$CLJS.kl.j?$CLJS.kl.j(h,l,m):$CLJS.kl.call(null,h,l,m)],null)}function b(h,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj.g?$CLJS.kj.g(h,l):$CLJS.kj.call(null,h,l),$CLJS.kl.g?$CLJS.kl.g(h,l):$CLJS.kl.call(null,h,l)],null)}function c(h){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj.h?$CLJS.kj.h(h):$CLJS.kj.call(null,h),$CLJS.kl.h?$CLJS.kl.h(h):$CLJS.kl.call(null,
h)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj.o?$CLJS.kj.o():$CLJS.kj.call(null),$CLJS.kl.o?$CLJS.kl.o():$CLJS.kl.call(null)],null)}var e=null,f=function(){function h(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.y(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.O.N($CLJS.kj,m,t,u,v),$CLJS.O.N($CLJS.kl,m,t,u,v)],null)}h.A=3;h.B=function(m){var t=
$CLJS.B(m);m=$CLJS.C(m);var u=$CLJS.B(m);m=$CLJS.C(m);var v=$CLJS.B(m);m=$CLJS.Lc(m);return l(t,u,v,m)};h.l=l;return h}();e=function(h,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,h);case 2:return b.call(this,h,l);case 3:return a.call(this,h,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.y(v,0,null)}return f.l(h,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};L_=function(a){return $CLJS.wP($CLJS.ZZ)(a,$CLJS.Bf,$CLJS.Bf)};Bfa=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.bK,b),$CLJS.iu(2,2,c))};$CLJS.M_=function(a,b){return $CLJS.fc($CLJS.bb(function(c,d){return $CLJS.hg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.cc($CLJS.N),b))};Cfa=function(a){return $CLJS.cf.g($CLJS.DZ(a),$CLJS.EZ(a))};
N_=function(a,b,c){var d=new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.Nt,null,$CLJS.rl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.fw(2,c):$CLJS.fw(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?K_(2,c):K_(1,c);var f=$CLJS.I(b,0,null);return $CLJS.z(e)?$CLJS.sZ.j(a,e,function(h){var l=$CLJS.R.j(h,f,null);return $CLJS.Ad(h)?$CLJS.eg.j($CLJS.Bf,$CLJS.Ik.h($CLJS.Ra),l):$CLJS.yd(h)?$CLJS.dE($CLJS.Ua,l):l}):$CLJS.Ak.g(a,f)};
Efa=function(a){return $CLJS.bb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return N_(b,Dfa,d)},a,Cfa(a))};
Ffa=function(a){for(var b=0;;){var c=$CLJS.cd($CLJS.hP.h(a),b);a:{var d=c;for(var e=$CLJS.Bf;;){var f=$CLJS.B($CLJS.ff.g(Afa(),$CLJS.jf($CLJS.Hk.j(O_,$CLJS.B,$CLJS.kl),$CLJS.Rt.h(L_(d)))));if($CLJS.n(f)){var h=f;f=$CLJS.I(h,0,null);h=$CLJS.I(h,1,null);var l=N_(d,f,h),m=$CLJS.iE($CLJS.oC);if($CLJS.n($CLJS.hE("metabase.lib.convert",m))){var t=$CLJS.LE.l($CLJS.G([$CLJS.jD,$CLJS.Ph.l($CLJS.G([h]))])),u=$CLJS.LE,v=u.l,x=$CLJS.Ph,A=x.l;var D=f;D=$CLJS.n(D)?D:$CLJS.BP(L_(d));$CLJS.gE("metabase.lib.convert",
m,$CLJS.m_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([t,v.call(u,$CLJS.G([$CLJS.jD,A.call(x,$CLJS.G([D]))])),$CLJS.LE.l($CLJS.G([$CLJS.ZC,$CLJS.n_($CLJS.B($CLJS.Lv(d,l)))]))])),null)}if($CLJS.F.g(l,d))break a;d=l;e=$CLJS.ae.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,h],null))}else break a}}d=Efa(d);if($CLJS.F.g(c,d)){if($CLJS.F.g(b,$CLJS.E($CLJS.hP.h(a))-1))return a;b+=1}else a=$CLJS.Kk.N(a,$CLJS.hP,$CLJS.R,b,d)}};
Gfa=function(a){var b=$CLJS.z_();return $CLJS.Jk.g(function(c){return $CLJS.F.g($CLJS.aJ.h(c),"__join")?$CLJS.Kk.j(c,$CLJS.aJ,b):c},a)};Hfa=function(a){return"string"===typeof $CLJS.SS.h(a)?$CLJS.Ak.g($CLJS.R.j(a,$CLJS.FZ,$CLJS.x_($CLJS.SS.h(a))),$CLJS.SS):a};Q_=function(a,b){var c=$CLJS.J.j(a,b,P_);if($CLJS.F.g(c,P_))throw $CLJS.li(["Unable to find ",$CLJS.Ph.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[Ifa,a,$CLJS.Vy,b],null));return c};
R_=function(){return $CLJS.Ik.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.le(b))?(b=$CLJS.he(b),b=$CLJS.n(b)?$CLJS.F.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};S_=function(a){return $CLJS.eg.j($CLJS.N,R_(),a)};T_=function(a){return $CLJS.He($CLJS.eg.j($CLJS.N,$CLJS.Hk.g(R_(),$CLJS.Ik.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.F.g(c,$CLJS.Hi)})),a))};
V_=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.ff.h($CLJS.U_),c);a=$CLJS.F.g(a,$CLJS.XJ)&&$CLJS.C(c)?$CLJS.ae.g($CLJS.td(d),new $CLJS.k(null,1,[$CLJS.gi,$CLJS.sd(d)],null)):d;b=$CLJS.He(T_(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cQ,a,b],null):a};Jfa=function(a){return $CLJS.eg.j($CLJS.Bf,$CLJS.Hk.g($CLJS.ff.h(function(b){return $CLJS.YD(b,$CLJS.PE)}),$CLJS.ff.h($CLJS.U_)),$CLJS.u_.h(a))};
W_=function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.hP);a=$CLJS.B($CLJS.bb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.U_.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.vU,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.ES,Jfa(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.bZ.h(c)],null)},null,a));return $CLJS.n($CLJS.WT.h(a))?$CLJS.pZ(a,new $CLJS.k(null,1,[$CLJS.WT,$CLJS.qQ],null)):a};
X_=function(a,b,c){var d=$CLJS.F.g($CLJS.E($CLJS.J.g(a,b)),1)?$CLJS.tZ.j(a,b,$CLJS.Hk.g($CLJS.U_,$CLJS.B)):a;a=1<$CLJS.E($CLJS.J.g(a,b))?$CLJS.tZ.j(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.st],null),$CLJS.ff.h($CLJS.U_),e)}):d;return $CLJS.pZ(a,$CLJS.Ee([b,c]))};Kfa=function(a){var b=$CLJS.FZ.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Ak.g(a,$CLJS.FZ),$CLJS.SS,["card__",$CLJS.p.h(b)].join("")):a};
P_=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Dfa=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);Y_=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);Z_=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);Ifa=new $CLJS.M(null,"m","m",1632677161);
$_=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var a0,b0,O_,Lfa,Mfa,Nfa,Ofa,Pfa,Qfa,Rfa,Sfa,Tfa,Ufa;a0=$CLJS.N;b0=$CLJS.N;O_=new $CLJS.dh(null,new $CLJS.k(null,7,[$CLJS.WZ,null,$CLJS.XI,null,$CLJS.nR,null,$CLJS.MT,null,$CLJS.tS,null,$CLJS.CV,null,$CLJS.bK,null],null),null);Lfa=$CLJS.Te($CLJS.N);Mfa=$CLJS.Te($CLJS.N);Nfa=$CLJS.Te($CLJS.N);Ofa=$CLJS.Te($CLJS.N);Pfa=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
$CLJS.c0=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.AF,Pfa,Lfa,Mfa,Nfa,Ofa);$CLJS.c0.m(null,$CLJS.gi,function(a){if($CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M){var b=$CLJS.sd(a);var c=$CLJS.yd(b);a=c?$CLJS.td(a):a;var d=$CLJS.z(a);a=$CLJS.B(d);d=$CLJS.C(d);b=$CLJS.f_($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.ff.h($CLJS.c0),d))}else b=a;return b});$CLJS.c0.m(null,$CLJS.VZ,function(a){return a});
$CLJS.c0.m(null,$CLJS.KZ,function(a){var b=$CLJS.c0.h($CLJS.bK.h(a)),c=$CLJS.He($CLJS.Jk.g(function(h){var l=$CLJS.I(h,0,null);h=$CLJS.I(h,1,null);return $CLJS.r_($CLJS.c0.h(h),l)},$CLJS.MT.h(a))),d=b0;b0=$CLJS.eg.j($CLJS.N,$CLJS.cm(function(h,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.Ge(l);l=$CLJS.J.g(l,$CLJS.CI);return new $CLJS.P(null,2,5,$CLJS.Q,[h,l],null)}),b);try{var e=Bfa(Hfa(a),b,$CLJS.G([$CLJS.MT,c])),f=$CLJS.bb(function(h,l){return $CLJS.Ta($CLJS.J.g(h,l))?h:$CLJS.Kk.j(h,l,$CLJS.c0)},
e,$CLJS.Bk.l(O_,$CLJS.bK,$CLJS.G([$CLJS.MT])));return $CLJS.n($CLJS.nR.h(f))?$CLJS.Kk.j(f,$CLJS.nR,Gfa):f}finally{b0=d}});$CLJS.c0.m(null,$CLJS.QZ,function(a){return $CLJS.tZ.v(a,$CLJS.sU,$CLJS.Ku,function(b){return $CLJS.tZ.j(b,$CLJS.RP,$CLJS.c0)})});
$CLJS.c0.m(null,$CLJS.cP,function(a){a=$CLJS.Kk.j($CLJS.Kk.j(a,$CLJS.kP,$CLJS.c0),$CLJS.hP,$CLJS.c0);var b=$CLJS.n($CLJS.XI.h(a))?$CLJS.Kk.j(a,$CLJS.XI,function(c){return $CLJS.Jl(c)?$CLJS.Jk.g($CLJS.c0,c):$CLJS.Ch.h(c)}):a;return $CLJS.Ta($CLJS.aJ.h(a))?$CLJS.R.j(b,$CLJS.aJ,"__join"):b});$CLJS.c0.m(null,$CLJS.wE,function(a){return $CLJS.Jk.g($CLJS.c0,a)});
$CLJS.c0.m(null,$CLJS.yE,function(a){return $CLJS.n($CLJS.kj.h(a))?Ffa($CLJS.Kk.j($CLJS.v_(a),$CLJS.hP,function(b){return $CLJS.Jk.g($CLJS.c0,b)})):$CLJS.Ku(a,$CLJS.c0)});$CLJS.c0.m(null,$CLJS.mK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.yd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.f_(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,a,b],null))});
$CLJS.c0.m(null,$CLJS.Bj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.pZ(a,new $CLJS.k(null,3,[$CLJS.RG,$CLJS.CE,$CLJS.pS,$CLJS.Rj,$CLJS.KV,$CLJS.yI],null));var c=$CLJS.R.j;var d=$CLJS.Hi.h(a);$CLJS.n(d)||(d=$CLJS.CE.h(a),d=$CLJS.n(d)?d:$CLJS.PI(b));a=c.call($CLJS.R,a,$CLJS.Hi,d);return $CLJS.f_(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,a,b],null))});
$CLJS.c0.m(null,$CLJS.XJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.gi.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,$CLJS.Ak.g(c,$CLJS.gi),$CLJS.Jk.g($CLJS.c0,b)],null);b=$CLJS.f_(b);return null!=a?$CLJS.ae.g(b,$CLJS.c0.h(a)):b});$CLJS.c0.m(null,$CLJS.zE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.f_(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.c0.m(null,$CLJS.bK,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.f_(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,Q_(b0,c)],null))});$CLJS.c0.m(null,$CLJS.cQ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.c0.h(b);var c=$CLJS.z(b);b=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Zk.l($CLJS.G([c,a]))],null),d)});Qfa=$CLJS.Te($CLJS.N);Rfa=$CLJS.Te($CLJS.N);Sfa=$CLJS.Te($CLJS.N);
Tfa=$CLJS.Te($CLJS.N);Ufa=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.U_=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.AF,Ufa,Qfa,Rfa,Sfa,Tfa);
$CLJS.U_.m(null,$CLJS.gi,function(a){if($CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M){a=$CLJS.z(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);if($CLJS.yd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.cf.g;d=$CLJS.ff.g($CLJS.U_,d);a=T_(a);a=c.call($CLJS.eg,b,e.call($CLJS.cf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.ff.g($CLJS.U_,$CLJS.de(a,d)))}else a=$CLJS.yd(a)?$CLJS.Ku(S_(a),$CLJS.U_):
a;return a});for(var d0=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$_,Z_],null)),e0=null,f0=0,g0=0;;)if(g0<f0){var Vfa=e0.X(null,g0);$CLJS.yJ(Vfa,Y_);g0+=1}else{var h0=$CLJS.z(d0);if(h0){var i0=h0;if($CLJS.Bd(i0)){var j0=$CLJS.kc(i0),Wfa=$CLJS.lc(i0),Xfa=j0,Yfa=$CLJS.E(j0);d0=Wfa;e0=Xfa;f0=Yfa}else{var Zfa=$CLJS.B(i0);$CLJS.yJ(Zfa,Y_);d0=$CLJS.C(i0);e0=null;f0=0}g0=0}else break}
for(var k0=$CLJS.z(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.TA,$CLJS.gK,$CLJS.vK,$CLJS.TJ,$CLJS.ek,$CLJS.AK,$CLJS.Un,$CLJS.ZJ,$CLJS.tK,$CLJS.DK,$CLJS.zK,$CLJS.HK],null)),l0=null,m0=0,n0=0;;)if(n0<m0){var $fa=l0.X(null,n0);$CLJS.yJ($fa,$_);n0+=1}else{var o0=$CLJS.z(k0);if(o0){var p0=o0;if($CLJS.Bd(p0)){var q0=$CLJS.kc(p0),aga=$CLJS.lc(p0),bga=q0,cga=$CLJS.E(q0);k0=aga;l0=bga;m0=cga}else{var dga=$CLJS.B(p0);$CLJS.yJ(dga,$_);k0=$CLJS.C(p0);l0=null;m0=0}n0=0}else break}
for(var r0=$CLJS.z($CLJS.xf([$CLJS.Us,$CLJS.pu,$CLJS.mk,$CLJS.IK,$CLJS.XJ,$CLJS.pK,$CLJS.PJ,$CLJS.KK,$CLJS.IJ,$CLJS.aK,$CLJS.LJ,$CLJS.kK,$CLJS.rK,$CLJS.MJ,$CLJS.jN,$CLJS.lN,$CLJS.pC,$CLJS.fN,$CLJS.nN,$CLJS.$M,$CLJS.rN,$CLJS.eN,$CLJS.YM,$CLJS.qN,$CLJS.yN,$CLJS.uN,$CLJS.dN,$CLJS.BN,$CLJS.tN,$CLJS.zN,$CLJS.OJ,$CLJS.JJ,$CLJS.LK,$CLJS.rM,$CLJS.oK,$CLJS.KA,$CLJS.EK,$CLJS.GK,$CLJS.MK,$CLJS.Pu,$CLJS.dv],!0)),s0=null,t0=0,u0=0;;)if(u0<t0){var ega=s0.X(null,u0);$CLJS.yJ(ega,Z_);u0+=1}else{var v0=$CLJS.z(r0);
if(v0){var w0=v0;if($CLJS.Bd(w0)){var x0=$CLJS.kc(w0),fga=$CLJS.lc(w0),gga=x0,hga=$CLJS.E(x0);r0=fga;s0=gga;t0=hga}else{var iga=$CLJS.B(w0);$CLJS.yJ(iga,Z_);r0=$CLJS.C(w0);s0=null;t0=0}u0=0}else break}$CLJS.U_.m(null,Y_,function(a){return V_(a)});$CLJS.U_.m(null,$CLJS.yE,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Hk.g(R_(),$CLJS.ff.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.U_.h(b)],null)})),a)});
$CLJS.U_.m(null,$CLJS.bK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);return $CLJS.yd(b)?(a=T_(b),c=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,Q_(a0,c)],null),$CLJS.n(a)?$CLJS.ae.g(c,a):c):a});$CLJS.U_.m(null,$CLJS.wE,function(a){return $CLJS.Jk.g($CLJS.U_,a)});
$CLJS.U_.m(null,$CLJS.mK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.yd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,$CLJS.U_.h(a),T_(b)],null)});
$CLJS.U_.m(null,$CLJS.Bj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=T_($CLJS.pZ(b,new $CLJS.k(null,3,[$CLJS.CE,$CLJS.RG,$CLJS.Rj,$CLJS.pS,$CLJS.yI,$CLJS.KV],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,a,b],null)});
$CLJS.U_.m(null,$CLJS.cP,function(a){var b=S_(a);a=0==$CLJS.aJ.h(a).lastIndexOf("__join",0)?$CLJS.Ak.g(b,$CLJS.aJ):b;return $CLJS.Zk.l($CLJS.G([$CLJS.Ku($CLJS.Ak.l(a,$CLJS.hP,$CLJS.G([$CLJS.kP])),$CLJS.U_),X_($CLJS.bh(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kP],null)),$CLJS.kP,$CLJS.EV),W_(a)]))});
$CLJS.U_.m(null,$CLJS.KZ,function(a){var b=a0;a0=$CLJS.eg.j($CLJS.N,$CLJS.cm(function(c,d){$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.Ge(d);d=$CLJS.J.g(d,$CLJS.CI);return new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}),$CLJS.bK.h(a));try{return $CLJS.bb(function(c,d){return $CLJS.tZ.j(c,d,$CLJS.U_)},X_($CLJS.tZ.j($CLJS.tZ.j(Kfa(S_(a)),$CLJS.bK,function(c){return $CLJS.Jk.g(V_,c)}),$CLJS.MT,function(c){return $CLJS.eg.g($CLJS.N,function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=
$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u),x=$CLJS.U_.h(v);v=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.q_(v),$CLJS.F.g($CLJS.Bj,$CLJS.B(x))?$CLJS.jd(x):x],null);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.B(h);l=$CLJS.U_.h(t);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.q_(t),$CLJS.F.g($CLJS.Bj,$CLJS.B(l))?$CLJS.jd(l):l],null),f($CLJS.Lc(h)))}return null}},
null,null)}(c)}())}),$CLJS.WZ,$CLJS.RQ),$CLJS.Bk.l(O_,$CLJS.bK,$CLJS.G([$CLJS.WZ,$CLJS.MT])))}finally{a0=b}});$CLJS.U_.m(null,$CLJS.QZ,function(a){return $CLJS.Ku(S_(a),$CLJS.U_)});
$CLJS.U_.m(null,$CLJS.VZ,function(a){var b=S_(a),c=$CLJS.dR.h(b),d=W_(b),e=$CLJS.F.g($CLJS.Ev.h($CLJS.kd($CLJS.hP.h(a))),$CLJS.QZ)?$CLJS.WT:$CLJS.qQ;a=$CLJS.Zk.l;b=$CLJS.Ku($CLJS.Ak.l(b,$CLJS.hP,$CLJS.G([$CLJS.dR])),$CLJS.U_);d=$CLJS.Ee([$CLJS.kj,e,e,d]);c=$CLJS.z(c)?$CLJS.R.j(d,$CLJS.dR,c):d;return a.call($CLJS.Zk,$CLJS.G([b,c]))});