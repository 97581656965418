var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var k_,sfa,tfa,s_,ufa,vfa,xfa,o_,yfa,wfa;k_=function(a){return a+1};$CLJS.l_=function(a){if("string"===typeof a){var b=$CLJS.qh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.em(a));};
sfa=function(a){var b=new $CLJS.Fa,c=$CLJS.hw;$CLJS.hw=new $CLJS.rc(b);try{var d=$CLJS.hw,e=$CLJS.Ta($CLJS.xx(d)),f=$CLJS.hw;$CLJS.hw=e?$CLJS.ox(d):d;try{d=$CLJS.zx;$CLJS.zx=!0;try{$CLJS.Bx(a)}finally{$CLJS.zx=d}$CLJS.F.g(0,$CLJS.pw($CLJS.hw,$CLJS.rw))||$CLJS.$b($CLJS.hw,"\n");$CLJS.ow()}finally{$CLJS.hw=f}$CLJS.xh($CLJS.p.h(b))}finally{$CLJS.hw=c}};$CLJS.m_=function(a,b){return $CLJS.O.j($CLJS.ha,a,b)};
$CLJS.n_=function(a){var b=new $CLJS.Fa,c=$CLJS.Oa,d=$CLJS.Pa;$CLJS.Oa=!0;$CLJS.Pa=function(e){return b.append(e)};try{sfa(a)}finally{$CLJS.Pa=d,$CLJS.Oa=c}return $CLJS.p.h(b)};
tfa=function(a){a=$CLJS.Ge(a);var b=$CLJS.J.j(a,$CLJS.xZ,$CLJS.Td),c=$CLJS.J.j(a,$CLJS.uZ,function(f,h){return[$CLJS.p.h(f),"_",$CLJS.p.h(h)].join("")}),d=$CLJS.Te($CLJS.N),e=$CLJS.Te($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),D=$CLJS.q(d);return D.h?D.h(A):D.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Rh.v(e,$CLJS.Kk,u,$CLJS.XH(k_,0)),u);if($CLJS.F.g(x,1))return $CLJS.Rh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var A=t,D=$CLJS.p.h(x);return c.g?c.g(A,D):c.call(null,A,D)}()}}function h(m){var t=l,u=t.g;null==o_&&(o_=$CLJS.Te(0));var v=$CLJS.Ih.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Rh.g(o_,k_))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return h.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=h;l.g=f;return l}()};
$CLJS.p_=function(a){return $CLJS.Ad(a)&&1<$CLJS.E(a)&&$CLJS.B(a)instanceof $CLJS.M&&$CLJS.yd($CLJS.jd(a))&&$CLJS.Id($CLJS.jd(a),$CLJS.CI)};$CLJS.q_=function(a){return $CLJS.p_(a)?$CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,$CLJS.eJ],null)):null};$CLJS.r_=function(a,b){return $CLJS.CP($CLJS.p_(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.k(null,2,[$CLJS.CI,$CLJS.p.h($CLJS.KI()),$CLJS.Hi,$CLJS.PI(a)],null),a],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,$CLJS.eJ],null),b)};
s_=function(a,b,c){var d=$CLJS.Id(a,b)?$CLJS.Kk.j(a,b,function(e){return $CLJS.Ad(e)&&$CLJS.F.g($CLJS.B(e),$CLJS.st)?$CLJS.Cf($CLJS.Ye(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Id(a,b)?$CLJS.pZ(d,$CLJS.Ee([b,c])):d};ufa=function(a){var b=$CLJS.bh(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SS,$CLJS.vU],null));b=t_.h?t_.h(b):t_.call(null,b);return $CLJS.f_($CLJS.R.l(s_($CLJS.Ak.l(a,$CLJS.SS,$CLJS.G([$CLJS.vU])),$CLJS.EV,$CLJS.kP),$CLJS.Ev,$CLJS.cP,$CLJS.G([$CLJS.hP,b])))};
vfa=function(a){return $CLJS.Jk.g(ufa,a)};xfa=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Kk.j($CLJS.Jl(a)?new $CLJS.k(null,1,[$CLJS.u_,a],null):a,$CLJS.u_,function(b){return $CLJS.Jk.g(function(c){return $CLJS.R.j($CLJS.YD(c,$CLJS.nE),$CLJS.Ev,$CLJS.h_)},b)}),$CLJS.Ev,wfa):null};
$CLJS.v_=function(a){if($CLJS.F.g($CLJS.Ev.h(a),$CLJS.VZ))return a;var b=$CLJS.kj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.Zk.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.VZ,$CLJS.hP,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Zk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.Ev,$CLJS.QZ],null),$CLJS.pZ($CLJS.WT.h(a),new $CLJS.k(null,1,[$CLJS.qQ,$CLJS.WT],null))]))],null)],null),$CLJS.Ak.l(a,$CLJS.kj,$CLJS.G([$CLJS.WT]))]));case "query":return $CLJS.Zk.l($CLJS.G([new $CLJS.k(null,
2,[$CLJS.Ev,$CLJS.VZ,$CLJS.hP,t_($CLJS.qQ.h(a))],null),$CLJS.Ak.l(a,$CLJS.kj,$CLJS.G([$CLJS.qQ]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.w_=function(a,b){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.hP);var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.li($CLJS.LI("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.k(null,1,[yfa,$CLJS.E(a)],null));return c};
$CLJS.x_=function(a){return"string"===typeof a&&(a=$CLJS.rh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.l_(a)):null};$CLJS.z_=function(){return $CLJS.Hk.g(y_,tfa($CLJS.G([$CLJS.xZ,$CLJS.kE,$CLJS.uZ,function(a,b){return y_.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};o_=null;$CLJS.A_=new $CLJS.M("lib","source","lib/source",-434086550);$CLJS.B_=new $CLJS.M("metadata","segment","metadata/segment",-1286738015);
$CLJS.C_=new $CLJS.M("metadata","table","metadata/table",-882499405);yfa=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.D_=new $CLJS.M(null,"table-id","table-id",-766649466);$CLJS.u_=new $CLJS.M(null,"columns","columns",1998437288);$CLJS.E_=new $CLJS.M("source","breakouts","source/breakouts",-1706018158);$CLJS.zfa=new $CLJS.M("metadata","database","metadata/database",-924828824);$CLJS.F_=new $CLJS.M(null,"table","table",-564943036);
$CLJS.G_=new $CLJS.M("metadata","card","metadata/card",-1039333889);wfa=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.H_=new $CLJS.M("source","aggregations","source/aggregations",-1828710137);var t_,y_;
t_=function t_(a){a=$CLJS.Ge(a);var c=$CLJS.J.g(a,$CLJS.vU),d=$CLJS.J.g(a,$CLJS.ES);c=$CLJS.n(c)?t_.h?t_.h(c):t_.call(null,c):$CLJS.Bf;d=xfa(d);var e=$CLJS.E(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.CP(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.bZ],null),d):c;d=$CLJS.n($CLJS.WT.h(a))?$CLJS.QZ:$CLJS.KZ;a=$CLJS.Zk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.Ev,d],null),$CLJS.Ak.l(a,$CLJS.vU,$CLJS.G([$CLJS.ES]))]));a=$CLJS.z($CLJS.nR.h(a))?$CLJS.Kk.j(a,$CLJS.nR,vfa):a;a=s_(a,$CLJS.RQ,$CLJS.WZ);return $CLJS.ae.g(c,a)};
$CLJS.I_=function(){function a(c,d,e,f){var h=null;if(3<arguments.length){h=0;for(var l=Array(arguments.length-3);h<l.length;)l[h]=arguments[h+3],++h;h=new $CLJS.y(l,0,null)}return b.call(this,c,d,e,h)}function b(c,d,e,f){c=$CLJS.v_(c);c=$CLJS.Ge(c);var h=$CLJS.J.g(c,$CLJS.hP);d=$CLJS.w_(c,d);e=$CLJS.O.N($CLJS.Kk,$CLJS.Cf(h),d,e,f);return $CLJS.R.j(c,$CLJS.hP,e)}a.A=3;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.C(c);var f=$CLJS.B(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
y_=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.a_.str(d,0)>>>0).toString(16);;)if(8>$CLJS.E(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.J_=RegExp(" id$","i");