var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var NL,OL,qaa,QL,raa,saa,taa,RL,PL;$CLJS.LL=function(a,b){return $CLJS.fc($CLJS.bb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.ae.g($CLJS.J.j(c,e,$CLJS.Bf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.ML=function(a,b){return $CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
NL=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.PF(b,$CLJS.vG)?$CLJS.xL:$CLJS.PF(b,$CLJS.TH)?$CLJS.kI:$CLJS.PF(b,$CLJS.dH)?$CLJS.lI:null;return $CLJS.n(b)?$CLJS.xJ(b,a):!0};
OL=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.LL(function(d){return $CLJS.PF($CLJS.PI(d),$CLJS.OG)},a);var b=$CLJS.Ge(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.ah.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.PI($CLJS.B(a));return $CLJS.Ne(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(NL(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
qaa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,new $CLJS.k(null,1,[$CLJS.zu,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$s,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PL],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$s,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PL],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.Bu,function(b){b=$CLJS.Ge(b);b=$CLJS.J.g(b,$CLJS.Bj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(OL(b))].join("")}],null),$CLJS.Oe(OL)],null)],null)};
QL=function(a){var b=$CLJS.Q,c=qaa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.zu,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$s,new $CLJS.k(null,1,[$CLJS.Un,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.mt,c,a],null)};raa=function(a){return $CLJS.Qd($CLJS.AJ,$CLJS.ff.g(function(b){var c=$CLJS.PI(b),d=$CLJS.PF(c,$CLJS.dJ);b=d?$CLJS.xJ($CLJS.qK,b):d;return $CLJS.n(b)?$CLJS.Lj:c},a))};saa=function(a){a=$CLJS.ML(function(b){return!$CLJS.PF(b,$CLJS.OG)},$CLJS.ff.g($CLJS.PI,a));return $CLJS.PF(a,$CLJS.dJ)?$CLJS.Sj:a};taa=function(a){return $CLJS.n($CLJS.Ne(function(b){return $CLJS.PF($CLJS.PI(b),$CLJS.OG)},a))?saa(a):raa(a)};
RL=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.SL=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);PL=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,PL],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.PI(a);return $CLJS.Me(function(d){return NL(d,c)},b)}],null)],null));
$CLJS.X(RL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$s,new $CLJS.k(null,1,[$CLJS.Un,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)],null));$CLJS.NK.g($CLJS.Us,QL($CLJS.Us));$CLJS.NK.g($CLJS.pu,QL($CLJS.pu));$CLJS.HJ($CLJS.mk,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,RL],null)]));$CLJS.HJ($CLJS.IK,$CLJS.G([$CLJS.pu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,RL],null)]));
for(var TL=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Us,$CLJS.pu,$CLJS.mk],null)),UL=null,VL=0,WL=0;;)if(WL<VL){var uaa=UL.X(null,WL);$CLJS.yJ(uaa,$CLJS.SL);WL+=1}else{var ZL=$CLJS.z(TL);if(ZL){var $L=ZL;if($CLJS.Bd($L)){var aM=$CLJS.kc($L),vaa=$CLJS.lc($L),waa=aM,xaa=$CLJS.E(aM);TL=vaa;UL=waa;VL=xaa}else{var yaa=$CLJS.B($L);$CLJS.yJ(yaa,$CLJS.SL);TL=$CLJS.C($L);UL=null;VL=0}WL=0}else break}$CLJS.OI.m(null,$CLJS.SL,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return taa(a)});
$CLJS.FJ($CLJS.PJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));$CLJS.yJ($CLJS.PJ,$CLJS.jJ);
for(var bM=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KK,$CLJS.IJ,$CLJS.aK],null)),cM=null,dM=0,eM=0;;)if(eM<dM){var zaa=cM.X(null,eM);$CLJS.FJ(zaa,$CLJS.G([$CLJS.pu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));eM+=1}else{var fM=$CLJS.z(bM);if(fM){var gM=fM;if($CLJS.Bd(gM)){var hM=$CLJS.kc(gM),Aaa=$CLJS.lc(gM),Baa=hM,Caa=$CLJS.E(hM);bM=Aaa;cM=Baa;dM=Caa}else{var Daa=$CLJS.B(gM);$CLJS.FJ(Daa,$CLJS.G([$CLJS.pu,$CLJS.NH,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));bM=$CLJS.C(gM);cM=null;dM=0}eM=0}else break}
for(var iM=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LJ,$CLJS.kK,$CLJS.rK],null)),jM=null,kM=0,lM=0;;)if(lM<kM){var Eaa=jM.X(null,lM);$CLJS.FJ(Eaa,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));lM+=1}else{var mM=$CLJS.z(iM);if(mM){var nM=mM;if($CLJS.Bd(nM)){var oM=$CLJS.kc(nM),Faa=$CLJS.lc(nM),Gaa=oM,Haa=$CLJS.E(oM);iM=Faa;jM=Gaa;kM=Haa}else{var Iaa=$CLJS.B(nM);$CLJS.FJ(Iaa,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));iM=$CLJS.C(nM);jM=null;kM=0}lM=0}else break}$CLJS.FJ($CLJS.MJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));
$CLJS.OI.m(null,$CLJS.MJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.PF($CLJS.PI(b),$CLJS.uj)&&$CLJS.PF($CLJS.PI(a),$CLJS.uj)?$CLJS.uj:$CLJS.NH});