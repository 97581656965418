var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var z0,A0,jga,lga,mga,oga,pga,P0,sga,tga,uga,vga,wga,U0,xga;$CLJS.y0=function(a,b){a=$CLJS.v_(a);a=$CLJS.Ge(a);var c=$CLJS.J.g(a,$CLJS.hP);return $CLJS.J.g($CLJS.Cf(c),$CLJS.w_(a,b))};z0=function(){};A0=function(a,b){if(null!=a&&null!=a.$f)a=a.$f(a,b);else{var c=A0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=A0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.field",a);}return a};
$CLJS.B0=function(a){return null!=a?$CLJS.r===a.Qh?!0:a.Pc?!1:$CLJS.Xa(z0,a):$CLJS.Xa(z0,a)};$CLJS.C0=function(a){return $CLJS.B0(a)?a:$CLJS.aZ.h(a)};$CLJS.D0=function(a,b){return A0($CLJS.C0(a),b)};jga=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.E0=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);$CLJS.F0=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);
$CLJS.kga=new $CLJS.M(null,"database-id","database-id",1883826326);$CLJS.G0=new $CLJS.M(null,"metadata-fn","metadata-fn",915781389);$CLJS.H0=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);lga=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);mga=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.nga=new $CLJS.M(null,"dataset","dataset",1159262238);oga=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.I0=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.J0=new $CLJS.M(null,"definition","definition",-1198729982);pga=new $CLJS.M(null,"state","state",-1988618099);$CLJS.K0=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);$CLJS.L0=new $CLJS.M("source","joins","source/joins",1225821486);$CLJS.M0=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);
$CLJS.N0=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);$CLJS.O0=new $CLJS.M(null,"selected?","selected?",-742502788);P0=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.Q0=new $CLJS.M("source","card","source/card",-139977973);$CLJS.R0=new $CLJS.M(null,"active","active",1895962068);$CLJS.qga=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.rga=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
$CLJS.S0=new $CLJS.M("source","fields","source/fields",-649667981);sga=new $CLJS.M("metabase.lib.metadata","persisted-info","metabase.lib.metadata/persisted-info",1906179826);tga=new $CLJS.M("metabase.lib.metadata","column.remapping.external","metabase.lib.metadata/column.remapping.external",-228294930);$CLJS.T0=new $CLJS.M("source","native","source/native",-1444604147);uga=new $CLJS.M("metabase.lib.metadata","column.remapping.internal","metabase.lib.metadata/column.remapping.internal",-716967804);
vga=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);wga=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);U0=new $CLJS.M("metabase.lib.metadata","persisted-info.definition","metabase.lib.metadata/persisted-info.definition",1100108182);xga=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.V0=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.X(new $CLJS.M("metabase.lib.metadata","column-source","metabase.lib.metadata/column-source",-458963769),new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.jt,$CLJS.Q0,$CLJS.T0,$CLJS.F0,$CLJS.H0,$CLJS.S0,$CLJS.H_,$CLJS.E_,$CLJS.L0,$CLJS.I0,$CLJS.K0],null));
$CLJS.X(tga,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,xga],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.XO],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.wI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vZ,$CLJS.EI],null)],null));
$CLJS.X(uga,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,vga],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.XO],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.wI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,$CLJS.pi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[lga,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,$CLJS.pi],null)],null)],null));
$CLJS.X(U0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[P0,$CLJS.wI],null),new $CLJS.P(null,2,5,$CLJS.Q,[oga,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[mga,$CLJS.wI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,$CLJS.FI],null)],null)],null)],null)],null)],null));
$CLJS.X(sga,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.R0,$CLJS.Ct],null),new $CLJS.P(null,2,5,$CLJS.Q,[pga,$CLJS.wI],null),new $CLJS.P(null,2,5,$CLJS.Q,[P0,$CLJS.wI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.J0,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,U0],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XP,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,
$CLJS.wI],null)],null)],null));$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.hk,$CLJS.Ai,$CLJS.Ui,$CLJS.lj,$CLJS.rk,$CLJS.zi,$CLJS.T,$CLJS.gk],[jga,wga,"metabase/lib/metadata/protocols.cljc",25,1,74,74,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Vq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.B0)?$CLJS.B0.H:null]);