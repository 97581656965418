var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var BZ,CZ,efa,GZ,HZ,gfa,IZ,JZ,LZ,MZ,NZ,OZ,PZ,RZ,SZ,TZ,UZ,hfa,XZ,YZ,ffa;
BZ=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bf,a],null)],null);for(var c=$CLJS.Bf;;){var d=$CLJS.sd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),h=$CLJS.I(e,1,null),l=e,m=$CLJS.td(a);a=function(t,u,v,x,A,D,H,K){return function(U){return $CLJS.eg.j(v,$CLJS.ff.h(function(Z,ca,ra,Ca,hb){return function(Ob){var Tb=$CLJS.I(Ob,0,null);Ob=$CLJS.I(Ob,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ae.g(hb,Tb),Ob],null)}}(t,u,v,x,A,D,H,K)),U)}}(a,c,m,e,f,h,l,d);
$CLJS.n(b.h?b.h(h):b.call(null,h))?(a=m,c=$CLJS.ae.g(c,l)):a=$CLJS.yd(h)?a(h):$CLJS.xd(h)?a($CLJS.am($CLJS.Ft,h)):m}else return c}};CZ=function(a,b,c){return $CLJS.Ad(c)&&$CLJS.F.g(a,$CLJS.B(c))&&!$CLJS.Id(b,$CLJS.J.g(c,2))};$CLJS.DZ=function(a){var b=$CLJS.eg.j($CLJS.eh,$CLJS.ff.h($CLJS.Hk.g($CLJS.eJ,$CLJS.jd)),$CLJS.MT.h(a));return BZ($CLJS.Ak.l(a,$CLJS.nR,$CLJS.G([$CLJS.bZ])),function(c){return CZ($CLJS.zE,b,c)})};
$CLJS.EZ=function(a){var b=$CLJS.eg.j($CLJS.eh,$CLJS.ff.h($CLJS.Hk.g($CLJS.CI,$CLJS.jd)),$CLJS.bK.h(a));return BZ($CLJS.Ak.l(a,$CLJS.nR,$CLJS.G([$CLJS.bZ])),function(c){return CZ($CLJS.bK,b,c)})};efa=function(a){function b(d){return $CLJS.gf(c,$CLJS.G([$CLJS.nR.h(d)]))}function c(d){return $CLJS.de($CLJS.aJ.h(d),$CLJS.gf(b,$CLJS.G([$CLJS.hP.h(d)])))}return $CLJS.n($CLJS.FZ.h(a))?$CLJS.Pe(!0):$CLJS.ih(b(a))};
GZ=function(a){if($CLJS.xd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.z(a),d=$CLJS.B(c),e=$CLJS.C(c),f=b,h=0,l=a;;){var m=f,t=h,u=l,v=$CLJS.z(u),x=$CLJS.B(v),A=$CLJS.C(v),D=x,H=A,K=$CLJS.WD(m,efa(D));f=$CLJS.B($CLJS.z($CLJS.jf($CLJS.Ua,function(U,Z,ca,ra,Ca,hb){return function dc(Tb,zc){try{if($CLJS.Ad(zc)&&3===$CLJS.E(zc))try{var Ac=$CLJS.cd(zc,0);if($CLJS.ge(Ac,$CLJS.mK))try{var wb=$CLJS.cd(zc,1);if($CLJS.n($CLJS.gT.h(wb)))try{var Ib=$CLJS.cd(zc,1);if(null!=Ib?Ib.C&256||$CLJS.r===Ib.We||(Ib.C?0:$CLJS.Xa($CLJS.sb,
Ib)):$CLJS.Xa($CLJS.sb,Ib))try{var dd=$CLJS.J.j(Ib,$CLJS.gT,$CLJS.wZ);if($CLJS.n($CLJS.Oe(ra)(dd))){var ri=$CLJS.J.g(Ib,$CLJS.gT);$CLJS.cd(zc,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(hb),": no join named ",$CLJS.Ph.l($CLJS.G([ri]))].join("")],null)}throw $CLJS.Y;}catch(Se){if(Se instanceof Error){var rf=Se;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;}throw Se;}else throw $CLJS.Y;}catch(Se){if(Se instanceof Error){rf=Se;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;
}throw Se;}else throw $CLJS.Y;}catch(Se){if(Se instanceof Error){rf=Se;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;}throw Se;}else throw $CLJS.Y;}catch(Se){if(Se instanceof Error){rf=Se;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;}throw Se;}else throw $CLJS.Y;}catch(Se){if(Se instanceof Error){rf=Se;if(rf===$CLJS.Y)return $CLJS.qZ(dc,Tb,zc);throw rf;}throw Se;}}}(f,h,l,K,m,t,u,v,x,A,D,H,b,0,a,a,c,d,e,d,e)($CLJS.Bf,$CLJS.Ak.l(D,$CLJS.nR,$CLJS.G([ffa]))))));if($CLJS.n(f))return f;if($CLJS.z(H))h=t+1,f=K,l=H;
else return null}else return null};HZ=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);gfa=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);IZ=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);JZ=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);
$CLJS.KZ=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);LZ=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);MZ=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);NZ=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);
OZ=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);PZ=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);$CLJS.QZ=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);RZ=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);SZ=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);
TZ=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);UZ=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.VZ=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.FZ=new $CLJS.M(null,"source-card","source-card",-1580820390);hfa=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.WZ=new $CLJS.M(null,"filters","filters",974726919);
XZ=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);YZ=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);$CLJS.ZZ=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);ffa=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(NZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.QZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WT,$CLJS.Ua],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wt,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,$CLJS.Al],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YR,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.wI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sU,new $CLJS.k(null,1,[$CLJS.It,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TY],null)],null)],null));$CLJS.X(HZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.k(null,1,[$CLJS.Un,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qK],null)],null));$CLJS.X(SZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.k(null,1,[$CLJS.Un,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qK],null)],null));
$CLJS.X(TZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.NJ],null),$CLJS.dk,$CLJS.Vj],null)],null));$CLJS.X(RZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.k(null,1,[$CLJS.Un,1],null),TZ],null));
var $Z=$CLJS.WD(function(a){a=$CLJS.B($CLJS.DZ(a));return $CLJS.n(a)?["Invalid :expression reference: no expression named ",$CLJS.Ph.l($CLJS.G([$CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null))]))].join(""):null},function(a){a=$CLJS.B($CLJS.EZ(a));return $CLJS.n(a)?["Invalid :aggregation reference: no aggregation with uuid ",$CLJS.p.h($CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null)))].join(""):null});
$CLJS.X(UZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,2,[$CLJS.zu,"Valid references for a single query stage",$CLJS.Bu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Bj);return $Z(a)}],null),$CLJS.Oe($Z)],null));
$CLJS.X($CLJS.ZZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.KZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nR,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MT,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.tS,new $CLJS.k(null,1,[$CLJS.It,!0],null),HZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XI,new $CLJS.k(null,1,[$CLJS.It,!0],null),SZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WZ,new $CLJS.k(null,1,[$CLJS.It,!0],null),RZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CV,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mP],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SS,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WO],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FZ,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YO],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Id(a,$CLJS.vU)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,
new $CLJS.k(null,1,[$CLJS.zu,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.vP($CLJS.SS,$CLJS.FZ))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UZ],null)],null));$CLJS.X(IZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZZ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WO],null)],null)],null)],null));
$CLJS.X(JZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZZ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YO],null)],null)],null)],null));$CLJS.X(MZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IZ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null));
$CLJS.X(OZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.WD($CLJS.SS,$CLJS.FZ))],null)],null));$CLJS.X(PZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jt,$CLJS.QZ,$CLJS.KZ],null));
$CLJS.X(gfa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,PZ],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,1,[$CLJS.Pi,$CLJS.Ev],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZZ],null)],null)],null)],null));
$CLJS.X(YZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,PZ],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,1,[$CLJS.Pi,$CLJS.Ev],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MZ],null)],null)],null)],null));$CLJS.X(LZ,OZ);
$CLJS.X(XZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,2,[$CLJS.zu,"Valid references for all query stages",$CLJS.Bu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Bj);return GZ.h?GZ.h(a):GZ.call(null,a)}],null),$CLJS.Oe(GZ)],null));
$CLJS.X($CLJS.dP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LZ],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XZ],null)],null));
$CLJS.X(hfa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.VZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,$CLJS.ZO,$CLJS.$O],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dP],null)],null)],null),$CLJS.Yea],null));