var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var PK,RK,SK,VK,XK,YK,ZK,$K,aL,bL,cL,dL,eL,fL,gL,iL,jL,aaa;$CLJS.OK=new $CLJS.M(null,"supported-field","supported-field",-2061545519);PK=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.QK=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);RK=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);SK=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);
$CLJS.TK=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.UK=new $CLJS.M(null,"column-name","column-name",551523580);VK=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.WK=new $CLJS.M(null,"display-info","display-info",-816930907);XK=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);YK=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
ZK=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);$K=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);aL=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);bL=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);cL=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);dL=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
eL=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);fL=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);gL=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.hL=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);iL=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);jL=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.kL=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);aaa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.HJ($CLJS.TA,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cJ],null)],null)],null)],null)]));$CLJS.HJ($CLJS.nK,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cJ],null)],null)],null)],null)]));
$CLJS.FJ($CLJS.gK,$CLJS.G([$CLJS.pu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));$CLJS.FJ($CLJS.TJ,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cJ],null)],null)]));$CLJS.FJ($CLJS.vK,$CLJS.G([$CLJS.pu,$CLJS.uj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null)],null)]));
$CLJS.FJ($CLJS.ek,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null)],null)]));$CLJS.yJ($CLJS.ek,$CLJS.jJ);$CLJS.FJ($CLJS.AK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));$CLJS.yJ($CLJS.AK,$CLJS.jJ);$CLJS.FJ($CLJS.Un,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null)],null)]));$CLJS.yJ($CLJS.Un,$CLJS.jJ);
$CLJS.X($K,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,new $CLJS.k(null,1,[$CLJS.zu,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.FJ($CLJS.ZJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$K],null)]));$CLJS.yJ($CLJS.ZJ,$CLJS.jJ);
$CLJS.FJ($CLJS.tK,$CLJS.G([$CLJS.pu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null)],null)]));$CLJS.FJ($CLJS.DK,$CLJS.G([$CLJS.pu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));$CLJS.FJ($CLJS.zK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));
$CLJS.FJ($CLJS.JK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));$CLJS.yJ($CLJS.zK,$CLJS.jJ);$CLJS.yJ($CLJS.JK,$CLJS.jJ);$CLJS.FJ($CLJS.HK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null)],null)]));$CLJS.yJ($CLJS.HK,$CLJS.jJ);
$CLJS.FJ($CLJS.UJ,$CLJS.G([$CLJS.pu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)]));$CLJS.X(jL,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.mt,iL,fL,YK,RK,PK,XK,ZK,aL,bL,eL,cL,aaa,gL,SK,dL,$CLJS.Al],null));$CLJS.X($CLJS.QK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.k(null,1,[$CLJS.Un,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jL],null)],null));
$CLJS.lL=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.k(null,4,[$CLJS.fK,$CLJS.TA,$CLJS.TK,!1,$CLJS.hL,$CLJS.KJ,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Count of rows"),$CLJS.UK,$CLJS.MI("Count"),$CLJS.hv,$CLJS.MI("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.fK,$CLJS.zK,$CLJS.OK,$CLJS.bk,$CLJS.TK,!0,$CLJS.hL,$CLJS.KJ,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Sum of ..."),$CLJS.UK,$CLJS.MI("Sum"),$CLJS.hv,$CLJS.MI("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.fK,$CLJS.gK,$CLJS.OK,$CLJS.bk,$CLJS.TK,!0,$CLJS.hL,$CLJS.KJ,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Average of ..."),$CLJS.UK,$CLJS.MI("Average"),$CLJS.hv,$CLJS.MI("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.fK,$CLJS.AK,$CLJS.OK,$CLJS.bk,$CLJS.TK,!0,$CLJS.hL,$CLJS.iK,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Median of ..."),$CLJS.UK,$CLJS.MI("Median"),$CLJS.hv,$CLJS.MI("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.fK,$CLJS.TJ,$CLJS.OK,$CLJS.pi,$CLJS.TK,!0,$CLJS.hL,$CLJS.KJ,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Number of distinct values of ..."),$CLJS.UK,$CLJS.MI("Distinct values"),$CLJS.hv,$CLJS.MI("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.fK,$CLJS.JK,$CLJS.OK,$CLJS.bk,$CLJS.TK,!0,$CLJS.hL,$CLJS.KJ,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Cumulative sum of ..."),
$CLJS.UK,$CLJS.MI("Sum"),$CLJS.hv,$CLJS.MI("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.fK,$CLJS.nK,$CLJS.TK,!1,$CLJS.hL,$CLJS.KJ,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Cumulative count of rows"),$CLJS.UK,$CLJS.MI("Count"),$CLJS.hv,$CLJS.MI("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.fK,$CLJS.DK,$CLJS.OK,$CLJS.bk,$CLJS.TK,
!0,$CLJS.hL,$CLJS.wK,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Standard deviation of ..."),$CLJS.UK,$CLJS.MI("SD"),$CLJS.hv,$CLJS.MI("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.fK,$CLJS.Un,$CLJS.OK,$CLJS.Xi,$CLJS.TK,!0,$CLJS.hL,$CLJS.KJ,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Minimum of ..."),$CLJS.UK,$CLJS.MI("Min"),$CLJS.hv,$CLJS.MI("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.fK,$CLJS.ek,$CLJS.OK,$CLJS.Xi,$CLJS.TK,!0,$CLJS.hL,$CLJS.KJ,$CLJS.WK,function(){return new $CLJS.k(null,3,[$CLJS.rI,$CLJS.MI("Maximum of ..."),$CLJS.UK,$CLJS.MI("Max"),$CLJS.hv,$CLJS.MI("Maximum value of a column")],null)}],null)],null);
$CLJS.X(VK,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.kL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fK,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jt],null),$CLJS.ff.h($CLJS.fK),$CLJS.lL)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OK,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Wi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TK,$CLJS.Ct],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.hL,$CLJS.Wi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WK,$CLJS.od],null)],null));