var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.domain_entities.converters.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Dla,Fla,Gla,Hla,Ila,Kla;Dla=function(a,b){return $CLJS.cE(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};Fla=function(a){return $CLJS.eg.j($CLJS.eh,$CLJS.Hk.g($CLJS.gv(function(b){return $CLJS.gZ(b,a)}),$CLJS.ff.h($CLJS.jd)),Ela)};Gla=function(a){return new $CLJS.k(null,3,[$CLJS.kj,$CLJS.HE,$CLJS.S,a,$CLJS.Yi,$CLJS.p.h($CLJS.KI())],null)};
Hla=function(a){a=$CLJS.Ge(a);var b=$CLJS.J.g(a,$CLJS.S);return $CLJS.Zk.l($CLJS.G([a,function(){var c=$CLJS.qh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.l_(c)):c=null;return $CLJS.n(c)?new $CLJS.k(null,2,[$CLJS.kj,$CLJS.eR,$CLJS.RU,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.k(null,2,[$CLJS.kj,$CLJS.oQ,$CLJS.aQ,c],null):null}(),$CLJS.n($CLJS.rI.h(a))?null:new $CLJS.k(null,
1,[$CLJS.rI,$CLJS.V1.g($CLJS.U1,b)],null)]))};$CLJS.F8=function(a){a=$CLJS.Y0($CLJS.C0(a));return $CLJS.n($CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,Ila],null)))?$CLJS.ae.g($CLJS.eh,$CLJS.YR):$CLJS.eh};$CLJS.G8=function(a,b){var c=$CLJS.F8(a);return(0,$CLJS.I_)(a,0,function(d){var e=$CLJS.Pv.g(Jla,c);d=$CLJS.O.j($CLJS.Ak,d,e);d=$CLJS.Zk.l($CLJS.G([d,$CLJS.bh(b,c)]));$CLJS.Pv.g(c,$CLJS.ih($CLJS.Xg(b)));$CLJS.y0(a,0);return d})};
Ila=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);Kla=new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077);var H8,Ela,Jla;
H8=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rt,$CLJS.Vj,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jt,$CLJS.HE,$CLJS.oQ,$CLJS.eR],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.Vj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.wI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rI,new $CLJS.k(null,2,[$CLJS.gF,"display-name",$CLJS.It,!0],null),$CLJS.wI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aQ,new $CLJS.k(null,2,[$CLJS.gF,
"snippet-name",$CLJS.It,!0],null),$CLJS.wI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RU,new $CLJS.k(null,2,[$CLJS.gF,"card-id",$CLJS.It,!0],null),$CLJS.yt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RP,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.pi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AR,new $CLJS.k(null,2,[$CLJS.gF,"widget-type",$CLJS.It,!0],null),$CLJS.Vj],null)],null)],null);
Ela=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.I8=function(){function a(d,e){d=$CLJS.He(Fla(d));var f=$CLJS.He($CLJS.ih($CLJS.Xg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var h=$CLJS.Pv.g(d,f);d=$CLJS.Pv.g(f,d);if($CLJS.F.l(1,$CLJS.E(h),$CLJS.G([$CLJS.E(d)]))){d=$CLJS.B(d);h=$CLJS.B(h);f=$CLJS.J.g(e,d);var l=$CLJS.F.g($CLJS.rI.h(f),$CLJS.V1.g($CLJS.U1,d))?$CLJS.V1.g($CLJS.U1,h):$CLJS.rI.h(f);f=$CLJS.R.l($CLJS.Ak.l(f,$CLJS.aQ,$CLJS.G([$CLJS.RU])),$CLJS.rI,l,$CLJS.G([$CLJS.S,h]));e=$CLJS.R.j($CLJS.Ak.g(e,d),h,f)}else e=$CLJS.Zk.l($CLJS.G([Dla($CLJS.Oe(d),
e),$CLJS.M_($CLJS.S,$CLJS.ff.g(Gla,h))]));e=$CLJS.Ku(e,Hla)}else e=$CLJS.N;return e}function b(d){return $CLJS.I8.g?$CLJS.I8.g(d,null):$CLJS.I8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.Lla=$CLJS.XE(H8);$CLJS.Mla=$CLJS.WE(H8);Jla=new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.YR,null],null),null);
$CLJS.X(Kla,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YR,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.wI],null)],null));
$CLJS.J8=function(){function a(d,e,f,h){var l=$CLJS.I8.h(e);return $CLJS.G8($CLJS.R1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.f_(new $CLJS.k(null,4,[$CLJS.Ev,$CLJS.QZ,$CLJS.bZ,f,$CLJS.sU,l,$CLJS.WT,e],null))],null)),h)}function b(d,e){return $CLJS.J8.v?$CLJS.J8.v(d,e,null,null):$CLJS.J8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,h){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,h)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.K8=function(){function a(d,e,f){$CLJS.y0(d,0);return $CLJS.G8($CLJS.R1.g(e,$CLJS.hP.h(d)),f)}function b(d,e){return $CLJS.K8.j?$CLJS.K8.j(d,e,null):$CLJS.K8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();