var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var yga,W0,zga,Z0,$0,o1,Aga,Bga,s1,h1,u1,Cga,j1;yga=function(a){return $CLJS.z(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};W0=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Id($CLJS.RE,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
zga=function(a){var b=$CLJS.Ge(new $CLJS.k(null,1,[$CLJS.FE,!0],null)),c=$CLJS.J.g(b,$CLJS.DE),d=$CLJS.J.g(b,$CLJS.FE);return $CLJS.z(a)?(b=$CLJS.mZ(function(){return function h(f){return new $CLJS.me(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Bd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);x=W0(x,$CLJS.Ta(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),h($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.B(l);return $CLJS.de(W0(u,
$CLJS.Ta(d)),h($CLJS.Lc(l)))}return null}},null,null)}(yga($CLJS.kE(a)))}()),$CLJS.n(c)?$CLJS.mZ($CLJS.Xe(c,b)):b):null};$CLJS.X0=function(a){return $CLJS.SS.h($CLJS.B($CLJS.hP.h(a)))};$CLJS.Y0=function(a){if(null!=a&&null!=a.Zf)a=a.Zf(a);else{var b=$CLJS.Y0[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=$CLJS.Y0._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Za("MetadataProvider.database",a);}return a};
Z0=function(a,b){if(null!=a&&null!=a.fg)a=a.fg(a,b);else{var c=Z0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Z0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.table",a);}return a};$0=function(a,b){if(null!=a&&null!=a.Yf)a=a.Yf(a,b);else{var c=$0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=$0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.card",a);}return a};
$CLJS.a1=function(a,b){return Z0($CLJS.C0(a),b)};$CLJS.b1=function(a,b){return $0($CLJS.C0(a),b)};
o1=function(a,b,c){var d=$CLJS.c1.j(a,b,c);return $CLJS.Zk.l($CLJS.G([$CLJS.bh(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,$CLJS.rI,$CLJS.Rj],null)),function(){var e=$CLJS.d1.v(a,b,c,$CLJS.e1);return $CLJS.n(e)?new $CLJS.k(null,1,[$CLJS.f1,e],null):null}(),function(){var e=$CLJS.WD($CLJS.Hi,$CLJS.CE)(d);return $CLJS.n(e)?new $CLJS.k(null,1,[$CLJS.Hi,e],null):null}(),function(){var e=$CLJS.D_.h(d);return $CLJS.n(e)?new $CLJS.k(null,1,[$CLJS.F_,$CLJS.g1.j(a,b,$CLJS.Gd(e)?$CLJS.a1(a,e):"string"===typeof e?
$CLJS.b1(a,$CLJS.x_(e)):null)],null):null}(),function(){var e=$CLJS.A_.h(d);return $CLJS.n(e)?new $CLJS.k(null,4,[h1,$CLJS.F.g(e,$CLJS.F0),$CLJS.i1,$CLJS.F.g(e,$CLJS.L0),j1,$CLJS.F.g(e,$CLJS.I0),$CLJS.k1,$CLJS.F.g(e,$CLJS.K0)],null):null}(),function(){var e=$CLJS.O0.h(d);return null==e?null:new $CLJS.k(null,1,[$CLJS.l1,e],null)}(),$CLJS.bh(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.m1,$CLJS.n1],null))]))};$CLJS.p1=new $CLJS.M(null,"direction","direction",-633359395);
$CLJS.k1=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.m1=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.q1=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.l1=new $CLJS.M(null,"selected","selected",574897764);Aga=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);
Bga=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.r1=new $CLJS.M(null,"stage-number","stage-number",-1752729638);s1=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.t1=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);h1=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);
u1=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);$CLJS.v1=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.i1=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.w1=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.n1=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);Cga=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.f1=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.e1=new $CLJS.M(null,"long","long",-171452093);$CLJS.x1=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.y1=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);j1=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.z1=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","display-name-method"),function(f,h,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.A1=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","column-name-method"),function(f,h,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.d1=function(){function a(f,h,l,m){var t=$CLJS.rI.h($CLJS.JI(l));if($CLJS.n(t))return t;try{return $CLJS.z1.v(f,h,l,m)}catch(u){if(u instanceof Error)throw h=u,$CLJS.ki($CLJS.LI("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.Ph.l($CLJS.G([l])),$CLJS.iZ(h)])),new $CLJS.k(null,2,[$CLJS.qQ,f,$CLJS.DI,l],null),h);throw u;}}function b(f,h,l){return e.v?e.v(f,h,l,$CLJS.gi):e.call(null,f,h,l,$CLJS.gi)}function c(f,h){return e.j?e.j(f,-1,h):e.call(null,f,-1,h)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,h,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,h);case 3:return b.call(this,f,h,l);case 4:return a.call(this,f,h,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.B1=function(){function a(d,e,f){var h=$CLJS.S.h($CLJS.JI(f));if($CLJS.n(h))return h;try{return $CLJS.A1.j(d,e,f)}catch(l){if(l instanceof Error)throw h=l,$CLJS.ki($CLJS.LI("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.Ph.l($CLJS.G([f])),$CLJS.iZ(h)])),new $CLJS.k(null,3,[$CLJS.DI,f,$CLJS.qQ,d,$CLJS.r1,e],null),h);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.z1.m(null,$CLJS.gi,function(a,b,c){a=$CLJS.iE($CLJS.oC);$CLJS.n($CLJS.hE("metabase.lib.metadata.calculation",a))&&$CLJS.gE("metabase.lib.metadata.calculation",a,$CLJS.m_("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.Ph.l($CLJS.G([c])),Bga,$CLJS.AF(c)])),null);return $CLJS.Ad(c)&&$CLJS.B(c)instanceof $CLJS.M?$CLJS.jh($CLJS.B(c)):$CLJS.Ph.l($CLJS.G([c]))});
$CLJS.A1.m(null,$CLJS.gi,function(a,b,c){return zga($CLJS.aE($CLJS.d1.j(a,b,c),/[\(\)]/,""))});$CLJS.C1=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,h,l){return l},e,a,b,c,d)}();
$CLJS.D1=function(){function a(d,e,f){return $CLJS.C1.j(d,e,$CLJS.Ch.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.E1=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","type-of-method"),function(f,h,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.F1=function(){function a(d,e,f){var h=$CLJS.JI(f);h=$CLJS.Ge(h);var l=$CLJS.J.g(h,$CLJS.sK),m=$CLJS.Hi.h(h);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Id($CLJS.JL,l):l)?$CLJS.uj:null;if($CLJS.n(l))return l;h=$CLJS.CE.h(h);if($CLJS.n(h))return h;d=$CLJS.E1.j(d,e,f);return $CLJS.PF(d,$CLJS.yj)?d:$CLJS.yj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.E1.m(null,$CLJS.gi,function(a,b,c){return $CLJS.PI(c)});$CLJS.E1.m(null,$CLJS.jJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.F1.j(a,b,c)});$CLJS.E1.m(null,$CLJS.gN,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.PI(c):$CLJS.F1.j(a,b,d)});
$CLJS.G1=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","metadata-method"),function(f,h,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.G1.m(null,$CLJS.gi,function(a,b,c){try{return new $CLJS.k(null,4,[$CLJS.Ev,$CLJS.h_,$CLJS.CE,$CLJS.F1.j(a,b,c),$CLJS.S,$CLJS.B1.j(a,b,c),$CLJS.rI,$CLJS.d1.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.ki($CLJS.LI("Error calculating metadata for {0}: {1}",$CLJS.G([$CLJS.Ph.l($CLJS.G([$CLJS.AF(c)])),$CLJS.iZ(d)])),new $CLJS.k(null,3,[$CLJS.qQ,a,$CLJS.r1,b,$CLJS.DI,c],null),d);}throw e;}});
$CLJS.c1=function(){function a(e,f,h){return $CLJS.G1.j(e,f,h)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,h){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,h)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.H1=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","display-info-method"),function(f,h,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.X(s1,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rI,$CLJS.Vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.f1,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.F_,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,s1],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[h1,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.i1,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[j1,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.k1,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[u1,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Cga,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Aga,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UK,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Vj],null),
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hv,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.x1,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.w1,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.l1,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.p1,
new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jt,$CLJS.jK,$CLJS.cK],null)],null)],null));
$CLJS.g1=function(){function a(d,e,f){try{return $CLJS.H1.j(d,e,f)}catch(l){if(l instanceof Error){var h=l;throw $CLJS.ki($CLJS.LI("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.AF(f),$CLJS.iZ(h)])),new $CLJS.k(null,3,[$CLJS.qQ,d,$CLJS.r1,e,$CLJS.DI,f],null),h);}throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.H1.m(null,$CLJS.gi,function(a,b,c){return o1(a,b,c)});$CLJS.H1.m(null,$CLJS.C_,function(a,b,c){return $CLJS.Zk.l($CLJS.G([o1(a,b,c),new $CLJS.k(null,1,[u1,$CLJS.F.g($CLJS.X0(a),$CLJS.Yi.h(c))],null)]))});
$CLJS.I1=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,h,l){return $CLJS.AF(l)},e,a,b,c,d)}();$CLJS.I1.m(null,$CLJS.GE,function(){return $CLJS.Bf});
$CLJS.J1=function(){function a(f,h,l,m){m=$CLJS.Zk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.v1,$CLJS.z_()],null),m]));return $CLJS.I1.v(f,h,l,m)}function b(f,h,l){return e.v?e.v(f,h,l,null):e.call(null,f,h,l,null)}function c(f,h){return e.j?e.j(f,-1,h):e.call(null,f,-1,h)}function d(f){var h=$CLJS.y0(f,-1);return e.g?e.g(f,h):e.call(null,f,h)}var e=null;e=function(f,h,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,h);case 3:return b.call(this,f,h,l);case 4:return a.call(this,
f,h,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.K1=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,h,l){return $CLJS.AF(l)},e,a,b,c,d)}();$CLJS.K1.m(null,$CLJS.GE,function(){return $CLJS.Bf});
$CLJS.K1.m(null,$CLJS.gi,function(a,b,c,d){return $CLJS.I1.v(a,b,c,d)});
$CLJS.L1=function(){function a(f,h,l,m){m=$CLJS.Zk.l($CLJS.G([$CLJS.Zk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.v1,$CLJS.z_()],null),new $CLJS.k(null,3,[$CLJS.t1,!0,$CLJS.y1,!0,$CLJS.q1,!0],null)])),m]));return $CLJS.K1.v(f,h,l,m)}function b(f,h,l){return e.v?e.v(f,h,l,null):e.call(null,f,h,l,null)}function c(f,h){return e.j?e.j(f,-1,h):e.call(null,f,-1,h)}function d(f){var h=$CLJS.y0(f,-1);return e.g?e.g(f,h):e.call(null,f,h)}var e=null;e=function(f,h,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,h);case 3:return b.call(this,f,h,l);case 4:return a.call(this,f,h,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.d1};