var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var zka,E6,F6,Aka,Bka,Cka,Dka,K6,L6,N6,O6,Eka,Q6,R6,Fka,Gka;$CLJS.B6=function(a,b){a=$CLJS.w_(a,b);return 0<a?a-1:null};zka=function(a,b){b=$CLJS.b1(a,b);return $CLJS.n(b)?$CLJS.Z1(a,b):null};$CLJS.C6=function(a,b){return $CLJS.d4(null,a,b)};$CLJS.D6=function(a){return $CLJS.N5.h(a)};E6=function(a,b){return $CLJS.F.g($CLJS.qI.h(a),$CLJS.bh(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HI,$CLJS.pI,$CLJS.GI],null)))};
F6=function(a){var b=new $CLJS.k(null,3,[$CLJS.CI,$CLJS.p.h($CLJS.KI()),$CLJS.CE,$CLJS.CE.h(a),$CLJS.Hi,$CLJS.WD($CLJS.Hi,$CLJS.CE)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,b,$CLJS.S.h(a)],null)};Aka=function(a){return $CLJS.O1(a,new $CLJS.k(null,1,[$CLJS.HI,$CLJS.Ch],null))};
Bka=function(a,b,c){var d=$CLJS.Zk.l;b=$CLJS.Ta($CLJS.B6(a,b))?function(){var f=$CLJS.FZ.h($CLJS.B($CLJS.hP.h(a)));return $CLJS.n(f)?(f=zka(a,f),$CLJS.n(f)?$CLJS.ML(function(h){return $CLJS.F.g($CLJS.Yi.h(h),c)},f):null):null}():null;try{var e=$CLJS.D0(a,c)}catch(f){e=null}return d.call($CLJS.Zk,$CLJS.G([b,e]))};
Cka=function(a,b){var c=$CLJS.ML(function(d){return $CLJS.F.g($CLJS.M0.h(d),a)},b);if($CLJS.n(c))return c;c=$CLJS.ML(function(d){return $CLJS.F.g($CLJS.S.h(d),a)},b);if($CLJS.n(c))return c;c=$CLJS.iE($CLJS.oC);$CLJS.n($CLJS.hE("metabase.lib.field",c))&&(b=$CLJS.LI("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.Ph.l($CLJS.G([a])),$CLJS.Ph.l($CLJS.G([$CLJS.Jk.g($CLJS.M0,b)]))])),b instanceof Error?$CLJS.gE("metabase.lib.field",c,$CLJS.vy(),b):$CLJS.gE("metabase.lib.field",
c,$CLJS.vy.l($CLJS.G([b])),null));return null};
Dka=function(a,b,c){if($CLJS.n(G6))return null;var d=G6;G6=!0;try{var e=$CLJS.B6(a,b),f=$CLJS.n(e)?$CLJS.y0(a,e):$CLJS.y0(a,b),h=function(){var m=$CLJS.H6.h(f);if($CLJS.n(m))return m;m=$CLJS.XD(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bZ,$CLJS.u_],null));if($CLJS.n(m))return m;m=$CLJS.n($CLJS.FZ.h(f))?$CLJS.L1.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.iE($CLJS.oC);if($CLJS.n($CLJS.hE("metabase.lib.field",m))){var t=$CLJS.LI("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.Ph.l($CLJS.G([c]))]));
return t instanceof Error?$CLJS.gE("metabase.lib.field",m,$CLJS.vy(),t):$CLJS.gE("metabase.lib.field",m,$CLJS.vy.l($CLJS.G([t])),null)}return null}(),l=function(){var m=$CLJS.z(h);return m?Cka(c,h):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.j4($CLJS.Ak.l(l,$CLJS.Yi,$CLJS.G([$CLJS.D_,$CLJS.I6,$CLJS.J6])),null),$CLJS.S,function(){var m=$CLJS.M0.h(l);return $CLJS.n(m)?m:$CLJS.S.h(l)}()),$CLJS.A_,$CLJS.F0):l:null}finally{G6=d}};
K6=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.Ge(d);d=$CLJS.J.g(e,$CLJS.gT);var f=$CLJS.I(c,2,null);c=$CLJS.Zk.l($CLJS.G([function(){var h=$CLJS.CE.h(e);return $CLJS.n(h)?new $CLJS.k(null,1,[$CLJS.CE,h],null):null}(),function(){var h=$CLJS.WD($CLJS.Hi,$CLJS.CE)(e);return $CLJS.n(h)?new $CLJS.k(null,1,[$CLJS.Hi,h],null):null}(),function(){var h=$CLJS.jV.h(e);return $CLJS.n(h)?new $CLJS.k(null,1,[$CLJS.I6,h],null):null}(),function(){var h=$CLJS.sK.h(e);return $CLJS.n(h)?new $CLJS.k(null,
1,[$CLJS.J6,h],null):null}(),$CLJS.Gd(f)?Bka(a,b,f):$CLJS.n(d)?new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.h_,$CLJS.S,f],null):function(){var h=Dka(a,b,f);return $CLJS.n(h)?h:new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.h_,$CLJS.S,f],null)}()]));return $CLJS.n(d)?$CLJS.j4(c,d):c};L6=function(a){a=$CLJS.Ge(a);var b=$CLJS.J.g(a,$CLJS.J6);return $CLJS.n($CLJS.n(b)?$CLJS.Id($CLJS.JL,b):b)?$CLJS.uj:$CLJS.WD($CLJS.Hi,$CLJS.CE)(a)};
$CLJS.M6=function(a,b){return"string"===typeof b?$CLJS.b1(a,$CLJS.x_(b)):$CLJS.Gd(b)?$CLJS.a1(a,b):null};N6=function(a,b,c){return $CLJS.n($CLJS.Ne(function(d){return $CLJS.F.g($CLJS.xi.h(d),c)},a))?$CLJS.Jk.g(function(d){var e=$CLJS.Ak.g(d,b);return $CLJS.F.g($CLJS.xi.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
O6=function(a){var b=function(){var d=$CLJS.A_.h(a),e=new $CLJS.dh(null,new $CLJS.k(null,3,[$CLJS.Q0,null,$CLJS.T0,null,$CLJS.F0,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.Zk.l($CLJS.G([new $CLJS.k(null,3,[$CLJS.CI,$CLJS.p.h($CLJS.KI()),$CLJS.CE,$CLJS.CE.h(a),$CLJS.Hi,L6(a)],null),function(){var d=$CLJS.i4(a);return $CLJS.n(d)?new $CLJS.k(null,1,[$CLJS.gT,d],null):null}(),function(){var d=$CLJS.J6.h(a);return $CLJS.n(d)?new $CLJS.k(null,1,[$CLJS.sK,d],null):null}(),function(){var d=
$CLJS.I6.h(a);return $CLJS.n(d)?new $CLJS.k(null,1,[$CLJS.jV,d],null):null}(),function(){var d=$CLJS.tU.h(a);return $CLJS.n(d)?new $CLJS.k(null,1,[$CLJS.oR,d],null):null}()]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,c,$CLJS.n(b)?function(){var d=$CLJS.M0.h(a);return $CLJS.n(d)?d:$CLJS.S.h(a)}():function(){var d=$CLJS.Yi.h(a);return $CLJS.n(d)?d:$CLJS.S.h(a)}()],null)};
Eka=function(a,b){return function e(d){return new $CLJS.me(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Bd(f)){var h=$CLJS.kc(f),l=$CLJS.E(h),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(h,t);$CLJS.F.g($CLJS.A_.h(u),$CLJS.I0)&&(u=$CLJS.g_(u),m.add(u));t+=1}else{h=!0;break a}return h?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}m=$CLJS.B(f);if($CLJS.F.g($CLJS.A_.h(m),$CLJS.I0))return $CLJS.de($CLJS.g_(m),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}($CLJS.L1.v(a,
b,$CLJS.y0(a,b),new $CLJS.k(null,3,[$CLJS.t1,!1,$CLJS.y1,!0,$CLJS.q1,!1],null)))};$CLJS.J6=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.P6=new $CLJS.M(null,"stage","stage",1843544772);Q6=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.H6=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);
R6=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);Fka=new $CLJS.M(null,"earliest","earliest",-1928154382);$CLJS.I6=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);Gka=new $CLJS.M(null,"latest","latest",24323665);$CLJS.P1.m(null,$CLJS.mK,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ch.h(b),$CLJS.O1(c,new $CLJS.k(null,2,[$CLJS.sK,$CLJS.Ch,$CLJS.jV,Aka],null)),a],null)});var G6=!1,S6=function S6(a,b){var d=$CLJS.D0(a,Q6.h(b));a=$CLJS.n(Q6.h(d))?S6.g?S6.g(a,d):S6.call(null,a,d):d;a=$CLJS.Ge(a);var e=$CLJS.J.g(a,$CLJS.S);return $CLJS.Kk.j(b,$CLJS.S,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.E1.m(null,$CLJS.h_,function(a,b,c){return L6(c)});$CLJS.E1.m(null,$CLJS.mK,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.Ge(d);d=$CLJS.J.g(d,$CLJS.sK);$CLJS.I(c,2,null);c=K6(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.J6,d):c;return $CLJS.F1.j(a,b,c)});$CLJS.G1.m(null,$CLJS.h_,function(a,b,c){a=$CLJS.Ge(c);b=$CLJS.J.g(a,$CLJS.S);return $CLJS.R.j(a,$CLJS.S,b)});
$CLJS.G1.m(null,$CLJS.mK,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.Ge(d),f=$CLJS.J.g(e,$CLJS.CI),h=$CLJS.J.g(e,$CLJS.CE),l=$CLJS.J.g(e,$CLJS.jV),m=$CLJS.J.g(e,$CLJS.Hi);d=$CLJS.J.g(e,$CLJS.gT);var t=$CLJS.J.g(e,$CLJS.oR),u=$CLJS.J.g(e,$CLJS.sK),v=K6(a,b,c),x=$CLJS.Zk.l;f=new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.h_,$CLJS.h3,f],null);e=$CLJS.rI.h(e);b=$CLJS.n(e)?e:$CLJS.d1.j(a,b,c);b=x.call($CLJS.Zk,$CLJS.G([f,v,new $CLJS.k(null,1,[$CLJS.rI,b],null),$CLJS.n(m)?new $CLJS.k(null,1,[$CLJS.Hi,
m],null):null,$CLJS.n(h)?new $CLJS.k(null,1,[$CLJS.CE,h],null):null,$CLJS.n(u)?new $CLJS.k(null,1,[$CLJS.J6,u],null):null,$CLJS.n(l)?new $CLJS.k(null,1,[$CLJS.I6,l],null):null,$CLJS.n(t)?new $CLJS.k(null,1,[$CLJS.tU,t],null):null]));d=$CLJS.n(d)?$CLJS.j4(b,d):b;return $CLJS.n(Q6.h(d))?S6(a,d):d});
$CLJS.z1.m(null,$CLJS.h_,function(a,b,c,d){c=$CLJS.Ge(c);var e=$CLJS.J.g(c,$CLJS.rI),f=$CLJS.J.g(c,$CLJS.S),h=$CLJS.J.g(c,$CLJS.xi),l=$CLJS.J.g(c,$CLJS.I6),m=$CLJS.J.g(c,$CLJS.V0),t=$CLJS.J.g(c,$CLJS.tU),u=$CLJS.J.g(c,$CLJS.D_);e=$CLJS.n(e)?e:$CLJS.V1.g($CLJS.U1,f);$CLJS.F.g(d,$CLJS.e1)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.D0(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.aE($CLJS.rI.h($CLJS.g1.j(a,b,t)),$CLJS.J_,"")):(u=$CLJS.M6(a,u),a=$CLJS.d1.v(a,b,u,d))):a=null,m=$CLJS.n(a)?a:$CLJS.n(m)?m:$CLJS.i4(c)):
m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(h)?(c=$CLJS.lE($CLJS.aE($CLJS.jh(h),"-"," ")),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.K5(l,c),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):m});
$CLJS.z1.m(null,$CLJS.mK,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.Ge(e);e=$CLJS.J.g(f,$CLJS.jV);var h=$CLJS.J.g(f,$CLJS.gT),l=$CLJS.J.g(f,$CLJS.sK);f=$CLJS.J.g(f,$CLJS.oR);$CLJS.I(c,2,null);c=K6(a,b,c);c=$CLJS.n(h)?$CLJS.R.j(c,$CLJS.V0,h):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.xi,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.I6,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.tU,f):e;return $CLJS.n(e)?$CLJS.d1.v(a,b,e,d):$CLJS.MI("[Unknown Field]")});
$CLJS.A1.m(null,$CLJS.h_,function(a,b,c){a=$CLJS.Ge(c);return $CLJS.J.g(a,$CLJS.S)});$CLJS.A1.m(null,$CLJS.mK,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=K6(a,b,c);return $CLJS.n(c)?$CLJS.B1.j(a,b,c):"unknown_field"});
$CLJS.H1.m(null,$CLJS.h_,function(a,b,c){return $CLJS.Zk.l($CLJS.G([function(){var d=$CLJS.ei($CLJS.H1,$CLJS.gi);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.g($CLJS.A_.h(c),$CLJS.Q0)?function(){var d=$CLJS.Y1.h(c);return $CLJS.n(d)?(d=$CLJS.b1(a,d),$CLJS.n(d)?new $CLJS.k(null,1,[$CLJS.F_,new $CLJS.k(null,2,[$CLJS.S,$CLJS.S.h(d),$CLJS.rI,$CLJS.S.h(d)],null)],null):null):null}():null]))});$CLJS.k3.m(null,$CLJS.mK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.sK.h(b)});
$CLJS.k3.m(null,$CLJS.h_,function(a){return $CLJS.J6.h(a)});$CLJS.j3.m(null,$CLJS.mK,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Id($CLJS.JL,b),e=$CLJS.NF(R6,$CLJS.Hi,$CLJS.CE)(c);c=$CLJS.R.l(c,$CLJS.sK,b,$CLJS.G([$CLJS.Hi,d?$CLJS.uj:e,R6,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,c,a],null)}b=R6.h(c);c=$CLJS.n(b)?$CLJS.Ak.g($CLJS.R.j(c,$CLJS.Hi,b),R6):c;c=$CLJS.Ak.g(c,$CLJS.sK);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,c,a],null)});
$CLJS.j3.m(null,$CLJS.h_,function(a,b){return $CLJS.n(b)?$CLJS.R.j(a,$CLJS.J6,b):$CLJS.Ak.g(a,$CLJS.J6)});$CLJS.m3.m(null,$CLJS.mK,function(a,b,c){return $CLJS.n3.j(a,b,K6(a,b,c))});
$CLJS.m3.m(null,$CLJS.h_,function(a,b,c){if($CLJS.ah.g($CLJS.A_.h(c),$CLJS.I0)){a=$CLJS.WD($CLJS.Hi,$CLJS.CE)(c);b=null==c?null:$CLJS.CR.h(c);if(null==b)var d=null;else try{var e=$CLJS.dH.h($CLJS.kj.h(b));if($CLJS.n(e)){var f=$CLJS.Ge(e),h=$CLJS.J.g(f,Fka),l=$CLJS.J.g(f,Gka),m=$CLJS.yka.l($CLJS.G([$CLJS.A6.h(h),$CLJS.A6.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Ls.g?$CLJS.Ls.g(1,m):$CLJS.Ls.call(null,1,m))?$CLJS.Dv:$CLJS.n($CLJS.Ls.g?$CLJS.Ls.g(31,m):$CLJS.Ls.call(null,31,m))?$CLJS.Gv:$CLJS.n($CLJS.Ls.g?
$CLJS.Ls.g(365,m):$CLJS.Ls.call(null,365,m))?$CLJS.mj:$CLJS.vj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.PF(a,$CLJS.dH)?$CLJS.sha:$CLJS.PF(a,$CLJS.vG)?$CLJS.rha:$CLJS.PF(a,$CLJS.TH)?$CLJS.qha:$CLJS.Bf;d=$CLJS.n(d)?N6(e,$CLJS.gi,d):e;return $CLJS.n($CLJS.J6.h(c))?N6(d,$CLJS.l1,$CLJS.J6.h(c)):d}return $CLJS.Bf});
$CLJS.N5.m(null,$CLJS.mK,function(a){var b=null==a?null:$CLJS.JI(a);b=null==b?null:$CLJS.jV.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Ev,$CLJS.L5,$CLJS.G([$CLJS.G0,function(c,d){return K6(c,d,a)}]))});$CLJS.N5.m(null,$CLJS.h_,function(a){var b=null==a?null:$CLJS.I6.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Ev,$CLJS.L5,$CLJS.G([$CLJS.G0,$CLJS.Pe(a)]))});$CLJS.M5.m(null,$CLJS.mK,function(a,b){return $CLJS.d_(a,$CLJS.c_,$CLJS.G([$CLJS.jV,b]))});
$CLJS.M5.m(null,$CLJS.h_,function(a,b){return $CLJS.c_(a,$CLJS.I6,b)});$CLJS.O5.m(null,$CLJS.mK,function(a,b,c){return $CLJS.P5.j(a,b,K6(a,b,c))});
$CLJS.O5.m(null,$CLJS.h_,function(a,b,c){b=$CLJS.Ge(c);c=$CLJS.J.g(b,$CLJS.Hi);var d=$CLJS.J.g(b,$CLJS.CR),e=$CLJS.J.g(b,$CLJS.Rj);if($CLJS.ah.g($CLJS.A_.h(b),$CLJS.I0)){var f=function(){var m=null==a?null:$CLJS.Y0($CLJS.C0(a));m=null==m?null:$CLJS.Yu.h(m);return null==m?null:$CLJS.Id(m,$CLJS.jV)}(),h=$CLJS.XD(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,$CLJS.Lj],null)),l=$CLJS.D6(b);return function u(t){return new $CLJS.me(null,function(){for(;;){var v=$CLJS.z(t);if(v){if($CLJS.Bd(v)){var x=$CLJS.kc(v),
A=$CLJS.E(x),D=$CLJS.qe(A);return function(){for(var K=0;;)if(K<A){var U=$CLJS.md(x,K),Z=D,ca=U;U=E6(U,l)?$CLJS.R.j(ca,$CLJS.l1,!0):ca;Z.add(U);K+=1}else return!0}()?$CLJS.te($CLJS.ve(D),u($CLJS.lc(v))):$CLJS.te($CLJS.ve(D),null)}var H=$CLJS.B(v);return $CLJS.de(function(){var K=H;return E6(H,l)?$CLJS.R.j(K,$CLJS.l1,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Ta(function(){if($CLJS.n(f)){var t=$CLJS.Un.h(h);return $CLJS.n(t)?$CLJS.ek.h(h):t}return f}())?null:$CLJS.PF(e,$CLJS.wj)?$CLJS.q($CLJS.vja):
$CLJS.PF(c,$CLJS.Lj)&&!$CLJS.PF(e,$CLJS.si)?$CLJS.q($CLJS.uja):null)}return $CLJS.Bf});$CLJS.b_.m(null,$CLJS.mK,function(a){return a});
$CLJS.b_.m(null,$CLJS.h_,function(a){a=$CLJS.Ge(a);var b=$CLJS.J.g(a,$CLJS.A_);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.k(null,2,[$CLJS.CI,$CLJS.p.h($CLJS.KI()),$CLJS.Hi,$CLJS.WD($CLJS.Hi,$CLJS.CE)(a)],null),a=$CLJS.h3.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,b,a],null);case "source/expressions":return F6(a);case "source/breakouts":return $CLJS.Id(a,$CLJS.eJ)?F6(a):O6(a);default:return O6(a)}});
$CLJS.Hka=function(){function a(e,f,h){var l=$CLJS.He($CLJS.Jk.g($CLJS.g_,h));h=function(){return null==l?null:$CLJS.eg.j(l,$CLJS.Ik.h(function(m){return $CLJS.v2(m,l)}),Eka(e,f))}();return $CLJS.I_.l(e,f,$CLJS.c_,$CLJS.G([$CLJS.XI,h]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,h){return d.j?d.j(f,h,e):d.call(null,f,h,e)}}var d=null;d=function(e,f,h){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,
e,f,h)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.T6=function(){function a(d,e){return $CLJS.XI.h($CLJS.y0(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Ika=function(){function a(d,e){var f=$CLJS.L1.v(d,e,$CLJS.y0(d,e),new $CLJS.k(null,3,[$CLJS.t1,!1,$CLJS.y1,!1,$CLJS.q1,!1],null));d=$CLJS.T6.g(d,e);return $CLJS.ud(d)?$CLJS.Jk.g(function(h){return $CLJS.R.j(h,$CLJS.O0,!0)},f):$CLJS.C6(f,d)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Jka=function(){function a(d,e,f){var h=$CLJS.y0(d,e);e=$CLJS.L1.j(d,e,h);e=$CLJS.cw($CLJS.ff.g($CLJS.g_,e),e);d=$CLJS.w2(d,f,$CLJS.Xg(e));return e.h?e.h(d):e.call(null,d)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();