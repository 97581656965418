var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var R8,Xla,S8,U8,Yla,Zla,$la,ama,bma,cma,dma,V8,ema,X8,Y8,fma,W8,Z8;
R8=function(a){return function(b){var c=$CLJS.We($CLJS.eh);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Id($CLJS.q(c),t))return l;c.Sd(null,$CLJS.ae.g(c.Xb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var h=null;h=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};h.o=f;h.h=e;h.g=d;return h}()}};Xla=function(a,b,c){return $CLJS.eg.j($CLJS.Bf,$CLJS.gv(function(d){return $CLJS.L1.v(a,b,d,new $CLJS.k(null,2,[$CLJS.v1,c,$CLJS.q1,!1],null))}),$CLJS.nR.h($CLJS.y0(a,b)))};S8=function(a,b,c){return $CLJS.eg.j($CLJS.Bf,$CLJS.gv(function(d){return $CLJS.J1.v(a,b,d,c)}),$CLJS.nR.h($CLJS.y0(a,b)))};$CLJS.T8=function(a,b){return $CLJS.M5.g(a,$CLJS.Id(b,$CLJS.qI)?$CLJS.qI.h(b):b)};
U8=function(a,b){var c=$CLJS.i4(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.Ge(b);c=$CLJS.J.g(d,$CLJS.tU);d=$CLJS.J.g(d,$CLJS.D_);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.M6(a,d),$CLJS.n(d))){d=$CLJS.S.h(d);a=$CLJS.S.h($CLJS.D0(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.o4(a,$CLJS.S.h(b)):$CLJS.S.h(b)};
$CLJS.z4=function(a,b){return $CLJS.bb(function(c,d){return $CLJS.I_.l(c,d,$CLJS.R,$CLJS.G([$CLJS.H6,$CLJS.J1.j(c,d,$CLJS.y0(c,d))]))},a,$CLJS.dw(0,$CLJS.w_(a,b)))};
Yla=function(a,b){a=$CLJS.y0(a,b);a=$CLJS.Ge(a);var c=$CLJS.J.g(a,$CLJS.Ev),d=$CLJS.J.g(a,$CLJS.FZ);b=$CLJS.H6.h(a);if($CLJS.n(b))return b;var e=$CLJS.bZ.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var h=$CLJS.F.g(c,$CLJS.QZ);return h?h:d}())){var f=function(){var h=c instanceof $CLJS.M?c.T:null;switch(h){case "mbql.stage/native":return $CLJS.T0;case "mbql.stage/mbql":return $CLJS.Q0;default:throw Error(["No matching clause: ",$CLJS.p.h(h)].join(""));}}();return $CLJS.He(function(){return function m(l){return new $CLJS.me(null,
function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.Bd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.qe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.md(u,A);D=$CLJS.Zk.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.E0,$CLJS.S.h(D),$CLJS.M0,$CLJS.S.h(D)],null),D,new $CLJS.k(null,1,[$CLJS.A_,f],null)]));x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.lc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.B(t);return $CLJS.de($CLJS.Zk.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.E0,$CLJS.S.h(x),$CLJS.M0,$CLJS.S.h(x)],
null),x,new $CLJS.k(null,1,[$CLJS.A_,f],null)])),m($CLJS.Lc(t)))}return null}},null,null)}($CLJS.u_.h(e))}())}return null};
Zla=function(a,b,c){return $CLJS.He(function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.md(l,v),A=t,D=$CLJS.R,H=D.l,K=x,U=$CLJS.WD($CLJS.E0,$CLJS.S)(x);x=U8(a,x);x=c.h?c.h(x):c.call(null,x);D=H.call(D,K,$CLJS.A_,$CLJS.E_,$CLJS.G([$CLJS.E0,U,$CLJS.M0,x]));A.add(D);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.B(h);return $CLJS.de($CLJS.R.l(u,$CLJS.A_,$CLJS.E_,$CLJS.G([$CLJS.E0,$CLJS.WD($CLJS.E0,$CLJS.S)(u),$CLJS.M0,function(){var v=U8(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(h)))}return null}},null,null)}($CLJS.Q5(a,b))}())};
$la=function(a,b,c){return $CLJS.He(function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.md(l,v),A=t,D=$CLJS.R,H=D.l,K=x,U=$CLJS.S.h(x);x=$CLJS.S.h(x);x=c.h?c.h(x):c.call(null,x);D=H.call(D,K,$CLJS.A_,$CLJS.H_,$CLJS.G([$CLJS.E0,U,$CLJS.M0,x]));A.add(D);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),null)}var u=
$CLJS.B(h);return $CLJS.de($CLJS.R.l(u,$CLJS.A_,$CLJS.H_,$CLJS.G([$CLJS.E0,$CLJS.S.h(u),$CLJS.M0,function(){var v=$CLJS.S.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(h)))}return null}},null,null)}($CLJS.d5.g(a,b))}())};
ama=function(a,b,c){var d=$CLJS.y0(a,b);if($CLJS.n(d)){d=$CLJS.Ge(d);var e=$CLJS.J.g(d,$CLJS.XI);return $CLJS.He(function(){return function l(h){return new $CLJS.me(null,function(){for(;;){var m=$CLJS.z(h);if(m){if($CLJS.Bd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.qe(u);return function(){for(var K=0;;)if(K<u){var U=$CLJS.md(t,K),Z=$CLJS.I(U,0,null),ca=function(){var Ca=Z;Ca=Ca instanceof $CLJS.M?Ca.T:null;switch(Ca){case "field":return $CLJS.S0;case "expression":return $CLJS.I0;default:throw Error(["No matching clause: ",
$CLJS.p.h(Ca)].join(""));}}(),ra=$CLJS.c1.j(a,b,U);$CLJS.ue(v,$CLJS.R.l(ra,$CLJS.A_,ca,$CLJS.G([$CLJS.E0,$CLJS.B1.j(a,b,ra),$CLJS.M0,function(){var Ca=U8(a,ra);return c.h?c.h(Ca):c.call(null,Ca)}()])));K+=1}else return!0}()?$CLJS.te($CLJS.ve(v),l($CLJS.lc(m))):$CLJS.te($CLJS.ve(v),null)}var x=$CLJS.B(m),A=$CLJS.I(x,0,null),D=function(){var K=A;K=K instanceof $CLJS.M?K.T:null;switch(K){case "field":return $CLJS.S0;case "expression":return $CLJS.I0;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),H=$CLJS.c1.j(a,b,x);return $CLJS.de($CLJS.R.l(H,$CLJS.A_,D,$CLJS.G([$CLJS.E0,$CLJS.B1.j(a,b,H),$CLJS.M0,function(){var K=U8(a,H);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Lc(m)))}return null}},null,null)}(e)}())}return null};bma=function(a,b,c){return $CLJS.He($CLJS.eg.j($CLJS.Bf,$CLJS.gv(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[Zla,$la],null)))};
cma=function(a,b,c){var d=$CLJS.B6(a,b);return $CLJS.n(d)?$CLJS.He(function(){return function h(f){return new $CLJS.me(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Bd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);return function(){for(var A=0;;)if(A<t){var D=$CLJS.md(m,A);var H=$CLJS.WD($CLJS.M0,$CLJS.E0)(D);H=$CLJS.n(H)?H:$CLJS.B1.j(a,b,D);D=$CLJS.Ak.g($CLJS.R.l(D,$CLJS.A_,$CLJS.F0,$CLJS.G([$CLJS.E0,H,$CLJS.M0,c.h?c.h(H):c.call(null,H)])),$CLJS.J6);u.add(D);A+=1}else return!0}()?$CLJS.te($CLJS.ve(u),
h($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}var v=$CLJS.B(l),x=function(){var A=$CLJS.WD($CLJS.M0,$CLJS.E0)(v);return $CLJS.n(A)?A:$CLJS.B1.j(a,b,v)}();return $CLJS.de($CLJS.Ak.g($CLJS.R.l(v,$CLJS.A_,$CLJS.F0,$CLJS.G([$CLJS.E0,x,$CLJS.M0,c.h?c.h(x):c.call(null,x)])),$CLJS.J6),h($CLJS.Lc(l)))}return null}},null,null)}($CLJS.J1.j(a,d,$CLJS.y0(a,d)))}()):null};
dma=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.b1(a,c),$CLJS.n(c)?$CLJS.He($CLJS.Jk.g(function(e){return $CLJS.Ak.g(e,$CLJS.N0)},$CLJS.L1.v(a,b,c,new $CLJS.k(null,2,[$CLJS.v1,d,$CLJS.q1,!1],null)))):null):null};
V8=function(a,b,c){return $CLJS.He(function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.md(l,v),A=t;var D=$CLJS.CE.h(x);var H=$CLJS.R.l,K=x,U=$CLJS.S.h(x);x=$CLJS.S.h(x);x=c.h?c.h(x):c.call(null,x);D=$CLJS.f3(H.call($CLJS.R,K,$CLJS.A_,$CLJS.I0,$CLJS.G([$CLJS.E0,U,$CLJS.M0,x])),$CLJS.Hi,$CLJS.n(D)?D:$CLJS.yj);A.add(D);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),
f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),null)}var u=$CLJS.B(h);return $CLJS.de(function(){var v=$CLJS.CE.h(u),x=$CLJS.R.l,A=u,D=$CLJS.S.h(u);var H=$CLJS.S.h(u);H=c.h?c.h(H):c.call(null,H);return $CLJS.f3(x.call($CLJS.R,A,$CLJS.A_,$CLJS.I0,$CLJS.G([$CLJS.E0,D,$CLJS.M0,H])),$CLJS.Hi,$CLJS.n(v)?v:$CLJS.yj)}(),f($CLJS.Lc(h)))}return null}},null,null)}($CLJS.Jja.g(a,b))}())};
ema=function(a,b,c,d){var e=$CLJS.eg.j($CLJS.eh,$CLJS.ff.h($CLJS.D_),c);return $CLJS.eg.j($CLJS.Bf,$CLJS.Hk.l($CLJS.hf($CLJS.N0),R8($CLJS.N0),$CLJS.ff.h(function(f){var h=$CLJS.Ge(f);f=$CLJS.J.g(h,$CLJS.Yi);h=$CLJS.J.g(h,$CLJS.N0);return $CLJS.R.j($CLJS.D0(a,h),W8,f)}),$CLJS.G([$CLJS.Ik.h(function(f){return $CLJS.Id(e,$CLJS.D_.h(f))}),R8($CLJS.D_),$CLJS.gv(function(f){f=$CLJS.Ge(f);var h=$CLJS.J.g(f,$CLJS.D_),l=$CLJS.J.g(f,W8);f=$CLJS.a1(a,h);return function u(t){return new $CLJS.me(null,function(){for(;;){var v=
$CLJS.z(t);if(v){if($CLJS.Bd(v)){var x=$CLJS.kc(v),A=$CLJS.E(x),D=$CLJS.qe(A);return function(){for(var U=0;;)if(U<A){var Z=$CLJS.md(x,U),ca=$CLJS.R.l(Z,$CLJS.tU,l,$CLJS.G([$CLJS.A_,$CLJS.K0,$CLJS.E0,$CLJS.S.h(Z)]));Z=D;var ra=$CLJS.R,Ca=ra.j,hb=ca;ca=U8(a,ca);ca=d.h?d.h(ca):d.call(null,ca);ra=Ca.call(ra,hb,$CLJS.M0,ca);Z.add(ra);U+=1}else return!0}()?$CLJS.te($CLJS.ve(D),u($CLJS.lc(v))):$CLJS.te($CLJS.ve(D),null)}var H=$CLJS.B(v),K=$CLJS.R.l(H,$CLJS.tU,l,$CLJS.G([$CLJS.A_,$CLJS.K0,$CLJS.E0,$CLJS.S.h(H)]));
return $CLJS.de($CLJS.R.j(K,$CLJS.M0,function(){var U=U8(a,K);return d.h?d.h(U):d.call(null,U)}()),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.K1.v(a,b,f,new $CLJS.k(null,2,[$CLJS.v1,d,$CLJS.q1,!1],null)))})])),c)};
X8=function(a,b,c){var d=$CLJS.Ge(c),e=$CLJS.J.g(d,$CLJS.v1);return $CLJS.Jk.g(function(f){return $CLJS.Ak.l(f,$CLJS.h4,$CLJS.G([$CLJS.J6,$CLJS.I6,$CLJS.tU]))},function(){var f=cma(a,b,e);if($CLJS.n(f))return f;f=$CLJS.y0(a,b);f=$CLJS.Ge(f);var h=$CLJS.J.g(f,$CLJS.SS),l=$CLJS.J.g(f,$CLJS.FZ),m=$CLJS.n(h)?function(){var t=$CLJS.a1(a,h);return $CLJS.L1.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?dma(a,b,l,e):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.me(null,function(){for(;;){var x=
$CLJS.z(u);if(x){if($CLJS.Bd(x)){var A=$CLJS.kc(x),D=$CLJS.E(A),H=$CLJS.qe(D);return function(){for(var U=0;;)if(U<D){var Z=$CLJS.md(A,U),ca=H,ra=$CLJS.R,Ca=ra.l,hb=Z,Ob=$CLJS.S.h(Z);Z=$CLJS.S.h(Z);Z=e.h?e.h(Z):e.call(null,Z);ra=Ca.call(ra,hb,$CLJS.A_,$CLJS.T0,$CLJS.G([$CLJS.E0,Ob,$CLJS.M0,Z]));ca.add(ra);U+=1}else return!0}()?$CLJS.te($CLJS.ve(H),v($CLJS.lc(x))):$CLJS.te($CLJS.ve(H),null)}var K=$CLJS.B(x);return $CLJS.de($CLJS.R.l(K,$CLJS.A_,$CLJS.T0,$CLJS.G([$CLJS.E0,$CLJS.S.h(K),$CLJS.M0,function(){var U=
$CLJS.S.h(K);return e.h?e.h(U):e.call(null,U)}()])),v($CLJS.Lc(x)))}return null}},null,null)}($CLJS.u_.h($CLJS.bZ.h(f)))}())};
Y8=function(a,b){var c=$CLJS.I(a,0,null);$CLJS.I(a,1,null);var d=$CLJS.I(a,2,null);switch(c instanceof $CLJS.M?c.T:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.F.g(d,$CLJS.Yi.h(b));throw $CLJS.li("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.NV,a,$CLJS.Ui,b],null));case "expression":return $CLJS.F.g(d,$CLJS.S.h(b));default:throw $CLJS.li("unknown clause in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.NV,a,$CLJS.Ui,b],null));}};
fma=function(a,b,c){var d=$CLJS.tS.h($CLJS.y0(a,b));return $CLJS.n(d)?function h(f){return new $CLJS.me(null,function(){for(;;){var l=$CLJS.z(f);if(l){var m=l;if($CLJS.Bd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.qe(u);return function(){for(var A=0;;)if(A<u){var D=$CLJS.md(t,A);$CLJS.ue(v,function(){var H=$CLJS.ML(function(Z,ca){return function(ra){return Y8(ra,ca)}}(A,D,t,u,v,m,l,d,d),d);if($CLJS.n(H)){var K=$CLJS.D6(H);H=$CLJS.f4(H);H=$CLJS.Ge(H);H=$CLJS.J.g(H,$CLJS.xi);var U=D;K=$CLJS.n(K)?$CLJS.T8(U,
K):U;return $CLJS.n(H)?$CLJS.e4(K,H):K}return D}());A+=1}else return!0}()?$CLJS.te($CLJS.ve(v),h($CLJS.lc(m))):$CLJS.te($CLJS.ve(v),null)}var x=$CLJS.B(m);return $CLJS.de(function(){var A=$CLJS.ML(function(K){return function(U){return Y8(U,K)}}(x,m,l,d,d),d);if($CLJS.n(A)){var D=$CLJS.D6(A);A=$CLJS.f4(A);A=$CLJS.Ge(A);A=$CLJS.J.g(A,$CLJS.xi);var H=x;D=$CLJS.n(D)?$CLJS.T8(H,D):H;return $CLJS.n(A)?$CLJS.e4(D,A):D}return x}(),h($CLJS.Lc(m)))}return null}},null,null)}(c):c};
W8=new $CLJS.M("metabase.lib.stage","source-field-id","metabase.lib.stage/source-field-id",-962431705);Z8=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.lia={};$CLJS.yJ($CLJS.KZ,Z8);$CLJS.yJ($CLJS.QZ,Z8);$CLJS.P1.m(null,$CLJS.KZ,function(a){return $CLJS.O1(a,new $CLJS.k(null,2,[$CLJS.bK,$CLJS.Qe($CLJS.Jk,$CLJS.P1),$CLJS.WZ,$CLJS.Qe($CLJS.Jk,$CLJS.P1)],null))});$CLJS.G1.m(null,Z8,function(){throw $CLJS.li("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.K1.m(null,Z8,function(a,b,c,d){var e=$CLJS.Ge(d);c=$CLJS.J.g(e,$CLJS.v1);d=$CLJS.J.g(e,$CLJS.q1);a=$CLJS.z4(a,b);e=$CLJS.Ge(e);var f=$CLJS.J.g(e,$CLJS.v1),h=$CLJS.J.g(e,$CLJS.t1),l=$CLJS.J.g(e,$CLJS.y1);e=$CLJS.cf.l(X8(a,b,e),$CLJS.n(l)?V8(a,b,f):null,$CLJS.G([$CLJS.n(h)?Xla(a,b,f):null]));return fma(a,b,$CLJS.cf.g(e,$CLJS.n(d)?ema(a,b,e,c):null))});
$CLJS.I1.m(null,Z8,function(a,b,c,d){c=$CLJS.Ge(d);d=$CLJS.J.g(c,$CLJS.v1);var e=Yla(a,b);if($CLJS.n(e))return e;a=$CLJS.z4(a,b);e=bma(a,b,d);var f=ama(a,b,d);return $CLJS.n(e)?$CLJS.eg.g(e,f):$CLJS.n(f)?($CLJS.ph(f),$CLJS.eg.j($CLJS.Bf,R8(function(h){return $CLJS.Ak.l(h,$CLJS.V0,$CLJS.G([$CLJS.A_,$CLJS.h3,$CLJS.M0]))}),$CLJS.cf.g(f,S8(a,b,c)))):$CLJS.cf.l(X8(a,b,new $CLJS.k(null,2,[$CLJS.q1,!1,$CLJS.v1,d],null)),V8(a,b,d),$CLJS.G([S8(a,b,c)]))});$CLJS.z1.m(null,$CLJS.QZ,function(){return $CLJS.MI("Native query")});
var gma=new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.SS,$CLJS.FZ,$CLJS.bK,$CLJS.tS,$CLJS.WZ,$CLJS.CV,$CLJS.ZV],null);
$CLJS.z1.m(null,$CLJS.KZ,function(a,b,c,d){var e=$CLJS.z4(a,b);a=$CLJS.He(function(){return $CLJS.Lu(", ",$CLJS.Ik.g($CLJS.bE,function(){return function l(h){return new $CLJS.me(null,function(){for(;;){var m=$CLJS.z(h);if(m){if($CLJS.Bd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.qe(u);a:for(var x=0;;)if(x<u){var A=$CLJS.md(t,x);A=$CLJS.D1.j(e,b,A);v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.te($CLJS.ve(v),l($CLJS.lc(m))):$CLJS.te($CLJS.ve(v),null)}v=$CLJS.B(m);return $CLJS.de($CLJS.D1.j(e,b,v),
l($CLJS.Lc(m)))}return null}},null,null)}(gma)}()))}());if($CLJS.n(a))return a;a=$CLJS.B6(e,b);return $CLJS.n(a)?$CLJS.d1.v(e,a,$CLJS.y0(e,a),d):null});