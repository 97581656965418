var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Wha,Xha,Yha,c4,Zha,k4,l4,m4,n4,$ha,aia,bia,cia,dia,eia,fia,gia,hia,x4,y4,iia,jia,w4,v4,kia;$CLJS.a4=function(a,b){$CLJS.bb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Wha=function(a){return function(b){var c=$CLJS.We(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Dl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var h=null;h=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};h.o=
f;h.h=e;h.g=d;return h}()}};Xha=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.y(b.slice(3),0,null):null;return $CLJS.Ta($CLJS.z($CLJS.XD(c,d)))?c:$CLJS.O.N($CLJS.sZ,c,d,e,b)};$CLJS.b4=function(a,b){return $CLJS.p_(a)&&$CLJS.F.g($CLJS.B(a),b)};
Yha=function(a,b){return $CLJS.B($CLJS.$l(function(c){if($CLJS.n($CLJS.n(c)?b:c)){var d=$CLJS.Ou.h(c);c=$CLJS.Tu.h(c);d=$CLJS.n($CLJS.rh(d,b))?$CLJS.aE(b,d,c):null}else d=null;return d},a))};c4=function(a){a=$CLJS.h2(a);var b=$CLJS.bE(a);b||($CLJS.n(a)?(b=$CLJS.n(a)?!$CLJS.Id($CLJS.q($CLJS.n2),$CLJS.h2(a).toLowerCase()):null,b=$CLJS.Ta(b)):b=null);return $CLJS.n(b)?a:Yha($CLJS.Zb($CLJS.q($CLJS.j2)),a)};
$CLJS.d4=function(a,b,c){c=$CLJS.Jk.g($CLJS.g_,c);var d=$CLJS.Jk.g($CLJS.g_,b),e=$CLJS.eg.j($CLJS.eh,$CLJS.ff.h(function(f){return $CLJS.w2(a,f,d)}),c);return $CLJS.Jk.j(function(f,h){return $CLJS.R.j(f,$CLJS.O0,$CLJS.Id(e,h))},b,d)};$CLJS.e4=function(a,b){return $CLJS.j3.g(a,b instanceof $CLJS.M?b:$CLJS.xi.h(b))};$CLJS.f4=function(a){a=$CLJS.k3.h(a);return $CLJS.n(a)?new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.vL,$CLJS.xi,a],null):null};$CLJS.g4=function(a){return $CLJS.F.g($CLJS.AF(a),$CLJS.cP)};
$CLJS.i4=function(a){var b=$CLJS.AF(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.gT.h($CLJS.JI(a));case "metadata/column":return $CLJS.h4.h(a);case "mbql/join":return $CLJS.aJ.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
Zha=function(a,b){return $CLJS.n($CLJS.XI.h(a))?$CLJS.Kk.j(a,$CLJS.XI,function(c){return $CLJS.xd(c)?$CLJS.Jk.g(function(d){return $CLJS.j4.g?$CLJS.j4.g(d,b):$CLJS.j4.call(null,d,b)},c):c}):a};
k4=function(a){if($CLJS.n(a)){var b=$CLJS.jf;a:try{if($CLJS.Ad(a)&&4===$CLJS.E(a))try{if($CLJS.cd(a,0)instanceof $CLJS.M)try{var c=$CLJS.cd(a,2);if($CLJS.Ad(c)&&3===$CLJS.E(c))try{var d=$CLJS.cd(c,0);if($CLJS.ge(d,$CLJS.mK))try{var e=$CLJS.cd(a,3);if($CLJS.Ad(e)&&3===$CLJS.E(e))try{var f=$CLJS.cd(e,0);if($CLJS.ge(f,$CLJS.mK)){$CLJS.cd(e,1);$CLJS.cd(e,2);$CLJS.cd(c,1);$CLJS.cd(c,2);$CLJS.cd(a,0);$CLJS.cd(a,1);var h=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){var l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y){h=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.B($CLJS.z(b($CLJS.Ua,h)))}else a=null;return a};l4=function(a){if($CLJS.n(k4(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};m4=function(a){return $CLJS.n(k4(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
n4=function(a,b){var c=$CLJS.j4;if($CLJS.Ta(k4(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.O.j(c,a,b)],null)};
$ha=function(a,b,c){return $CLJS.ud($CLJS.kP.h(a))?a:$CLJS.n(b)?Xha(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kP],null),function(d){return function l(f,h){try{if($CLJS.Ad(h)&&3===$CLJS.E(h))try{var m=$CLJS.cd(h,0);if($CLJS.ge(m,$CLJS.mK))try{var t=$CLJS.cd(h,1);if(null!=t?t.C&256||$CLJS.r===t.We||(t.C?0:$CLJS.Xa($CLJS.sb,t)):$CLJS.Xa($CLJS.sb,t))try{var u=$CLJS.J.j(t,$CLJS.gT,$CLJS.wZ);if($CLJS.F.g(u,b))return $CLJS.cd(h,2),$CLJS.j4.g?$CLJS.j4.g(h,c):$CLJS.j4.call(null,h,c);throw $CLJS.Y;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)return $CLJS.rZ(l,f,h);throw v;}throw x;}}($CLJS.Bf,d)}):$CLJS.Kk.j(a,$CLJS.kP,function(d){return $CLJS.Jk.g(function(e){return n4(e,$CLJS.G([c]))},d)})};
$CLJS.j4=function(a,b){var c=$CLJS.AF(a);c=c instanceof $CLJS.M?c.T:null;switch(c){case "field":return $CLJS.d_(a,$CLJS.c_,$CLJS.G([$CLJS.gT,b]));case "metadata/column":return $CLJS.c_(a,$CLJS.h4,b);case "mbql/join":return c=$CLJS.i4(a),$ha(Zha($CLJS.c_(a,$CLJS.aJ,b),b),c,b);default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}};$CLJS.o4=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};
$CLJS.p4=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.WD($CLJS.E0,$CLJS.S)(c);a=$CLJS.o4($CLJS.aJ.h(a),$CLJS.WD($CLJS.E0,$CLJS.S)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.E0,e,$CLJS.G([$CLJS.M0,b]))};aia=function(a,b){return $CLJS.Ta(b)?a:$CLJS.Jk.g(function(c){var d=m4(c);d=$CLJS.n(d)?$CLJS.n($CLJS.i4(d))?null:n4(c,$CLJS.G([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.q4=function(a,b){b=aia($CLJS.Jk.g($CLJS.j_,b),$CLJS.i4(a));return $CLJS.c_(a,$CLJS.kP,$CLJS.He(b))};
bia=function(a,b){var c=$CLJS.eg.j($CLJS.N,$CLJS.Ul(function(e){var f=$CLJS.v2($CLJS.g_(e),b);return $CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[f,e],null):null}),a);c=$CLJS.ff.g(c,b);var d=$CLJS.ML($CLJS.WD($CLJS.W2,$CLJS.X2),c);if($CLJS.n(d))return d;c=$CLJS.B(c);if($CLJS.n(c))return c;c=$CLJS.ML($CLJS.W2,a);if($CLJS.n(c))return c;c=$CLJS.ML($CLJS.X2,a);return $CLJS.n(c)?c:$CLJS.B(a)};cia=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.aE(a,/ id$/i,"")):null};
dia=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Mh(function(){return c4(b)});var d=new $CLJS.Mh(function(){return c4(a)});return $CLJS.F.g(a,b)||$CLJS.F.g(a,$CLJS.q(c))||$CLJS.F.g($CLJS.q(d),b)||$CLJS.F.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
eia=function(a,b,c){var d=$CLJS.d1.g($CLJS.F.g($CLJS.Ev.h(b),$CLJS.VZ)?b:a,b),e=$CLJS.n(c)?cia($CLJS.d1.g(a,c)):null,f=dia(d,e);return function(){var h=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Ta($CLJS.qh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(h)?h:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
fia=function(a,b,c,d){return function l(f,h){try{if($CLJS.b4(h,$CLJS.mK)&&$CLJS.Fd($CLJS.w2(a,h,d)))return $CLJS.j4(h,c);throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)return $CLJS.rZ(l,f,h);throw m;}throw t;}}($CLJS.Bf,b)};
gia=function(a,b,c,d,e){b=fia(a,b,c,e);return function m(h,l){try{if($CLJS.Ad(l)&&4===$CLJS.E(l))try{var t=$CLJS.cd(l,2);if($CLJS.b4(t,$CLJS.mK))try{var u=$CLJS.cd(l,3);if($CLJS.b4(u,$CLJS.mK)){var v=$CLJS.cd(l,3),x=$CLJS.cd(l,2),A=$CLJS.cd(l,0),D=$CLJS.cd(l,1),H=$CLJS.i4(x),K=$CLJS.i4(v);if($CLJS.Ta($CLJS.n(H)?H:K))return $CLJS.n($CLJS.w2(a,v,d))?new $CLJS.P(null,4,5,$CLJS.Q,[A,D,$CLJS.j4(x,c),v],null):new $CLJS.P(null,4,5,$CLJS.Q,[A,D,x,$CLJS.j4(v,c)],null);if($CLJS.F.g(H,c)&&$CLJS.F.g(K,c)){var U=
$CLJS.d_(x,$CLJS.Ak,$CLJS.G([$CLJS.gT])),Z=$CLJS.d_(v,$CLJS.Ak,$CLJS.G([$CLJS.gT])),ca=null==$CLJS.w2(a,U,d);var ra=ca?$CLJS.w2(a,Z,d):ca;return $CLJS.n(ra)?new $CLJS.P(null,4,5,$CLJS.Q,[A,D,x,Z],null):new $CLJS.P(null,4,5,$CLJS.Q,[A,D,U,v],null)}return l}throw $CLJS.Y;}catch(hb){if(hb instanceof Error){var Ca=hb;if(Ca===$CLJS.Y)throw $CLJS.Y;throw Ca;}throw hb;}else throw $CLJS.Y;}catch(hb){if(hb instanceof Error){Ca=hb;if(Ca===$CLJS.Y)throw $CLJS.Y;throw Ca;}throw hb;}else throw $CLJS.Y;}catch(hb){if(hb instanceof
Error){Ca=hb;if(Ca===$CLJS.Y)return $CLJS.rZ(m,h,l);throw Ca;}throw hb;}}($CLJS.Bf,b)};hia=function(a,b){var c=$CLJS.z_();$CLJS.a4(c,b);return c(a)};
$CLJS.r4=function(a,b,c){if($CLJS.Id(c,$CLJS.aJ))return c;var d=$CLJS.y0(a,b);b=$CLJS.L1.j(a,b,d);var e=$CLJS.z($CLJS.jf($CLJS.Ua,function v(t,u){try{if($CLJS.Ad(u)&&1<=$CLJS.E(u))try{var x=$CLJS.Ok.j(u,0,1);if($CLJS.Ad(x)&&1===$CLJS.E(x))try{var A=$CLJS.cd(x,0);if($CLJS.ge(A,$CLJS.mK))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Y;}catch(H){if(H instanceof Error){var D=H;if(D===$CLJS.Y)throw $CLJS.Y;throw D;}throw H;}else throw $CLJS.Y;}catch(H){if(H instanceof Error){D=H;if(D===$CLJS.Y)throw $CLJS.Y;
throw D;}throw H;}else throw $CLJS.Y;}catch(H){if(H instanceof Error){D=H;if(D===$CLJS.Y)return $CLJS.qZ(v,t,u);throw D;}throw H;}}($CLJS.Bf,$CLJS.kP.h(c))));e=bia(b,e);var f=hia(eia(a,c,e),$CLJS.$l($CLJS.aJ,$CLJS.nR.h(d))),h=$CLJS.Jk.g($CLJS.g_,b),l=$CLJS.Jk.g($CLJS.g_,$CLJS.J1.h($CLJS.R1.g(a,$CLJS.hP.h(c))));return $CLJS.j4($CLJS.Kk.j(c,$CLJS.kP,function(t){return $CLJS.Jk.g(function(u){return gia(a,u,f,h,l)},t)}),f)};$CLJS.s4=function(a){return $CLJS.kP.h(a)};
$CLJS.t4=function(a){return $CLJS.Zk.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.lP,$CLJS.HI,a],null),$CLJS.F.g(a,$CLJS.hJ)?new $CLJS.k(null,1,[$CLJS.gi,!0],null):null]))};$CLJS.u4=function(a,b){b=$CLJS.B($CLJS.hP.h(b));return $CLJS.n($CLJS.FZ.h(b))?$CLJS.b1(a,$CLJS.FZ.h(b)):$CLJS.n($CLJS.SS.h(b))?$CLJS.a1(a,$CLJS.SS.h(b)):null};
x4=function(a){a=$CLJS.LL(function(d){return $CLJS.X2(d)?v4:$CLJS.W2(d)?w4:$CLJS.wv},a);var b=$CLJS.Ge(a);a=$CLJS.J.g(b,v4);var c=$CLJS.J.g(b,w4);b=$CLJS.J.g(b,$CLJS.wv);return $CLJS.cf.l(a,c,$CLJS.G([b]))};y4=function(a,b,c){return $CLJS.Ta(b)?c:$CLJS.Jk.g(function(d){return $CLJS.n($CLJS.O0.h(d))?$CLJS.e4(d,$CLJS.f4(b)):d},$CLJS.d4(a,c,new $CLJS.P(null,1,5,$CLJS.Q,[b],null)))};
iia=function(a,b,c){var d=$CLJS.Yi.h(c);return $CLJS.n(d)?$CLJS.ML(function(e){e=$CLJS.Ge(e);var f=$CLJS.J.g(e,$CLJS.N0);return $CLJS.W2(e)&&$CLJS.F.g(f,d)},$CLJS.L1.j(a,b,$CLJS.y0(a,b))):null};jia={};w4=new $CLJS.M(null,"fk","fk",398442651);$CLJS.h4=new $CLJS.M("metabase.lib.join","join-alias","metabase.lib.join/join-alias",1166233023);v4=new $CLJS.M(null,"pk","pk",-771936732);
kia=new $CLJS.w("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);var mia,nia,oia,pia,qia;$CLJS.z1.m(null,$CLJS.cP,function(a,b,c){b=$CLJS.Ge(c);b=$CLJS.J.g(b,$CLJS.hP);b=$CLJS.I(b,0,null);c=$CLJS.Ge(b);b=$CLJS.J.g(c,$CLJS.SS);c=$CLJS.J.g(c,$CLJS.FZ);a=$CLJS.n(b)?$CLJS.rI.h($CLJS.a1(a,b)):null;if($CLJS.n(a))return a;a=$CLJS.n(c)?$CLJS.X1(c):null;return $CLJS.n(a)?a:$CLJS.MI("Native Query")});$CLJS.H1.m(null,$CLJS.cP,function(a,b,c){a=$CLJS.d1.j(a,b,c);c=$CLJS.aJ.h(c);return new $CLJS.k(null,2,[$CLJS.S,$CLJS.n(c)?c:a,$CLJS.rI,a],null)});
$CLJS.G1.m(null,$CLJS.cP,function(){throw $CLJS.li("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.z1.m(null,$CLJS.lP,function(a,b,c){a=$CLJS.Ge(c);a=$CLJS.J.g(a,$CLJS.HI);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.MI("Left outer join");case "right-join":return $CLJS.MI("Right outer join");case "inner-join":return $CLJS.MI("Inner join");case "full-join":return $CLJS.MI("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.H1.m(null,$CLJS.lP,function(a,b,c){var d=$CLJS.Ge(c),e=$CLJS.J.g(d,$CLJS.HI);c=$CLJS.J.g(d,$CLJS.gi);a=new $CLJS.k(null,2,[$CLJS.x1,$CLJS.jE(e),$CLJS.rI,$CLJS.d1.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.gi,!0):a});
$CLJS.I1.m(null,$CLJS.cP,function(a,b,c,d){var e=$CLJS.Ge(c),f=$CLJS.J.g(e,$CLJS.aJ),h=$CLJS.J.j(e,$CLJS.XI,$CLJS.Wu),l=$CLJS.J.g(e,$CLJS.hP);c=$CLJS.Ge(d);var m=$CLJS.J.g(c,$CLJS.v1);if($CLJS.F.g(h,$CLJS.Wu))return null;var t="undefined"!==typeof $CLJS.MP&&"undefined"!==typeof jia&&"undefined"!==typeof $CLJS.lia&&"undefined"!==typeof $CLJS.z4?new $CLJS.Gc(function(){return $CLJS.z4},$CLJS.qd(kia,new $CLJS.k(null,1,[$CLJS.Xj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.hP,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.F.g(h,$CLJS.cC)?$CLJS.J1.v(u,-1,$CLJS.sd(l),c):function(){return function A(x){return new $CLJS.me(null,function(){for(;;){var D=$CLJS.z(x);if(D){if($CLJS.Bd(D)){var H=$CLJS.kc(D),K=$CLJS.E(H),U=$CLJS.qe(K);a:for(var Z=0;;)if(Z<K){var ca=$CLJS.md(H,Z);ca=$CLJS.d_(ca,$CLJS.Ak,$CLJS.G([$CLJS.gT]));ca=$CLJS.c1.j(u,-1,ca);U.add(ca);Z+=1}else{H=!0;break a}return H?$CLJS.te($CLJS.ve(U),A($CLJS.lc(D))):$CLJS.te($CLJS.ve(U),null)}U=$CLJS.B(D);U=$CLJS.d_(U,$CLJS.Ak,
$CLJS.G([$CLJS.gT]));return $CLJS.de($CLJS.c1.j(u,-1,U),A($CLJS.Lc(D)))}return null}},null,null)}(h)}();return $CLJS.Jk.g(function(v){v=$CLJS.R.j(v,$CLJS.V0,f);v=$CLJS.j4($CLJS.R.l(v,$CLJS.rI,$CLJS.d1.j(a,b,v),$CLJS.G([$CLJS.A_,$CLJS.L0])),f);return $CLJS.p4(e,m,v)},c)});$CLJS.K1.m(null,$CLJS.cP,function(a,b,c,d){return $CLJS.J1.v(a,b,$CLJS.R.j(c,$CLJS.XI,$CLJS.cC),d)});mia=$CLJS.Te($CLJS.N);nia=$CLJS.Te($CLJS.N);oia=$CLJS.Te($CLJS.N);pia=$CLJS.Te($CLJS.N);
qia=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.A4=new $CLJS.hi($CLJS.Ih.g("metabase.lib.join","join-clause-method"),$CLJS.AF,qia,mia,nia,oia,pia);$CLJS.A4.m(null,$CLJS.cP,function(a){return a});$CLJS.A4.m(null,$CLJS.VZ,function(a){return $CLJS.f_(new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.cP,$CLJS.hP,$CLJS.hP.h($CLJS.v_(a))],null))});
$CLJS.A4.m(null,$CLJS.KZ,function(a){return $CLJS.f_(new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.cP,$CLJS.hP,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});$CLJS.A4.m(null,$CLJS.G_,function(a){return $CLJS.f_(new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.cP,$CLJS.hP,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.k(null,2,[$CLJS.FZ,$CLJS.Yi.h(a),$CLJS.Ev,$CLJS.KZ],null)],null)],null))});
$CLJS.A4.m(null,$CLJS.C_,function(a){return $CLJS.f_(new $CLJS.k(null,2,[$CLJS.Ev,$CLJS.cP,$CLJS.hP,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.k(null,2,[$CLJS.SS,$CLJS.Yi.h(a),$CLJS.Ev,$CLJS.KZ],null)],null)],null))});
$CLJS.B4=function(){function a(d,e){return $CLJS.q4(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.f3($CLJS.A4.h(d),$CLJS.XI,$CLJS.cC)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ria=function(){function a(d,e,f){var h=$CLJS.r4(d,e,f);return $CLJS.I_.l(d,e,$CLJS.Kk,$CLJS.G([$CLJS.nR,function(l){return $CLJS.ae.g($CLJS.Cf(l),h)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.C4=function(){function a(d,e){return $CLJS.He($CLJS.J.g($CLJS.y0(d,e),$CLJS.nR))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.sia=function(){function a(d){d=$CLJS.Y0($CLJS.C0(d));d=$CLJS.Yu.h(d);return $CLJS.eg.j($CLJS.Bf,$CLJS.Hk.g($CLJS.hf($CLJS.Qe($CLJS.Id,d)),$CLJS.ff.h($CLJS.t4)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hJ,$CLJS.TI,$CLJS.nJ,$CLJS.pJ],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tia=function(){function a(d,e,f,h){var l=$CLJS.g4(f)?$CLJS.i4(f):null,m=$CLJS.eg.j($CLJS.eh,$CLJS.Hk.g($CLJS.ff.h($CLJS.i4),Wha(function(t){return $CLJS.ah.g(t,l)})),$CLJS.C4.g(d,e));f=$CLJS.n(h)?h:$CLJS.g4(f)?l4($CLJS.B($CLJS.s4(f))):null;return x4(y4(d,f,$CLJS.Ik.g(function(t){t=$CLJS.i4(t);return $CLJS.n(t)?$CLJS.Id(m,t):null},$CLJS.L1.v(d,e,$CLJS.y0(d,e),new $CLJS.k(null,1,[$CLJS.q1,!1],null)))))}function b(d,e,f,h){return c.N?c.N(d,-1,e,f,h):c.call(null,d,-1,e,f,h)}var c=null;c=function(d,
e,f,h,l){switch(arguments.length){case 4:return b.call(this,d,e,f,h);case 5:return a.call(this,d,e,f,h,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.uia=function(){function a(d,e,f,h,l){h=$CLJS.g4(f)?$CLJS.u4(d,f):f;var m=$CLJS.g4(f)?$CLJS.i4(f):null;f=$CLJS.n(l)?l:$CLJS.g4(f)?m4($CLJS.B($CLJS.s4(f))):null;return x4(y4(d,f,$CLJS.ff.g(function(t){t=$CLJS.R.j(t,$CLJS.A_,$CLJS.L0);return $CLJS.n(m)?$CLJS.j4(t,m):t},$CLJS.L1.v(d,e,h,new $CLJS.k(null,1,[$CLJS.q1,!1],null)))))}function b(d,e,f,h){return c.N?c.N(d,-1,e,f,h):c.call(null,d,-1,e,f,h)}var c=null;c=function(d,e,f,h,l){switch(arguments.length){case 4:return b.call(this,d,e,f,h);case 5:return a.call(this,
d,e,f,h,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.via=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.e3}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.e3};return b}();
$CLJS.wia=function(){function a(d,e,f){f=$CLJS.ML($CLJS.X2,$CLJS.L1.j(d,e,f));return $CLJS.n(f)?(d=iia(d,e,f),$CLJS.n(d)?$CLJS.$3.l($CLJS.c3.h($CLJS.Rs),d,$CLJS.G([f])):null):null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.xia=function(){function a(e,f,h,l){l=$CLJS.n(l)?l:$CLJS.g4(h)?l4($CLJS.B($CLJS.s4(h))):null;$CLJS.n(l)?(l=$CLJS.g1.j(e,f,l),l=$CLJS.XD(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.F_,$CLJS.rI],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.w_(e,f)){l=$CLJS.C4.g(e,f);var m=$CLJS.ud(l);h=m?m:$CLJS.g4(h)?$CLJS.F.g($CLJS.aJ.h(h),$CLJS.aJ.h($CLJS.B(l))):null;h=$CLJS.n(h)?$CLJS.X0(e):h}else h=l;$CLJS.n(h)?(h=$CLJS.X0(e),h=$CLJS.a1(e,h),e=$CLJS.d1.j(e,f,h)):e=null;return $CLJS.n(e)?e:$CLJS.MI("Previous results")}
function b(e,f,h){return d.v?d.v(e,-1,f,h):d.call(null,e,-1,f,h)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,h,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,h);case 4:return a.call(this,e,f,h,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.yia=function(){function a(d,e,f,h){f=$CLJS.j_.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);h=h instanceof $CLJS.M?h:$CLJS.xi.h(h);e=$CLJS.w_(d,e);l=$CLJS.n3.j(d,e,l);d=$CLJS.n3.j(d,e,m);m=null==h||$CLJS.Id($CLJS.ih($CLJS.ff.g($CLJS.xi,l)),h);d=null==h||$CLJS.Id($CLJS.ih($CLJS.ff.g($CLJS.xi,d)),h);f=m?$CLJS.Kk.v(f,2,$CLJS.e4,h):f;return d?$CLJS.Kk.v(f,3,$CLJS.e4,h):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,h){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,h)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();