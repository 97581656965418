var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.mbql.util.match.js");
'use strict';var q2,s2,t2,Vga,Wga,Xga,x2,y2;q2=function(a,b){var c=$CLJS.Hd,d=p2;for(a=$CLJS.z(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.B(a),c);if(c===d)return b;a=$CLJS.C(a)}else return d};
$CLJS.r2=function(a,b,c,d){a=$CLJS.v_(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.y0(a,b);e=!($CLJS.z($CLJS.bK.h(e))||$CLJS.z($CLJS.tS.h(e)));c=$CLJS.I_.l(a,b,$CLJS.Kk,$CLJS.G([c,function(f){return $CLJS.ae.g($CLJS.Cf(f),$CLJS.j_.h(d))}]));return e?$CLJS.Kk.N((0,$CLJS.I_)(c,b,function(f){return $CLJS.tZ.j($CLJS.Ak.l(f,$CLJS.CV,$CLJS.G([$CLJS.XI])),$CLJS.nR,function(h){return $CLJS.Jk.g(function(l){return $CLJS.Ak.g(l,$CLJS.XI)},h)})}),$CLJS.hP,$CLJS.Hk.g(function(f){return $CLJS.eg.g($CLJS.Bf,f)},$CLJS.Ok),
0,$CLJS.w_(a,b)+1):c};s2=function(a){return $CLJS.eg.j($CLJS.eh,$CLJS.Ik.h(function(b){return $CLJS.le(b)&&$CLJS.ah.g(b,$CLJS.Ev)}),$CLJS.Xg(a))};t2=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.cm(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.Ge(c);c=$CLJS.J.g(c,$CLJS.CI);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.bK.h(a))};
Vga=function(a){return $CLJS.e_(a,function(b){return $CLJS.eg.j($CLJS.N,$CLJS.Ik.h(function(c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.le(d)}),b)})};
$CLJS.v2=function(a,b){for(var c=$CLJS.Td,d=new $CLJS.P(null,5,5,$CLJS.Q,[function(){return function(h){return $CLJS.d_(h,$CLJS.tZ,$CLJS.G([$CLJS.jV,$CLJS.Ak,$CLJS.G0,$CLJS.Ev]))}}(c),Vga,function(){return function(h){return $CLJS.d_(h,$CLJS.Ak,$CLJS.G([$CLJS.CE,$CLJS.Hi]))}}(c),function(){return function(h){return $CLJS.d_(h,$CLJS.Ak,$CLJS.G([$CLJS.sK]))}}(c),function(){return function(h){return $CLJS.d_(h,$CLJS.Ak,$CLJS.G([$CLJS.gT]))}}(c)],null);;){var e=function(){var h=c.h?c.h(a):c.call(null,
a);return $CLJS.ML(function(l,m,t){return function(u){return u2.g(l.h?l.h(u):l.call(null,u),t)}}(c,d,h),b)}();if($CLJS.n(e))return e;if($CLJS.z(d)){e=$CLJS.Hk.g(c,$CLJS.B(d));var f=$CLJS.Lc(d);c=e;d=f}else return null}};
$CLJS.w2=function(a,b,c){var d=$CLJS.v2(b,c);return $CLJS.n(d)?d:$CLJS.n(a)?$CLJS.B($CLJS.z($CLJS.jf($CLJS.Ua,function l(f,h){try{if($CLJS.Ad(h)&&3===$CLJS.E(h))try{var m=$CLJS.cd(h,0);if($CLJS.ge(m,$CLJS.mK))try{var t=$CLJS.cd(h,2);if($CLJS.Gd(t)){var u=$CLJS.cd(h,2),v=$CLJS.cd(h,1);m=$CLJS.Q;var x=$CLJS.S.h($CLJS.D0(a,u));var A=$CLJS.n(x)?$CLJS.v2(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,v,x],null),c):null;return new $CLJS.P(null,1,5,m,[A],null)}throw $CLJS.Y;}catch(H){if(H instanceof Error){var D=
H;if(D===$CLJS.Y)throw $CLJS.Y;throw D;}throw H;}else throw $CLJS.Y;}catch(H){if(H instanceof Error){D=H;if(D===$CLJS.Y)throw $CLJS.Y;throw D;}throw H;}else throw $CLJS.Y;}catch(H){if(H instanceof Error){D=H;if(D===$CLJS.Y)return $CLJS.qZ(l,f,h);throw D;}throw H;}}($CLJS.Bf,b)))):null};Wga=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);Xga=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);
x2=new $CLJS.M(null,"left","left",-399115937);y2=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var u2=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.CF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.equality","\x3d"),function(f,h){f=$CLJS.AF(f);h=$CLJS.AF(h);return $CLJS.ah.g(f,h)?y2:f},e,a,b,c,d)}();u2.m(null,y2,function(){return!1});
u2.m(null,$CLJS.yE,function(a,b){var c=s2(a),d=s2(b);return $CLJS.F.g(c,d)&&$CLJS.Me(function(e){return u2.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});u2.m(null,$CLJS.wE,function(a,b){var c=$CLJS.F.g($CLJS.E(a),$CLJS.E(b));if(c)for(c=$CLJS.z(a),$CLJS.B(c),$CLJS.C(c),c=$CLJS.z(b),$CLJS.B(c),$CLJS.C(c),c=a,a=b;;){c=$CLJS.z(c);b=$CLJS.B(c);var d=$CLJS.C(c);c=b;b=d;a=$CLJS.z(a);d=$CLJS.B(a);a=$CLJS.C(a);c=u2.g(c,d);if($CLJS.n(c)){if(c=$CLJS.ud(b))return c;c=b}else return c}else return c});var p2=null;
u2.m(null,$CLJS.KZ,function(a,b){var c=p2;p2=new $CLJS.k(null,2,[x2,t2(a),$CLJS.Yz,t2(b)],null);try{var d=$CLJS.ei(u2,$CLJS.yE);return d.g?d.g(a,b):d.call(null,a,b)}finally{p2=c}});
u2.m(null,$CLJS.bK,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),h=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.F.l(3,$CLJS.E(a),$CLJS.G([$CLJS.E(b)])))?(c=$CLJS.F.g(c,f))?(d=u2.g(d,h),$CLJS.n(d)?$CLJS.n(p2)?$CLJS.F.g(q2(new $CLJS.P(null,2,5,$CLJS.Q,[x2,e],null),Xga),q2(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,l],null),Wga)):$CLJS.F.g(e,l):d):c:a});
u2.m(null,$CLJS.gi,function(a,b){if($CLJS.yd(a)){var c=$CLJS.ei(u2,$CLJS.yE);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.xd(a)?(c=$CLJS.ei(u2,$CLJS.wE),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.F.g(a,b)});