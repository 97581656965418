var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var T5=function(a){return $CLJS.n(S5)?["(",$CLJS.p.h(a),")"].join(""):a},yja=function(a,b,c){var d=$CLJS.y0(a,b);a=$CLJS.L1.j(a,b,d);var e=$CLJS.kE(c);return $CLJS.Ne(function(f){return $CLJS.F.g($CLJS.kE($CLJS.S.h(f)),e)},a)},zja=function(a,b){var c=$CLJS.Kk.v(a,$CLJS.MT,$CLJS.XH($CLJS.ae,$CLJS.Bf),b);return $CLJS.Ad($CLJS.XI.h(a))?$CLJS.Kk.v(c,$CLJS.XI,$CLJS.ae,$CLJS.f_(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,$CLJS.N,$CLJS.q_(b)],null))):c},Aja=function(a,b,c){var d=$CLJS.q_(c);return $CLJS.R.l($CLJS.c1.j(a,
b,c),$CLJS.A_,$CLJS.I0,$CLJS.G([$CLJS.S,d,$CLJS.rI,d]))},U5=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);var Bja=function(){function a(d,e,f){var h=$CLJS.y0(d,e);h=$CLJS.ML($CLJS.Hk.g($CLJS.fh([f]),$CLJS.q_),$CLJS.MT.h(h));if($CLJS.n(h))return h;throw $CLJS.li($CLJS.LI("No expression named {0}",$CLJS.G([$CLJS.Ph.l($CLJS.G([f]))])),new $CLJS.k(null,3,[$CLJS.OV,f,$CLJS.qQ,d,$CLJS.r1,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.E1.m(null,$CLJS.zE,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=Bja.j(a,b,c);return $CLJS.F1.j(a,b,c)});$CLJS.G1.m(null,$CLJS.zE,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.k(null,7,[$CLJS.Ev,$CLJS.h_,$CLJS.h3,$CLJS.CI.h(d),$CLJS.S,e,$CLJS.eJ,e,$CLJS.rI,$CLJS.d1.j(a,b,c),$CLJS.CE,$CLJS.F1.j(a,b,c),$CLJS.A_,$CLJS.I0],null)});$CLJS.z1.m(null,$CLJS.uE,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.z1.m(null,$CLJS.sE,function(a,b,c){return $CLJS.p.h(c)});$CLJS.z1.m(null,$CLJS.tE,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.z1.m(null,$CLJS.zE,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.A1.m(null,$CLJS.zE,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var S5=!1,Cja=new $CLJS.k(null,4,[$CLJS.Us,"+",$CLJS.pu,"-",$CLJS.mk,"×",$CLJS.IK,"÷"],null),V5=$CLJS.z(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Us,$CLJS.pu,$CLJS.IK,$CLJS.mk],null)),W5=null,X5=0,Y5=0;;)if(Y5<X5){var Dja=W5.X(null,Y5);$CLJS.yJ(Dja,U5);Y5+=1}else{var Z5=$CLJS.z(V5);if(Z5){var $5=Z5;if($CLJS.Bd($5)){var a6=$CLJS.kc($5),Eja=$CLJS.lc($5),Fja=a6,Gja=$CLJS.E(a6);V5=Eja;W5=Fja;X5=Gja}else{var Hja=$CLJS.B($5);$CLJS.yJ(Hja,U5);V5=$CLJS.C($5);W5=null;X5=0}Y5=0}else break}
$CLJS.z1.m(null,U5,function(a,b,c){var d=$CLJS.z(c);c=$CLJS.B(d);d=$CLJS.C(d);$CLJS.B(d);d=$CLJS.C(d);c=$CLJS.J.g(Cja,c);a:{var e=S5;S5=!0;try{var f=$CLJS.Lu([" ",$CLJS.jh(c)," "].join(""),$CLJS.ff.g($CLJS.Iv($CLJS.d1,a,b),d));break a}finally{S5=e}f=void 0}return T5(f)});$CLJS.A1.m(null,U5,function(){return"expression"});
$CLJS.E1.m(null,$CLJS.SL,function(a,b,c){c=$CLJS.z(c);$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var d=$CLJS.C(c);return $CLJS.Qd($CLJS.AJ,function(){return function h(f){return new $CLJS.me(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Bd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);x=$CLJS.F1.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),h($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.B(l);return $CLJS.de($CLJS.F1.j(a,
b,u),h($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.z1.m(null,$CLJS.tN,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.d1.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.i3.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=Math.abs(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=T5(f);return[a," ",d.call(b,f)].join("")});
$CLJS.A1.m(null,$CLJS.tN,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.B1.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.i3.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=Math.abs(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.z1.m(null,$CLJS.pK,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.d1.v(a,b,e,d)});$CLJS.A1.m(null,$CLJS.pK,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.B1.j(a,b,d)});
$CLJS.Ija=function(){function a(d,e,f,h){e=$CLJS.n(e)?e:-1;if($CLJS.n(yja(d,e,f)))throw $CLJS.li("Expression name conflicts with a column in the same query stage",new $CLJS.k(null,1,[$CLJS.OV,f],null));return $CLJS.I_.l(d,e,zja,$CLJS.G([$CLJS.r_($CLJS.j_.h(h),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,h){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,h)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Jja=function(){function a(d,e){var f=$CLJS.He($CLJS.MT.h($CLJS.y0(d,e)));return null==f?null:$CLJS.Jk.g($CLJS.Iv(Aja,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.b6=function(){function a(d,e){return $CLJS.He($CLJS.MT.h($CLJS.y0(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.b_.m(null,$CLJS.zE,function(a){return a});
$CLJS.Kja=function(){function a(d,e,f){var h=$CLJS.eg.g($CLJS.N,$CLJS.am(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.q_(t),m],null)},$CLJS.b6.g(d,e))),l=$CLJS.y0(d,e);d=$CLJS.L1.j(d,e,l);return $CLJS.He($CLJS.hZ(function(m){return $CLJS.Ta(f)||$CLJS.ah.g($CLJS.A_.h(m),$CLJS.I0)||$CLJS.J.g(h,$CLJS.S.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();