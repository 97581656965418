var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var o3,s3,tha,uha,t3,u3,vha,wha,v3,w3;
o3=function(a){return $CLJS.n($CLJS.T2.g?$CLJS.T2.g($CLJS.M2,a):$CLJS.T2.call(null,$CLJS.M2,a))?$CLJS.d3:$CLJS.n($CLJS.T2.g?$CLJS.T2.g($CLJS.J2,a):$CLJS.T2.call(null,$CLJS.J2,a))?$CLJS.d3:$CLJS.n($CLJS.T2.g?$CLJS.T2.g($CLJS.G2,a):$CLJS.T2.call(null,$CLJS.G2,a))?$CLJS.dha:$CLJS.n($CLJS.T2.g?$CLJS.T2.g($CLJS.P2,a):$CLJS.T2.call(null,$CLJS.P2,a))?$CLJS.eha:$CLJS.n($CLJS.T2.g?$CLJS.T2.g($CLJS.O2,a):$CLJS.T2.call(null,$CLJS.O2,a))?$CLJS.fha:$CLJS.n($CLJS.T2.g?$CLJS.T2.g($CLJS.F2,a):$CLJS.T2.call(null,
$CLJS.F2,a))?$CLJS.gha:$CLJS.n($CLJS.T2.g?$CLJS.T2.g($CLJS.D2,a):$CLJS.T2.call(null,$CLJS.D2,a))?$CLJS.hha:$CLJS.n($CLJS.T2.g?$CLJS.T2.g($CLJS.E2,a):$CLJS.T2.call(null,$CLJS.E2,a))?$CLJS.iha:$CLJS.n($CLJS.T2.g?$CLJS.T2.g($CLJS.K2,a):$CLJS.T2.call(null,$CLJS.K2,a))?$CLJS.jha:$CLJS.kha};$CLJS.p3=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.M?a.T:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.q3=function(a,b){a=$CLJS.p3(a);b=$CLJS.n(b)?b:$CLJS.Gv;return 0===a?$CLJS.F.g(b,$CLJS.Gv)?$CLJS.MI("Today"):$CLJS.LI("This {0}",$CLJS.G([$CLJS.i3.h(b)])):$CLJS.F.g(a,1)?$CLJS.F.g(b,$CLJS.Gv)?$CLJS.MI("Tomorrow"):$CLJS.LI("Next {0}",$CLJS.G([$CLJS.i3.h(b)])):$CLJS.F.g(a,-1)?$CLJS.F.g(b,$CLJS.Gv)?$CLJS.MI("Yesterday"):$CLJS.LI("Previous {0}",$CLJS.G([$CLJS.i3.h(b)])):0>a?$CLJS.LI("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.i3.g(Math.abs(a),b)])):0<a?$CLJS.LI("Next {0} {1}",$CLJS.G([a,$CLJS.i3.g(a,
b)])):null};s3=function(a){var b=o3(a);b=$CLJS.He(b);return null==b?a:$CLJS.R.j(a,$CLJS.r3,b)};tha=new $CLJS.M(null,"legacy-filter","legacy-filter",-948552546);uha=new $CLJS.M(null,"query-filters","query-filters",409521440);t3=new $CLJS.M("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);u3=new $CLJS.M("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);vha=new $CLJS.M(null,"matching-filters","matching-filters",-326129659);
wha=new $CLJS.M("mbql","filter-parts","mbql/filter-parts",893592485);$CLJS.r3=new $CLJS.M(null,"operators","operators",-2064102509);v3=new $CLJS.M("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);w3=new $CLJS.M("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var x3=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.st,$CLJS.mt],null)),y3=null,z3=0,A3=0;;)if(A3<z3){var xha=y3.X(null,A3);$CLJS.yJ(xha,v3);A3+=1}else{var B3=$CLJS.z(x3);if(B3){var C3=B3;if($CLJS.Bd(C3)){var D3=$CLJS.kc(C3),yha=$CLJS.lc(C3),zha=D3,Aha=$CLJS.E(D3);x3=yha;y3=zha;z3=Aha}else{var Bha=$CLJS.B(C3);$CLJS.yJ(Bha,v3);x3=$CLJS.C(C3);y3=null;z3=0}A3=0}else break}
for(var E3=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.FK],null)),F3=null,G3=0,H3=0;;)if(H3<G3){var Cha=F3.X(null,H3);$CLJS.yJ(Cha,u3);H3+=1}else{var I3=$CLJS.z(E3);if(I3){var J3=I3;if($CLJS.Bd(J3)){var K3=$CLJS.kc(J3),Dha=$CLJS.lc(J3),Eha=K3,Fha=$CLJS.E(K3);E3=Dha;F3=Eha;G3=Fha}else{var Gha=$CLJS.B(J3);$CLJS.yJ(Gha,u3);E3=$CLJS.C(J3);F3=null;G3=0}H3=0}else break}
for(var L3=$CLJS.z(new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Os,$CLJS.Qs,$CLJS.Ks,$CLJS.Ms,$CLJS.dK,$CLJS.xK,$CLJS.lK,$CLJS.eK],null)),M3=null,N3=0,O3=0;;)if(O3<N3){var Hha=M3.X(null,O3);$CLJS.yJ(Hha,w3);O3+=1}else{var P3=$CLJS.z(L3);if(P3){var Q3=P3;if($CLJS.Bd(Q3)){var R3=$CLJS.kc(Q3),Iha=$CLJS.lc(Q3),Jha=R3,Kha=$CLJS.E(R3);L3=Iha;M3=Jha;N3=Kha}else{var Lha=$CLJS.B(Q3);$CLJS.yJ(Lha,w3);L3=$CLJS.C(Q3);M3=null;N3=0}O3=0}else break}
for(var S3=$CLJS.z(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.QJ,$CLJS.$J,$CLJS.SJ,$CLJS.YJ,$CLJS.ot],null)),T3=null,U3=0,V3=0;;)if(V3<U3){var Mha=T3.X(null,V3);$CLJS.yJ(Mha,t3);V3+=1}else{var W3=$CLJS.z(S3);if(W3){var X3=W3;if($CLJS.Bd(X3)){var Y3=$CLJS.kc(X3),Nha=$CLJS.lc(X3),Oha=Y3,Pha=$CLJS.E(Y3);S3=Nha;T3=Oha;U3=Pha}else{var Qha=$CLJS.B(X3);$CLJS.yJ(Qha,t3);S3=$CLJS.C(X3);T3=null;U3=0}V3=0}else break}
$CLJS.C1.m(null,$CLJS.WZ,function(a,b){var c=$CLJS.He($CLJS.WZ.h($CLJS.y0(a,b)));return $CLJS.n(c)?$CLJS.LI("Filtered by {0}",$CLJS.G([$CLJS.g3($CLJS.MI("and"),function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u);v=$CLJS.d1.v(a,b,v,$CLJS.e1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.B(h);
return $CLJS.de($CLJS.d1.v(a,b,t,$CLJS.e1),f($CLJS.Lc(h)))}return null}},null,null)}(c)}())])):null});
$CLJS.z1.m(null,v3,function(a,b,c,d){c=$CLJS.z(c);var e=$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var f=$CLJS.C(c);return $CLJS.g3(function(){var h=e instanceof $CLJS.M?e.T:null;switch(h){case "and":return $CLJS.MI("and");case "or":return $CLJS.MI("or");default:throw Error(["No matching clause: ",$CLJS.p.h(h)].join(""));}}(),function(){return function m(l){return new $CLJS.me(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.Bd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.qe(v);a:for(var A=0;;)if(A<
v){var D=$CLJS.md(u,A);D=$CLJS.d1.v(a,b,D,d);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.lc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.B(t);return $CLJS.de($CLJS.d1.v(a,b,x,d),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())});
$CLJS.z1.m(null,u3,function(a,b,c,d){var e=$CLJS.z(c);c=$CLJS.B(e);e=$CLJS.C(e);$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.ff.g(function(h){return $CLJS.d1.v(a,b,h,d)},f);if($CLJS.F.g($CLJS.E(f),2))switch(f=$CLJS.I(e,0,null),e=$CLJS.I(e,1,null),c=c instanceof $CLJS.M?c.T:null,c){case "\x3d":return $CLJS.LI("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.LI("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}else switch(f=$CLJS.B(e),e=$CLJS.g3($CLJS.MI("or"),
$CLJS.Lc(e)),c=c instanceof $CLJS.M?c.T:null,c){case "\x3d":return $CLJS.LI("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.LI("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.z1.m(null,w3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);var f=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);f=$CLJS.d1.v(a,b,f,d);a=$CLJS.d1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "\x3c":return $CLJS.LI("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.LI("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.LI("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.LI("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.LI("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.LI("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.LI("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.LI("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.z1.m(null,$CLJS.RJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);return $CLJS.LI("{0} is between {1} and {2}",$CLJS.G([$CLJS.d1.v(a,b,e,d),$CLJS.d1.v(a,b,f,d),$CLJS.d1.v(a,b,c,d)]))});
$CLJS.z1.m(null,$CLJS.WJ,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.I(c,2,null),h=$CLJS.I(c,3,null),l=$CLJS.I(c,4,null),m=$CLJS.I(c,5,null),t=$CLJS.I(c,6,null);c=$CLJS.I(c,7,null);return $CLJS.d1.v(a,b,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,e,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.RJ,e,f,t,l],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.RJ,e,h,m,c],null)],null),d)});
$CLJS.z1.m(null,t3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.d1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "is-null":return $CLJS.LI("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.LI("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.LI("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.LI("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.LI("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.h(e)].join(""));}});$CLJS.z1.m(null,$CLJS.yK,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);return $CLJS.LI("{0} is within {1}",$CLJS.G([$CLJS.d1.v(a,b,e,d),$CLJS.q3(f,c).toLowerCase()]))});
$CLJS.Rha=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.AF(f),$CLJS.B_))f=$CLJS.g_(f);else return e=$CLJS.n(e)?e:-1,f=$CLJS.j_.h(f),$CLJS.I_.l(d,e,$CLJS.Kk,$CLJS.G([$CLJS.WZ,$CLJS.XH($CLJS.ae,$CLJS.Bf),f]))}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Z3=function(){function a(d,e){return $CLJS.He($CLJS.WZ.h($CLJS.y0(d,$CLJS.n(e)?e:-1)))}function b(d){return c.g?c.g(d,null):c.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Sha=function(){function a(d,e){var f=$CLJS.y0(d,e);return $CLJS.He($CLJS.eg.j($CLJS.Bf,$CLJS.Hk.g($CLJS.ff.h(s3),$CLJS.hf($CLJS.r3)),$CLJS.L1.j(d,e,f)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.$3=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var h=Array(arguments.length-2);f<h.length;)h[f]=arguments[f+2],++f;f=new $CLJS.y(h,0,null)}return b.call(this,c,d,f)}function b(c,d,e){return $CLJS.f_($CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK.h(c),$CLJS.N,$CLJS.j_.h(d)],null),$CLJS.ff.g($CLJS.j_,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.Tha=function(){function a(d,e,f){var h=$CLJS.I(f,0,null);$CLJS.I(f,1,null);f=$CLJS.I(f,2,null);var l=$CLJS.y0(d,e);d=$CLJS.L1.j(d,e,l);d=$CLJS.cw($CLJS.ff.g($CLJS.g_,d),d);f=$CLJS.v2(f,$CLJS.Xg(d));f=$CLJS.ML(function(m){return $CLJS.F.g($CLJS.fK.h(m),h)},o3(d.h?d.h(f):d.call(null,f)));return $CLJS.n(f)?f:$CLJS.c3.h(h)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,
f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Uha=function(){function a(d,e,f){d=$CLJS.Cf($CLJS.Z3.g(d,e));e=$CLJS.jf(function(h){return $CLJS.F.g($CLJS.U_.h(h),f)},d);if($CLJS.z(e)){if($CLJS.C(e))throw $CLJS.li("Multiple matching filters found",new $CLJS.k(null,3,[tha,f,uha,d,vha,e],null));return $CLJS.B(e)}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.Vha=function(){function a(d,e,f){f=$CLJS.z(f);var h=$CLJS.B(f),l=$CLJS.C(f);f=$CLJS.B(l);var m=$CLJS.C(l);l=$CLJS.B(m);m=$CLJS.C(m);var t=$CLJS.y0(d,e);e=$CLJS.L1.j(d,e,t);e=$CLJS.M_($CLJS.g_,e);d=$CLJS.w2(d,l,$CLJS.Xg(e));var u=e.h?e.h(d):e.call(null,d);return new $CLJS.k(null,5,[$CLJS.Ev,wha,$CLJS.zI,function(){var v=$CLJS.ML(function(x){return $CLJS.F.g($CLJS.fK.h(x),h)},o3(u));return $CLJS.n(v)?v:$CLJS.c3.h(h)}(),$CLJS.ej,f,$CLJS.Ui,null==u?null:s3(u),$CLJS.Wt,$CLJS.Cf(m)],null)}function b(d,
e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();