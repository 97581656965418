var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var QY,RY,SY,UY,VY,WY,XY,YY,ZY,$Y;QY=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);RY=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);SY=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.TY=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);UY=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);VY=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);WY=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
XY=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);YY=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);ZY=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);$Y=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(QY,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jt,new $CLJS.k(null,1,[$CLJS.zu,"Valid template tag :widget-type"],null),$CLJS.Wu],null),$CLJS.Xg($CLJS.LY)));$CLJS.X(UY,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jt,$CLJS.oQ,$CLJS.eR,$CLJS.RP,$CLJS.uv,$CLJS.HE,$CLJS.oT],null));
$CLJS.X(XY,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.wI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rI,$CLJS.wI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.k(null,1,[$CLJS.It,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,$CLJS.wI,$CLJS.Bt],null)],null)],null));
$CLJS.X(YY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Al],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HR,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null)],null)],null));
$CLJS.X(VY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YY],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.RP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.k(null,1,[$CLJS.It,!0],
null),$CLJS.dk],null)],null)],null));$CLJS.X(WY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XY],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.oQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aQ,$CLJS.wI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GT,$CLJS.aP],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HT,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.ZO],null)],null)],null));
$CLJS.X($Y,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.eR],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RU,$CLJS.YO],null)],null)],null));$CLJS.X(ZY,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jt],null),$CLJS.zY));
$CLJS.X(RY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZY],null)],null)],null)],null));
$CLJS.X(SY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UY],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Si,new $CLJS.k(null,1,[$CLJS.Pi,$CLJS.kj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oQ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eR,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$Y],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RY],null)],null)],null)],null));
$CLJS.X($CLJS.TY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rt,$CLJS.wI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.zu,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.F.g(c,$CLJS.S.h(b))},a)}],null)],null));