var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var M4=function(a,b,c){var d=$CLJS.y0(a,b);d=$CLJS.Ge(d);d=$CLJS.J.g(d,$CLJS.bK);d=$CLJS.ML($CLJS.Hk.j($CLJS.fh([c]),$CLJS.CI,$CLJS.jd),d);if(!$CLJS.n(d))throw $CLJS.li($CLJS.LI("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Bt,c,$CLJS.qQ,a,$CLJS.r1,b],null));return d},N4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592),O4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",
-21314018),P4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815),Fia=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.C1.m(null,$CLJS.bK,function(a,b){var c=$CLJS.He($CLJS.bK.h($CLJS.y0(a,b)));return $CLJS.n(c)?$CLJS.g3($CLJS.MI("and"),function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u);v=$CLJS.d1.v(a,b,v,$CLJS.e1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.B(h);return $CLJS.de($CLJS.d1.v(a,
b,t,$CLJS.e1),f($CLJS.Lc(h)))}return null}},null,null)}(c)}()):null});$CLJS.G1.m(null,$CLJS.bK,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.Ge(d);d=$CLJS.J.g(e,$CLJS.CE);e=$CLJS.J.g(e,$CLJS.Hi);c=$CLJS.I(c,2,null);c=M4(a,b,c);return $CLJS.Zk.l($CLJS.G([$CLJS.c1.j(a,b,c),new $CLJS.k(null,2,[$CLJS.A_,$CLJS.H_,$CLJS.h3,$CLJS.CI.h($CLJS.jd(c))],null),$CLJS.n(d)?new $CLJS.k(null,1,[$CLJS.CE,d],null):null,$CLJS.n(e)?new $CLJS.k(null,1,[$CLJS.Hi,e],null):null]))});
$CLJS.z1.m(null,$CLJS.bK,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.d1.v(a,b,M4(a,b,c),d)});$CLJS.yJ(O4,P4);
for(var Q4=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.nK],null)),R4=null,S4=0,T4=0;;)if(T4<S4){var Gia=R4.X(null,T4);$CLJS.yJ(Gia,O4);T4+=1}else{var U4=$CLJS.z(Q4);if(U4){var V4=U4;if($CLJS.Bd(V4)){var W4=$CLJS.kc(V4),Hia=$CLJS.lc(V4),Iia=W4,Jia=$CLJS.E(W4);Q4=Hia;R4=Iia;S4=Jia}else{var Kia=$CLJS.B(V4);$CLJS.yJ(Kia,O4);Q4=$CLJS.C(V4);R4=null;S4=0}T4=0}else break}
$CLJS.z1.m(null,O4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.d1.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.LI("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.LI("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.MI("Count");case "cum-count":return $CLJS.MI("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.A1.m(null,O4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.G1.m(null,O4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.ei($CLJS.G1,P4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Rj,$CLJS.yG)});$CLJS.z1.m(null,$CLJS.XJ,function(){return $CLJS.MI("Case")});
$CLJS.A1.m(null,$CLJS.XJ,function(){return"case"});$CLJS.yJ(N4,P4);for(var X4=$CLJS.z(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.gK,$CLJS.JK,$CLJS.TJ,$CLJS.ek,$CLJS.AK,$CLJS.Un,$CLJS.DK,$CLJS.zK,$CLJS.UJ],null)),Y4=null,Z4=0,$4=0;;)if($4<Z4){var Lia=Y4.X(null,$4);$CLJS.yJ(Lia,N4);$4+=1}else{var a5=$CLJS.z(X4);if(a5){var b5=a5;if($CLJS.Bd(b5)){var c5=$CLJS.kc(b5),Mia=$CLJS.lc(b5),Nia=c5,Oia=$CLJS.E(c5);X4=Mia;Y4=Nia;Z4=Oia}else{var Pia=$CLJS.B(b5);$CLJS.yJ(Pia,N4);X4=$CLJS.C(b5);Y4=null;Z4=0}$4=0}else break}
$CLJS.A1.m(null,N4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.z1.m(null,N4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.d1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.LI("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.LI("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.LI("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.LI("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.LI("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.LI("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.LI("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.LI("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.LI("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.z1.m(null,$CLJS.ZJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.LI("{0}th percentile of {1}",$CLJS.G([c,$CLJS.d1.v(a,b,e,d)]))});$CLJS.A1.m(null,$CLJS.ZJ,function(){return"percentile"});
$CLJS.yJ($CLJS.ZJ,P4);$CLJS.z1.m(null,$CLJS.HK,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.LI("Sum of {0} matching condition",$CLJS.G([$CLJS.d1.v(a,b,e,d)]))});$CLJS.A1.m(null,$CLJS.HK,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.B1.j(a,b,d))].join("")});$CLJS.yJ($CLJS.HK,P4);$CLJS.z1.m(null,$CLJS.tK,function(){return $CLJS.MI("Share of rows matching condition")});
$CLJS.A1.m(null,$CLJS.tK,function(){return"share"});$CLJS.yJ($CLJS.tK,P4);$CLJS.z1.m(null,$CLJS.vK,function(){return $CLJS.MI("Count of rows matching condition")});$CLJS.A1.m(null,$CLJS.vK,function(){return"count-where"});$CLJS.yJ($CLJS.vK,P4);
$CLJS.G1.m(null,P4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.Zk.l;d=$CLJS.n(d)?$CLJS.bh($CLJS.c1.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wU],null)):null;var f=$CLJS.ei($CLJS.G1,$CLJS.gi);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Zk,$CLJS.G([d,a]))});$CLJS.b_.m(null,$CLJS.bK,function(a){return a});
$CLJS.Qia=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.AF(f),$CLJS.i_))f=$CLJS.g_(f);else return $CLJS.r2(d,e,$CLJS.bK,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Ria=function(){function a(d,e){return $CLJS.He($CLJS.bK.h($CLJS.y0(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.d5=function(){function a(d,e){var f=$CLJS.He($CLJS.bK.h($CLJS.y0(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Bf,$CLJS.ff.h(function(h){var l=$CLJS.c1.j(d,e,h),m=$CLJS.R.l,t=$CLJS.CE.h(l);return m.call($CLJS.R,$CLJS.f3(l,$CLJS.Hi,$CLJS.n(t)?t:$CLJS.yj),$CLJS.A_,$CLJS.H_,$CLJS.G([$CLJS.h3,$CLJS.CI.h($CLJS.jd(h))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.z1.m(null,$CLJS.kL,function(a,b,c){a=$CLJS.Ge(c);a=$CLJS.J.g(a,$CLJS.WK);return $CLJS.rI.h(a.o?a.o():a.call(null))});$CLJS.H1.m(null,$CLJS.kL,function(a,b,c){var d=$CLJS.Ge(c);a=$CLJS.J.g(d,$CLJS.fK);b=$CLJS.J.g(d,$CLJS.WK);c=$CLJS.J.g(d,$CLJS.TK);d=$CLJS.J.g(d,$CLJS.O0);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.x1,$CLJS.jE(a),$CLJS.G([$CLJS.w1,c]));return null!=d?$CLJS.R.j(a,$CLJS.l1,d):a});
$CLJS.Sia=function(){function a(d,e){var f=function(){var m=$CLJS.Yu.h($CLJS.Y0($CLJS.C0(d)));return $CLJS.n(m)?m:$CLJS.eh}(),h=$CLJS.y0(d,e),l=$CLJS.L1.j(d,e,h);return $CLJS.He($CLJS.eg.j($CLJS.Bf,$CLJS.Hk.j($CLJS.hf(function(m){m=$CLJS.hL.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Ul(function(m){m=$CLJS.Ge(m);var t=$CLJS.J.g(m,$CLJS.TK),u=$CLJS.J.g(m,$CLJS.OK);if($CLJS.Ta(t))return m;if($CLJS.F.g(u,$CLJS.pi))return $CLJS.R.j(m,$CLJS.u_,l);t=$CLJS.He($CLJS.hZ(function(v){return $CLJS.T2(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.u_,t):null}),$CLJS.ff.h(function(m){return $CLJS.R.j(m,$CLJS.Ev,$CLJS.kL)})),$CLJS.lL))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Tia=function(){function a(d,e){return $CLJS.f_(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK.h(d),$CLJS.N,$CLJS.j_.h(e)],null))}function b(d){if($CLJS.Ta($CLJS.TK.h(d)))return $CLJS.f_(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fK.h(d),$CLJS.N],null));var e=$CLJS.fK.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.li(e,new $CLJS.k(null,1,[Fia,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();