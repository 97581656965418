var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var GM,HM,Waa,Xaa,Yaa,KM,LM,MM,OM,Zaa,QM;GM=function(a){switch(arguments.length){case 2:return $CLJS.xJ(arguments[0],arguments[1]);case 3:return $CLJS.wJ(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};HM=function(a){return["(?:",$CLJS.p.h($CLJS.O.g($CLJS.p,a)),")?"].join("")};Waa=new $CLJS.M("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
Xaa=new $CLJS.M("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.IM=new $CLJS.M("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);Yaa=new $CLJS.M("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.JM=new $CLJS.M("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
KM=new $CLJS.M("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);LM=new $CLJS.M("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);MM=new $CLJS.M("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.NM=new $CLJS.M("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
OM=new $CLJS.M("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Zaa=new $CLJS.M("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.PM=new $CLJS.M("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);QM=new $CLJS.M("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.RM=new $CLJS.M("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.OI.m(null,$CLJS.GE,function(){return $CLJS.yj});$CLJS.X(QM,$CLJS.Ct);$CLJS.OI.m(null,$CLJS.JE,function(){return $CLJS.Hj});$CLJS.X(QM,$CLJS.Ct);$CLJS.X(Zaa,$CLJS.yt);$CLJS.OI.m(null,$CLJS.uE,function(){return $CLJS.uj});$CLJS.X(Xaa,$CLJS.xt);$CLJS.OI.m(null,$CLJS.sE,function(){return $CLJS.NH});$CLJS.X(Yaa,$CLJS.Vj);
var $aa=[":\\d{2}",HM($CLJS.G(["\\.\\d{1,6}"]))].join(""),SM=["\\d{2}:\\d{2}",HM($CLJS.G([$aa]))].join(""),TM=["\\d{4}-\\d{2}-\\d{2}T",SM].join(""),UM=["(?:Z|(?:[+-]",SM,"))"].join(""),aba=$CLJS.sh("^\\d{4}-\\d{2}-\\d{2}$"),bba=$CLJS.sh(["^",SM,"$"].join("")),cba=$CLJS.sh(["^",SM,UM,"$"].join("")),dba=$CLJS.sh(["^",TM,"$"].join("")),eba=$CLJS.sh(["^",TM,UM,"$"].join(""));$CLJS.X(LM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,new $CLJS.k(null,1,[$CLJS.zu,"date string literal"],null),aba],null));
$CLJS.X(KM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,new $CLJS.k(null,1,[$CLJS.zu,"local time string literal"],null),bba],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,new $CLJS.k(null,1,[$CLJS.zu,"offset time string literal"],null),cba],null)],null));
$CLJS.X(MM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,new $CLJS.k(null,1,[$CLJS.zu,"local date time string literal"],null),dba],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,new $CLJS.k(null,1,[$CLJS.zu,"offset date time string literal"],null),eba],null)],null));
$CLJS.OI.m(null,$CLJS.tE,function(a){return $CLJS.n($CLJS.xJ?$CLJS.xJ(MM,a):GM.call(null,MM,a))?new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.Zj,null,$CLJS.dH,null],null),null):$CLJS.n($CLJS.xJ?$CLJS.xJ(LM,a):GM.call(null,LM,a))?new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.vG,null,$CLJS.Zj,null],null),null):$CLJS.n($CLJS.xJ?$CLJS.xJ(KM,a):GM.call(null,KM,a))?new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.TH,null,$CLJS.Zj,null],null),null):$CLJS.Zj});$CLJS.X($CLJS.NM,LM);$CLJS.X($CLJS.JM,KM);
$CLJS.X($CLJS.RM,MM);$CLJS.X(Waa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mt,$CLJS.NM,$CLJS.JM,$CLJS.RM],null));$CLJS.VM=$CLJS.sh("^\\d{4}-\\d{2}$");$CLJS.X($CLJS.PM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,new $CLJS.k(null,1,[$CLJS.zu,"year-month string literal"],null),$CLJS.VM],null));$CLJS.WM=$CLJS.sh("^\\d{4}$");$CLJS.X($CLJS.IM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,new $CLJS.k(null,1,[$CLJS.zu,"year string literal"],null),$CLJS.WM],null));
$CLJS.X(OM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,$CLJS.FI],null)],null)],null));$CLJS.NK.g($CLJS.Bj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pt,new $CLJS.k(null,1,[$CLJS.zu,"Value :value clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.Bj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null),$CLJS.Al],null));