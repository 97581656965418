var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var a3=function(a,b){a=a instanceof $CLJS.M?a.T:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "equal-to":return $CLJS.MI("Equal to");case "default":return $CLJS.MI("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-equal-to":return $CLJS.MI("Not equal to");case "excludes":return $CLJS.MI("Excludes");case "default":return $CLJS.MI("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.M?b.T:null,b){case "after":return $CLJS.MI("After");case "default":return $CLJS.MI("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.M?b.T:null,b){case "before":return $CLJS.MI("Before");case "default":return $CLJS.MI("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "is-empty":return $CLJS.MI("Is empty");
case "default":return $CLJS.MI("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-empty":return $CLJS.MI("Not empty");case "default":return $CLJS.MI("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.MI("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},b3=function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return a3(a,b)}},Zga=new $CLJS.M(null,"after","after",594996914),$ga=new $CLJS.M(null,"equal-to","equal-to",608296653),aha=new $CLJS.M(null,"excludes","excludes",-1791725945),bha=new $CLJS.M(null,"not-equal-to","not-equal-to",
-343181655),cha=new $CLJS.M(null,"before","before",-1633692388);$CLJS.c3=function(){function a(d,e){return new $CLJS.k(null,3,[$CLJS.Ev,$CLJS.bO,$CLJS.fK,d,$CLJS.cO,e],null)}function b(d){return c.g?c.g(d,$CLJS.gi):c.call(null,d,$CLJS.gi)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.d3=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.c3.h($CLJS.Rs),$CLJS.c3.h($CLJS.FK),$CLJS.c3.h($CLJS.Ks),$CLJS.c3.h($CLJS.Os),$CLJS.c3.h($CLJS.RJ),$CLJS.c3.h($CLJS.Ms),$CLJS.c3.h($CLJS.Qs),$CLJS.c3.g($CLJS.QJ,$CLJS.SJ),$CLJS.c3.g($CLJS.$J,$CLJS.YJ)],null);$CLJS.dha=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.c3.h($CLJS.Rs),$CLJS.c3.h($CLJS.FK),$CLJS.c3.h($CLJS.SJ),$CLJS.c3.h($CLJS.YJ),$CLJS.c3.h($CLJS.lK),$CLJS.c3.h($CLJS.eK),$CLJS.c3.h($CLJS.dK),$CLJS.c3.h($CLJS.xK)],null);
$CLJS.eha=new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.c3.g($CLJS.FK,aha),$CLJS.c3.h($CLJS.Rs),$CLJS.c3.g($CLJS.Os,cha),$CLJS.c3.g($CLJS.Ks,Zga),$CLJS.c3.h($CLJS.RJ),$CLJS.c3.g($CLJS.QJ,$CLJS.SJ),$CLJS.c3.g($CLJS.$J,$CLJS.YJ)],null);$CLJS.fha=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.c3.h($CLJS.Rs),$CLJS.c3.h($CLJS.FK),$CLJS.c3.h($CLJS.WJ),$CLJS.c3.h($CLJS.Ks),$CLJS.c3.h($CLJS.Os),$CLJS.c3.h($CLJS.RJ),$CLJS.c3.h($CLJS.Ms),$CLJS.c3.h($CLJS.Qs)],null);
$CLJS.gha=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.c3.g($CLJS.Rs,$ga),$CLJS.c3.g($CLJS.FK,bha),$CLJS.c3.h($CLJS.Ks),$CLJS.c3.h($CLJS.Os),$CLJS.c3.h($CLJS.RJ),$CLJS.c3.h($CLJS.Ms),$CLJS.c3.h($CLJS.Qs),$CLJS.c3.g($CLJS.QJ,$CLJS.SJ),$CLJS.c3.g($CLJS.$J,$CLJS.YJ)],null);
$CLJS.hha=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.c3.h($CLJS.Rs),$CLJS.c3.h($CLJS.FK),$CLJS.c3.h($CLJS.lK),$CLJS.c3.h($CLJS.eK),$CLJS.c3.h($CLJS.QJ),$CLJS.c3.h($CLJS.$J),$CLJS.c3.h($CLJS.SJ),$CLJS.c3.h($CLJS.YJ),$CLJS.c3.h($CLJS.dK),$CLJS.c3.h($CLJS.xK)],null);$CLJS.iha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.c3.h($CLJS.Rs),$CLJS.c3.h($CLJS.FK),$CLJS.c3.h($CLJS.QJ),$CLJS.c3.h($CLJS.$J),$CLJS.c3.h($CLJS.SJ),$CLJS.c3.h($CLJS.YJ)],null);
$CLJS.jha=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.c3.h($CLJS.Rs),$CLJS.c3.g($CLJS.QJ,$CLJS.SJ),$CLJS.c3.g($CLJS.$J,$CLJS.YJ)],null);$CLJS.kha=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.c3.h($CLJS.Rs),$CLJS.c3.h($CLJS.FK),$CLJS.c3.h($CLJS.QJ),$CLJS.c3.h($CLJS.$J)],null);$CLJS.e3=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.R.j($CLJS.c3.h($CLJS.Rs),$CLJS.gi,!0),$CLJS.c3.h($CLJS.Ks),$CLJS.c3.h($CLJS.Os),$CLJS.c3.h($CLJS.Ms),$CLJS.c3.h($CLJS.Qs),$CLJS.c3.h($CLJS.FK)],null);
$CLJS.z1.m(null,$CLJS.bO,function(a,b,c,d){b=$CLJS.Ge(c);a=$CLJS.J.g(b,$CLJS.fK);b=$CLJS.J.g(b,$CLJS.cO);d=d instanceof $CLJS.M?d.T:null;switch(d){case "default":return b3(a,b);case "long":return a3(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.H1.m(null,$CLJS.bO,function(a,b,c){c=$CLJS.Ge(c);a=$CLJS.J.g(c,$CLJS.fK);b=$CLJS.J.g(c,$CLJS.cO);c=$CLJS.J.g(c,$CLJS.gi);a=new $CLJS.k(null,3,[$CLJS.x1,$CLJS.jE(a),$CLJS.rI,b3(a,b),$CLJS.f1,a3(a,b)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.gi,!0):a});