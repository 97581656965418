var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var tba;tba=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.bO=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.cO=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var dO=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.st,$CLJS.mt],null)),eO=null,fO=0,gO=0;;)if(gO<fO){var uba=eO.X(null,gO);$CLJS.HJ(uba,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$s,new $CLJS.k(null,1,[$CLJS.Un,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null)],null)],null)],null)]));gO+=1}else{var hO=$CLJS.z(dO);if(hO){var iO=hO;if($CLJS.Bd(iO)){var jO=$CLJS.kc(iO),vba=$CLJS.lc(iO),
wba=jO,xba=$CLJS.E(jO);dO=vba;eO=wba;fO=xba}else{var yba=$CLJS.B(iO);$CLJS.HJ(yba,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$s,new $CLJS.k(null,1,[$CLJS.Un,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null)],null)],null)],null)]));dO=$CLJS.C(iO);eO=null;fO=0}gO=0}else break}$CLJS.FJ($CLJS.ot,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null)]));
for(var kO=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.FK],null)),lO=null,mO=0,nO=0;;)if(nO<mO){var zba=lO.X(null,nO);$CLJS.HJ(zba,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$s,new $CLJS.k(null,1,[$CLJS.Un,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iJ],null)],null)],null)],null)]));nO+=1}else{var oO=$CLJS.z(kO);if(oO){var pO=oO;if($CLJS.Bd(pO)){var qO=$CLJS.kc(pO),Aba=$CLJS.lc(pO),
Bba=qO,Cba=$CLJS.E(qO);kO=Aba;lO=Bba;mO=Cba}else{var Dba=$CLJS.B(pO);$CLJS.HJ(Dba,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$s,new $CLJS.k(null,1,[$CLJS.Un,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iJ],null)],null)],null)],null)]));kO=$CLJS.C(pO);lO=null;mO=0}nO=0}else break}
for(var rO=$CLJS.z(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Os,$CLJS.Qs,$CLJS.Ks,$CLJS.Ms],null)),sO=null,tO=0,uO=0;;)if(uO<tO){var Eba=sO.X(null,uO);$CLJS.FJ(Eba,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null)]));uO+=1}else{var vO=$CLJS.z(rO);if(vO){var wO=vO;if($CLJS.Bd(wO)){var xO=$CLJS.kc(wO),Fba=$CLJS.lc(wO),Gba=xO,Hba=$CLJS.E(xO);rO=Fba;sO=Gba;tO=Hba}else{var Iba=$CLJS.B(wO);$CLJS.FJ(Iba,$CLJS.G([$CLJS.pu,$CLJS.Hj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null)]));rO=$CLJS.C(wO);sO=null;tO=0}uO=0}else break}$CLJS.FJ($CLJS.RJ,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null)]));
$CLJS.FJ($CLJS.WJ,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null)]));
for(var yO=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QJ,$CLJS.$J],null)),zO=null,AO=0,BO=0;;)if(BO<AO){var Jba=zO.X(null,BO);$CLJS.FJ(Jba,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cJ],null)]));BO+=1}else{var CO=$CLJS.z(yO);if(CO){var DO=CO;if($CLJS.Bd(DO)){var EO=$CLJS.kc(DO),Kba=$CLJS.lc(DO),Lba=EO,Mba=$CLJS.E(EO);yO=Kba;zO=Lba;AO=Mba}else{var Nba=$CLJS.B(DO);$CLJS.FJ(Nba,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cJ],null)]));yO=$CLJS.C(DO);
zO=null;AO=0}BO=0}else break}
for(var FO=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,$CLJS.YJ],null)),GO=null,HO=0,IO=0;;)if(IO<HO){var Oba=GO.X(null,IO);$CLJS.FJ(Oba,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oJ],null)]));IO+=1}else{var JO=$CLJS.z(FO);if(JO){var KO=JO;if($CLJS.Bd(KO)){var LO=$CLJS.kc(KO),Pba=$CLJS.lc(KO),Qba=LO,Rba=$CLJS.E(LO);FO=Pba;GO=Qba;HO=Rba}else{var Sba=$CLJS.B(KO);$CLJS.FJ(Sba,$CLJS.G([$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oJ],null)]));FO=$CLJS.C(KO);
GO=null;HO=0}IO=0}else break}
for(var MO=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uK,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null)],null),NO=$CLJS.z(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dK,$CLJS.xK,$CLJS.lK,$CLJS.eK],null)),OO=null,PO=0,QO=0;;)if(QO<PO){var RO=OO.X(null,QO);$CLJS.NK.v(RO,$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,RO],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,$CLJS.BI,MO],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.oJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oJ],null)],null));QO+=1}else{var SO=$CLJS.z(NO);if(SO){var TO=SO;if($CLJS.Bd(TO)){var UO=$CLJS.kc(TO),Tba=$CLJS.lc(TO),Uba=UO,Vba=$CLJS.E(UO);NO=Tba;OO=Uba;PO=Vba}else{var VO=$CLJS.B(TO);$CLJS.NK.v(VO,$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,VO],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,$CLJS.BI,MO],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oJ],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.oJ],null)],null));NO=$CLJS.C(TO);OO=null;PO=0}QO=0}else break}
$CLJS.NK.v($CLJS.yK,$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.yK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yu,$CLJS.BI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,new $CLJS.k(null,1,[$CLJS.It,!0],null),$CLJS.Ct],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jt,$CLJS.Lx,$CLJS.dB,$CLJS.VJ],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.rJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lI],null)],null));$CLJS.NK.v($CLJS.NJ,$CLJS.pu,$CLJS.Hj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.NJ],null),$CLJS.BI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,$CLJS.nI,$CLJS.wI],null)],null));
$CLJS.X(tba,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ev,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.bO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fK,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.jt,$CLJS.Rs,$CLJS.FK,$CLJS.WJ,$CLJS.RJ,$CLJS.Os,$CLJS.Ks,$CLJS.Qs,$CLJS.Ms,$CLJS.QJ,$CLJS.$J,$CLJS.SJ,$CLJS.YJ,$CLJS.lK,$CLJS.eK,$CLJS.dK,$CLJS.xK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cO,$CLJS.Wi],null)],null));